/* eslint-disable */

// function removeEmptyKeys(obj) {
//   for (const key in obj) {
//     if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
//       delete obj[key];
//     }
//   }
//   return obj;
// }

// const flattenJSON = (obj = {}, res = {}, extraKey = "") => {
//   for (key in obj) {
//     if (typeof obj[key] !== "object") {
//       res[extraKey + key] = obj[key];
//     } else {
//       flattenJSON(obj[key], res, `${extraKey}${key}.`);
//     }
//   }
//   return res;
// };

function titleize(str) {
  // Split the string into words using a regular expression
  // that matches one or more whitespace characters.
  const words = str.split(/\s+/);

  // Initialize an array to store the titleized words.
  const titleizedWords = [];

  // Loop through each word and titleize it.
  for (const word of words) {
    // Convert the first letter to uppercase and the rest to lowercase.
    const titleizedWord =
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

    // Push the titleized word to the array.
    titleizedWords.push(titleizedWord);
  }

  // Join the titleized words back into a single string.
  const titleizedString = titleizedWords.join(" ");

  return titleizedString;
}

// function getDocumentTerms() {
//   const result = [];

//   if (!lease) {
//     return;
//   }
  
//   result.push({
//     key: "Document type",
//     value: titleize(lease.form.flavor.replace(/_/g, " "))
//   });

//   if (lease.abatementDates?.length > 0) {
//     lease.abatementDates.forEach((record, index) => {
//       if (lease.abatementDates[index]?.date) {
//         result.push({
//           key: `Abatement date #${index + 1} - date`,
//           value: lease.abatementDates[index].date
//         });
//       }
//     });
//   }
//   if (lease.additionalRentType) {
//     result.push({
//       key: "Additional rent type",
//       value: lease.additionalRentType
//     });
//   }
//   if (lease.advanceRentAmount) {
//     result.push({
//       key: "Advance rent amount",
//       value: lease.advanceRentAmount
//     });
//   }
//   if (lease.annualIncrease) {
//     result.push({
//       key: "Annual increase",
//       value: lease.annualIncrease
//     });
//   }
//   if (lease.arbitrationProcess?.submarket) {
//     result.push({
//       key: "Arbitration process submarket",
//       value: lease.arbitrationProcess.submarket
//     });
//   }
//   if (lease.assigneeInfo?.name) {
//     result.push({
//       key: "Assignee info name",
//       value: lease.assigneeInfo.name
//     });
//   }
//   if (lease.assigneeInfo?.type) {
//     result.push({
//       key: "Assignee info type",
//       value: lease.assigneeInfo.type
//     });
//   }
//   if (lease.assigneeInfo?.stateOfFormation) {
//     result.push({
//       key: "Assignee info state of formation",
//       value: lease.assigneeInfo.stateOfFormation
//     });
//   }
//   if (lease.assigneeInfo?.entityType) {
//     result.push({
//       key: "Assignee info entity type",
//       value: lease.assigneeInfo.entityType
//     });
//   }
//   if (lease.assigneeInfo?.entityTypeOther) {
//     result.push({
//       key: "Assignee info entity type other",
//       value: lease.assigneeInfo.entityTypeOther
//     });
//   }
//   if (lease.assigneeInfo?.address) {
//     result.push({
//       key: "Assignee info address",
//       value: lease.assigneeInfo.address
//     });
//   }
//   if (lease.assigneeInfo?.addressCity) {
//     result.push({
//       key: "Assignee info address city",
//       value: lease.assigneeInfo.addressCity
//     });
//   }
//   if (lease.assigneeInfo?.addressState) {
//     result.push({
//       key: "Assignee info address state",
//       value: lease.assigneeInfo.addressState
//     });
//   }
//   if (lease.assigneeInfo?.addressZip) {
//     result.push({
//       key: "Assignee info address zip",
//       value: lease.assigneeInfo.addressZip
//     });
//   }
//   if (lease.assigneeInfo?.attentionName) {
//     result.push({
//       key: "Assignee info attention name",
//       value: lease.assigneeInfo.attentionName
//     });
//   }
//   if (lease.assigneeInfo?.assignmentDate) {
//     result.push({
//       key: "Assignee info assignment date",
//       value: lease.assigneeInfo.assignmentDate
//     });
//   }
//   if (lease.assigneeInfo?.individuals?.length > 0) {
//     lease.assigneeInfo.individuals.forEach((record, index) => {
//       if (lease.assigneeInfo.individuals[index]?.name) {
//         result.push({
//           key: `Assignee info individual #${index + 1} - name`,
//           value: lease.assigneeInfo.individuals[index].name
//         });
//       }
//       if (lease.assigneeInfo.individuals[index]?.SSN) {
//         result.push({
//           key: `Assignee info individual #${index + 1} - SSN`,
//           value: lease.assigneeInfo.individuals[index].SSN
//         });
//       }
//     });
//   }
//   if (lease.assignment) {
//     result.push({
//       key: "Assignment",
//       value: lease.assignment
//     });
//   }
//   if (lease.hasArrearage) {
//     result.push({
//       key: "Has arrearage",
//       value: lease.hasArrearage
//     });
//   }
//   if (lease.arrearageType) {
//     result.push({
//       key: "Arrearage type",
//       value: lease.arrearageType
//     });
//   }
//   if (lease.arrearageDate) {
//     result.push({
//       key: "Arrearage date",
//       value: lease.arrearageDate
//     });
//   }
//   if (lease.arrearageAmount) {
//     result.push({
//       key: "Arrearage amount",
//       value: lease.arrearageAmount
//     });
//   }
//   if (lease.auditRightReimbursementsTrigger) {
//     result.push({
//       key: "Audit right reimbursements trigger",
//       value: lease.auditRightReimbursementsTrigger
//     });
//   }
//   if (lease.auditRightType) {
//     result.push({
//       key: "Audit right type",
//       value: lease.auditRightType
//     });
//   }
//   if (lease.baseRent) {
//     result.push({
//       key: "Base rent",
//       value: lease.baseRent
//     });
//   }
//   if (lease.burnOff) {
//     result.push({
//       key: "Burn off",
//       value: lease.burnOff
//     });
//   }
//   if (lease.burnOffPeriod) {
//     result.push({
//       key: "Burn off period",
//       value: lease.burnOffPeriod
//     });
//   }
//   if (lease.capOnCam) {
//     result.push({
//       key: "Cap on cam",
//       value: lease.capOnCam
//     });
//   }
//   if (lease.capOnCamFirstYear) {
//     result.push({
//       key: "Cap on cam first year",
//       value: lease.capOnCamFirstYear
//     });
//   }
//   if (lease.commonAreaReimbursement) {
//     result.push({
//       key: "Common area reimbursement",
//       value: lease.commonAreaReimbursement
//     });
//   }
//   if (lease.commonAreaWorkPayment) {
//     result.push({
//       key: "Common area work payment",
//       value: lease.commonAreaWorkPayment
//     });
//   }
//   if (lease.contingencyTermination) {
//     result.push({
//       key: "Contingency termination",
//       value: lease.contingencyTermination
//     });
//   }
//   if (lease.cpiAnchorYears) {
//     result.push({
//       key: "CPI anchor years",
//       value: lease.cpiAnchorYears
//     });
//   }
//   if (lease.createdAt) {
//     result.push({
//       key: "Created At",
//       value: lease.createdAt
//     });
//   }
//   if (lease.damageDeposit) {
//     result.push({
//       key: "Damage deposit",
//       value: lease.damageDeposit
//     });
//   }
//   if (lease.cleaningFee) {
//     result.push({
//       key: "Cleaning fee",
//       value: lease.cleaningFee
//     });
//   }
//   if (lease.deliveryDateType) {
//     result.push({
//       key: "Delivery date type",
//       value: lease.deliveryDateType
//     });
//   }
//   if (lease.deliveryDaysAfterLease) {
//     result.push({
//       key: "Delivery days after lease",
//       value: lease.deliveryDaysAfterLease
//     });
//   }
//   if (lease.demoHardTerminationDate) {
//     result.push({
//       key: "Demo hard termination date",
//       value: lease.demoHardTerminationDate
//     });
//   }
//   if (lease.demoSoftTerminationDate) {
//     result.push({
//       key: "Demo soft termination date",
//       value: lease.demoSoftTerminationDate
//     });
//   }
//   if (lease.earlyAccessDateType) {
//     result.push({
//       key: "Early access date type",
//       value: lease.earlyAccessDateType
//     });
//   }
//   if (lease.earlyAccessPeriod) {
//     result.push({
//       key: "Early access period",
//       value: lease.earlyAccessPeriod
//     });
//   }
//   if (lease.earlyAccessSpecificDate) {
//     result.push({
//       key: "Early access specific date",
//       value: lease.earlyAccessSpecificDate
//     });
//   }
//   if (lease.earlyAccessType) {
//     result.push({
//       key: "Early access type",
//       value: lease.earlyAccessType
//     });
//   }
//   if (lease.estimatedCommencementDate) {
//     result.push({
//       key: "Estimated commencement date",
//       value: lease.estimatedCommencementDate
//     });
//   }
//   if (lease.estimatedCommencementDateType) {
//     result.push({
//       key: "Estimated commencement date type",
//       value: lease.estimatedCommencementDateType
//     });
//   }
//   if (lease.estimatedDeliveryDate) {
//     result.push({
//       key: "Estimated delivery date",
//       value: lease.estimatedDeliveryDate
//     });
//   }
//   if (lease.exclusiveParkingAmount) {
//     result.push({
//       key: "Exclusive parking amount",
//       value: lease.exclusiveParkingAmount
//     });
//   }
//   if (lease.exclusiveParkingDesignation) {
//     result.push({
//       key: "Exclusive parking designation",
//       value: lease.exclusiveParkingDesignation
//     });
//   }
//   if (lease.exclusiveUse?.language) {
//     result.push({
//       key: "Exclusive use language",
//       value: lease.exclusiveUse.language
//     });
//   }
//   if (lease.exclusiveUse?.scope) {
//     result.push({
//       key: "Exclusive use scope",
//       value: lease.exclusiveUse.scope
//     });
//   }
//   if (lease.exclusiveUse?.primaryPercentage) {
//     result.push({
//       key: "Exclusive use primary percentage",
//       value: lease.exclusiveUse.primaryPercentage
//     });
//   }
//   if (lease.exclusiveUse?.primaryType) {
//     result.push({
//       key: "Exclusive use primary type",
//       value: lease.exclusiveUse.primaryType
//     });
//   }
//   if (lease.exclusiveUse?.curePeriod) {
//     result.push({
//       key: "Exclusive use cure period",
//       value: lease.exclusiveUse.curePeriod
//     });
//   }
//   if (lease.exclusiveUse?.hasRemedy) {
//     result.push({
//       key: "Exclusive use has remedy",
//       value: lease.exclusiveUse.hasRemedy
//     });
//   }
//   if (lease.exclusiveUse?.hasCarveOut) {
//     result.push({
//       key: "Exclusive use has carve out",
//       value: lease.exclusiveUse.hasCarveOut
//     });
//   }
//   if (lease.exclusiveUse?.carveOutLanguage) {
//     result.push({
//       key: "Exclusive use carve out language",
//       value: lease.exclusiveUse.carveOutLanguage
//     });
//   }
//   if (lease.existingGuarantorInfo) {
//     if (lease.existingGuarantorInfo.individuals?.length > 0) {
//       lease.existingGuarantorInfo.individuals.forEach((record, index) => {
//         if (lease.existingGuarantorInfo.individuals[index]?.name) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - name`,
//             value: lease.existingGuarantorInfo.individuals[index].name
//           });
//         }
//         if (lease.existingGuarantorInfo.individuals[index]?.nickname) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - nickname`,
//             value: lease.existingGuarantorInfo.individuals[index].nickname
//           });
//         }
//         if (lease.existingGuarantorInfo.individuals[index]?.guarantyDate) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - guaranty date`,
//             value: lease.existingGuarantorInfo.individuals[index].guarantyDate
//           });
//         }
//         if (lease.existingGuarantorInfo.individuals[index]?.hasBeenReleased) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - has been released`,
//             value:
//               lease.existingGuarantorInfo.individuals[index].hasBeenReleased
//           });
//         }
//         if (lease.existingGuarantorInfo.individuals[index]?.releaseDate) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - release date`,
//             value: lease.existingGuarantorInfo.individuals[index].releaseDate
//           });
//         }
//         if (lease.existingGuarantorInfo.individuals[index]?.address) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - address`,
//             value: lease.existingGuarantorInfo.individuals[index].address
//           });
//         }
//         if (lease.existingGuarantorInfo.individuals[index]?.city) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - city`,
//             value: lease.existingGuarantorInfo.individuals[index].city
//           });
//         }
//         if (lease.existingGuarantorInfo.individuals[index]?.state) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - state`,
//             value: lease.existingGuarantorInfo.individuals[index].state
//           });
//         }
//         if (lease.existingGuarantorInfo.individuals[index]?.zip) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - zip`,
//             value: lease.existingGuarantorInfo.individuals[index].zip
//           });
//         }
//       });
//     }
//     if (lease.existingGuarantorInfo.marriedCouples?.length > 0) {
//       lease.existingGuarantorInfo.marriedCouples.forEach((record, index) => {
//         if (lease.existingGuarantorInfo.marriedCouples[index]?.name1) {
//           result.push({
//             key: `Married couple guarantor #${index + 1} - name1`,
//             value: lease.existingGuarantorInfo.marriedCouples[index].name1
//           });
//         }
//         if (lease.existingGuarantorInfo.marriedCouples[index]?.name2) {
//           result.push({
//             key: `Married couple guarantor #${index + 1} - name2`,
//             value: lease.existingGuarantorInfo.marriedCouples[index].name2
//           });
//         }
//         if (lease.existingGuarantorInfo.marriedCouples[index]?.nickname) {
//           result.push({
//             key: `Married couple guarantor #${index + 1} - nickname`,
//             value: lease.existingGuarantorInfo.marriedCouples[index].nickname
//           });
//         }
//         if (lease.existingGuarantorInfo.marriedCouples[index]?.guarantyDate) {
//           result.push({
//             key: `Married couple guarantor #${index + 1} - guaranty date`,
//             value:
//               lease.existingGuarantorInfo.marriedCouples[index].guarantyDate
//           });
//         }
//         if (
//           lease.existingGuarantorInfo.marriedCouples[index]?.hasBeenReleased
//         ) {
//           result.push({
//             key: `Married couple guarantor #${index + 1} - has been released`,
//             value:
//               lease.existingGuarantorInfo.marriedCouples[index].hasBeenReleased
//           });
//         }
//         if (lease.existingGuarantorInfo.marriedCouples[index]?.releaseDate) {
//           result.push({
//             key: `Married couple guarantor #${index + 1} - release date`,
//             value: lease.existingGuarantorInfo.marriedCouples[index].releaseDate
//           });
//         }
//         if (lease.existingGuarantorInfo.marriedCouples[index]?.address1) {
//           result.push({
//             key: `Married couple guarantor #${index + 1} - address1`,
//             value: lease.existingGuarantorInfo.marriedCouples[index].address1
//           });
//         }
//         if (lease.existingGuarantorInfo.marriedCouples[index]?.city1) {
//           result.push({
//             key: `Married couple guarantor #${index + 1} - city1`,
//             value: lease.existingGuarantorInfo.marriedCouples[index].city1
//           });
//         }
//         if (lease.existingGuarantorInfo.marriedCouples[index]?.state1) {
//           result.push({
//             key: `Married couple guarantor #${index + 1} - state1`,
//             value: lease.existingGuarantorInfo.marriedCouples[index].state1
//           });
//         }
//         if (lease.existingGuarantorInfo.marriedCouples[index]?.zip1) {
//           result.push({
//             key: `Married couple guarantor #${index + 1} - zip1`,
//             value: lease.existingGuarantorInfo.marriedCouples[index].zip1
//           });
//         }
//         if (lease.existingGuarantorInfo.marriedCouples[index]?.address2) {
//           result.push({
//             key: `Married couple guarantor #${index + 1} - address2`,
//             value: lease.existingGuarantorInfo.marriedCouples[index].address2
//           });
//         }
//         if (lease.existingGuarantorInfo.marriedCouples[index]?.city2) {
//           result.push({
//             key: `Married couple guarantor #${index + 1} - city2`,
//             value: lease.existingGuarantorInfo.marriedCouples[index].city2
//           });
//         }
//         if (lease.existingGuarantorInfo.marriedCouples[index]?.state2) {
//           result.push({
//             key: `Married couple guarantor #${index + 1} - state2`,
//             value: lease.existingGuarantorInfo.marriedCouples[index].state2
//           });
//         }
//         if (lease.existingGuarantorInfo.marriedCouples[index]?.zip2) {
//           result.push({
//             key: `Married couple guarantor #${index + 1} - zip2`,
//             value: lease.existingGuarantorInfo.marriedCouples[index].zip2
//           });
//         }
//         if (lease.existingGuarantorInfo.marriedCouples[index]?.hasSameAddress) {
//           result.push({
//             key: `Married couple guarantor #${index + 1} - has same address`,
//             value:
//               lease.existingGuarantorInfo.marriedCouples[index].hasSameAddress
//           });
//         }
//       });
//     }
//     if (lease.existingGuarantorInfo.entities?.length > 0) {
//       lease.existingGuarantorInfo.entities.forEach((record, index) => {
//         if (lease.existingGuarantorInfo.entities?.name) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - name`,
//             value: lease.existingGuarantorInfo.entities.name
//           });
//         }
//         if (lease.existingGuarantorInfo.entities?.nickname) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - nickname`,
//             value: lease.existingGuarantorInfo.entities.nickname
//           });
//         }
//         if (lease.existingGuarantorInfo.entities?.guarantyDate) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - guaranty date`,
//             value: lease.existingGuarantorInfo.entities.guarantyDate
//           });
//         }
//         if (lease.existingGuarantorInfo.entities?.hasBeenReleased) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - has been released`,
//             value: lease.existingGuarantorInfo.entities.hasBeenReleased
//           });
//         }
//         if (lease.existingGuarantorInfo.entities?.releaseDate) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - release date`,
//             value: lease.existingGuarantorInfo.entities.releaseDate
//           });
//         }
//         if (lease.existingGuarantorInfo.entities?.address) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - address`,
//             value: lease.existingGuarantorInfo.entities.address
//           });
//         }
//         if (lease.existingGuarantorInfo.entities?.city) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - city`,
//             value: lease.existingGuarantorInfo.entities.city
//           });
//         }
//         if (lease.existingGuarantorInfo.entities?.state) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - state`,
//             value: lease.existingGuarantorInfo.entities.state
//           });
//         }
//         if (lease.existingGuarantorInfo.entities?.zip) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - zip`,
//             value: lease.existingGuarantorInfo.entities.zip
//           });
//         }
//         if (lease.existingGuarantorInfo.entities?.stateOfFormation) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - state of formation`,
//             value: lease.existingGuarantorInfo.entities.stateOfFormation
//           });
//         }
//         if (lease.existingGuarantorInfo.entities?.entityType) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - entity type`,
//             value: lease.existingGuarantorInfo.entities.entityType
//           });
//         }
//         if (lease.existingGuarantorInfo.entities?.entityTypeOther) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - entity type other`,
//             value: lease.existingGuarantorInfo.entities.entityTypeOther
//           });
//         }
//       });
//     }
//   }
//   if (lease.existingLeaseDate) {
//     result.push({
//       key: "Existing lease date",
//       value: lease.existingLeaseDate
//     });
//   }
//   if (lease.existingLeaseExpirationDate) {
//     result.push({
//       key: "Existing lease expiration date",
//       value: lease.existingLeaseExpirationDate
//     });
//   }
//   if (lease.existingPercentageRentRate) {
//     result.push({
//       key: "Existing percentage rent rate",
//       value: lease.existingPercentageRentRate
//     });
//   }
//   if (lease.existingTenantContingency) {
//     result.push({
//       key: "Has existing tenant contingency",
//       value: lease.existingTenantContingency
//     });
//   }
//   if (lease.existingTenantName) {
//     result.push({
//       key: "Existing tenant name",
//       value: lease.existingTenantName
//     });
//   }
//   if (lease.expansionAgreementDate) {
//     result.push({
//       key: "Expansion agreement date",
//       value: lease.expansionAgreementDate
//     });
//   }
//   if (lease.expansionCommencementDate) {
//     result.push({
//       key: "Expansion commencement date",
//       value: lease.expansionCommencementDate
//     });
//   }
//   if (lease.expansionNoticeDate) {
//     result.push({
//       key: "Expansion notice date",
//       value: lease.expansionNoticeDate
//     });
//   }
//   if (lease.expansionNoticeSoftDate) {
//     result.push({
//       key: "Expansion notice soft date",
//       value: lease.expansionNoticeSoftDate
//     });
//   }
//   if (lease.expansionOption?.area) {
//     result.push({
//       key: "Expansion option area",
//       value: lease.expansionOption.area
//     });
//   }
//   if (lease.expansionOption?.deliveryDate) {
//     result.push({
//       key: "Expansion option delivery date",
//       value: lease.expansionOption.deliveryDate
//     });
//   }
//   if (lease.expansionOption?.date) {
//     result.push({
//       key: "Expansion option date",
//       value: lease.expansionOption.date
//     });
//   }
//   if (lease.expansionOption?.windowPeriod) {
//     result.push({
//       key: "Expansion option window period",
//       value: lease.expansionOption.windowPeriod
//     });
//   }
//   if (lease.expansionOption?.windowStartDate) {
//     result.push({
//       key: "Expansion option window start date",
//       value: lease.expansionOption.windowStartDate
//     });
//   }
//   if (lease.expansionOption?.hasSuperiorNamedTenants) {
//     result.push({
//       key: "Expansion option has superior named tenants",
//       value: lease.expansionOption.hasSuperiorNamedTenants
//     });
//   }
//   if (lease.expansionOption?.superiorNamedTenants) {
//     result.push({
//       key: "Expansion option superior named tenants",
//       value: lease.expansionOption.superiorNamedTenants
//     });
//   }
//   if (lease.expansionOption?.hasImprovementAllowance) {
//     result.push({
//       key: "Expansion option has improvement allowance",
//       value: lease.expansionOption.hasImprovementAllowance
//     });
//   }
//   if (lease.expansionOption?.improvementAllowanceAmount) {
//     result.push({
//       key: "Expansion option improvement allowance amount",
//       value: lease.expansionOption.improvementAllowanceAmount
//     });
//   }
//   if (lease.expansionOption?.suite) {
//     result.push({
//       key: "Expansion option suite",
//       value: lease.expansionOption.suite
//     });
//   }
//   if (lease.expansionOption?.days) {
//     result.push({
//       key: "Expansion option days",
//       value: lease.expansionOption.days
//     });
//   }
//   if (lease.expansionOption?.deliveryDateEarliestLength) {
//     result.push({
//       key: "Expansion option delivery date earliest length",
//       value: lease.expansionOption.deliveryDateEarliestLength
//     });
//   }
//   if (lease.expansionOption?.deliveryDateLongestLength) {
//     result.push({
//       key: "Expansion option delivery date longest length",
//       value: lease.expansionOption.deliveryDateLongestLength
//     });
//   }
//   if (lease.expansionOption?.deliveryDateLeaseYear) {
//     result.push({
//       key: "Expansion option delivery date lease year",
//       value: lease.expansionOption.deliveryDateLeaseYear
//     });
//   }
//   if (lease.expansionOption?.floor) {
//     result.push({
//       key: "Expansion option floor",
//       value: lease.expansionOption.floor
//     });
//   }
//   if (lease.expansionOption?.rentType) {
//     result.push({
//       key: "Expansion option rent type",
//       value: lease.expansionOption.rentType
//     });
//   }
//   if (lease.expansionOption?.areaMinimum) {
//     result.push({
//       key: "Expansion option area minimum",
//       value: lease.expansionOption.areaMinimum
//     });
//   }
//   if (lease.expansionOption?.areaMaximum) {
//     result.push({
//       key: "Expansion option area maximum",
//       value: lease.expansionOption.areaMaximum
//     });
//   }
//   if (lease.expansionOption?.commencement) {
//     result.push({
//       key: "Expansion option commencement",
//       value: lease.expansionOption.commencement
//     });
//   }
//   if (lease.expansionOption?.hasExistingOccupant) {
//     result.push({
//       key: "Expansion option has existing occupant",
//       value: lease.expansionOption.hasExistingOccupant
//     });
//   }
//   if (lease.expansionPremiseAreaSize) {
//     result.push({
//       key: "Expansion premise area size",
//       value: lease.expansionPremiseAreaSize
//     });
//   }
//   if (lease.expansionPremiseFloor) {
//     result.push({
//       key: "expansionPremiseFloor",
//       value: lease.expansionPremiseFloor
//     });
//   }
//   if (lease.expansionPremiseStoreroomNumber) {
//     result.push({
//       key: "Expansion premise storeroom number",
//       value: lease.expansionPremiseStoreroomNumber
//     });
//   }
//   if (lease.expenseBaseYear) {
//     result.push({
//       key: "Expense base year",
//       value: lease.expenseBaseYear
//     });
//   }
//   if (lease.extendedTermLength) {
//     result.push({
//       key: "Extended term length",
//       value: lease.extendedTermLength
//     });
//   }
//   if (lease.fitTestAllowanceAmount) {
//     result.push({
//       key: "Fit test allowance amount",
//       value: lease.fitTestAllowanceAmount
//     });
//   }
//   if (lease.fixedCam) {
//     result.push({
//       key: "Has fixed cam",
//       value: lease.fixedCam
//     });
//   }
//   if (lease.fixedCamAnnualEscalator) {
//     result.push({
//       key: "Fixed cam annual escalator",
//       value: lease.fixedCamAnnualEscalator
//     });
//   }
//   if (lease.fixedCamInitialAmount) {
//     result.push({
//       key: "Fixed cam initial amount",
//       value: lease.fixedCamInitialAmount
//     });
//   }
//   if (lease.fixedCamType) {
//     result.push({
//       key: "Fixed cam type",
//       value: lease.fixedCamType
//     });
//   }
//   if (lease.fixturingPeriod) {
//     result.push({
//       key: "Fixturing period",
//       value: lease.fixturingPeriod
//     });
//   }
//   if (lease.fixturingPeriodType) {
//     result.push({
//       key: "Fixturing period type",
//       value: lease.fixturingPeriodType
//     });
//   }
//   if (lease.franchise) {
//     result.push({
//       key: "Has franchise",
//       value: lease.franchise
//     });
//   }
//   if (lease.franchisorAddendum) {
//     result.push({
//       key: "Has franchisor addendum",
//       value: lease.franchisorAddendum
//     });
//   }
//   if (lease.franchisorInfo?.name) {
//     result.push({
//       key: "Franchisor info name",
//       value: lease.franchisorInfo.name
//     });
//   }
//   if (lease.franchisorInfo?.entityType) {
//     result.push({
//       key: "Franchisor info entity type",
//       value: lease.franchisorInfo.entityType
//     });
//   }
//   if (lease.franchisorInfo?.entityTypeOther) {
//     result.push({
//       key: "Franchisor info entity type other",
//       value: lease.franchisorInfo.entityTypeOther
//     });
//   }
//   if (lease.franchisorInfo?.stateOfFormation) {
//     result.push({
//       key: "Franchisor info state of formation",
//       value: lease.franchisorInfo.stateOfFormation
//     });
//   }
//   if (lease.franchisorInfo?.address) {
//     result.push({
//       key: "Franchisor info address",
//       value: lease.franchisorInfo.address
//     });
//   }
//   if (lease.franchisorInfo?.city) {
//     result.push({
//       key: "Franchisor info city",
//       value: lease.franchisorInfo.city
//     });
//   }
//   if (lease.franchisorInfo?.zip) {
//     result.push({
//       key: "Franchisor info zip",
//       value: lease.franchisorInfo.zip
//     });
//   }
//   if (lease.franchisorInfo?.state) {
//     result.push({
//       key: "Franchisor info state",
//       value: lease.franchisorInfo.state
//     });
//   }
//   if (lease.franchisorInfo?.type) {
//     result.push({
//       key: "Franchisor info type",
//       value: lease.franchisorInfo.type
//     });
//   }
//   if (lease.franchisorInfo?.attention) {
//     result.push({
//       key: "Franchisor info attention",
//       value: lease.franchisorInfo.attention
//     });
//   }
//   if (lease.freeRent) {
//     result.push({
//       key: "Free Rent",
//       value: lease.freeRent
//     });
//   }
//   if (lease.freeRentExtendedAt) {
//     result.push({
//       key: "Free rent extended at",
//       value: lease.freeRentExtendedAt
//     });
//   }
//   if (lease.freeRentPeriods?.length > 0) {
//     lease.freeRentPeriods.forEach((record, index) => {
//       if (lease.freeRentPeriods[index]?.isFree) {
//         result.push({
//           key: `Free rent period #${index + 1} - is free`,
//           value: lease.freeRentPeriods[index].isFree
//         });
//       }
//       if (lease.freeRentPeriods[index]?.hardStartDate) {
//         result.push({
//           key: `Free rent period #${index + 1} - hard start date`,
//           value: lease.freeRentPeriods[index].hardStartDate
//         });
//       }
//       if (lease.freeRentPeriods[index]?.hardEndDate) {
//         result.push({
//           key: `Free rent period #${index + 1} - hard end date`,
//           value: lease.freeRentPeriods[index].hardEndDate
//         });
//       }
//       if (lease.freeRentPeriods[index]?.softStartDate) {
//         result.push({
//           key: `Free rent period #${index + 1} - soft start date`,
//           value: lease.freeRentPeriods[index].softStartDate
//         });
//       }
//       if (lease.freeRentPeriods[index]?.softEndDate) {
//         result.push({
//           key: `Free rent period #${index + 1} - soft end date`,
//           value: lease.freeRentPeriods[index].softEndDate
//         });
//       }
//       if (lease.freeRentPeriods[index]?.beginIn) {
//         result.push({
//           key: `Free rent period #${index + 1} - begin in`,
//           value: lease.freeRentPeriods[index].beginIn
//         });
//       }
//       if (lease.freeRentPeriods[index]?.duration) {
//         result.push({
//           key: `Free rent period #${index + 1} - duration`,
//           value: lease.freeRentPeriods[index].duration
//         });
//       }
//       if (lease.freeRentPeriods[index]?.percentFree) {
//         result.push({
//           key: `Free rent period #${index + 1} - percent free`,
//           value: lease.freeRentPeriods[index].percentFree
//         });
//       }
//       if (lease.freeRentPeriods[index]?.amount) {
//         result.push({
//           key: `Free rent period #${index + 1} - amount`,
//           value: lease.freeRentPeriods[index].amount
//         });
//       }
//     });
//   }
//   if (lease.freeRentType) {
//     result.push({
//       key: "Free rent type",
//       value: lease.freeRentType
//     });
//   }
//   if (lease.goDarkOperatingPeriod) {
//     result.push({
//       key: "Go dark operating period",
//       value: lease.goDarkOperatingPeriod
//     });
//   }
//   if (lease.goDarkOption) {
//     result.push({
//       key: "Go dark option",
//       value: lease.goDarkOption
//     });
//   }
//   if (lease.grandOpeningPeriod) {
//     result.push({
//       key: "Grand opening period",
//       value: lease.grandOpeningPeriod
//     });
//   }
//   if (lease.guarantor) {
//     result.push({
//       key: "Has guarantor",
//       value: lease.guarantor
//     });
//   }
//   if (lease.guarantorInfo) {
//     if (lease.guarantorInfo.entityType) {
//       result.push({
//         key: "Guarantor info entity type",
//         value: lease.guarantorInfo.entityType
//       });
//     }
//     if (lease.guarantorInfo.entityTypeOther) {
//       result.push({
//         key: "Guarantor info entity type other",
//         value: lease.guarantorInfo.entityTypeOther
//       });
//     }
//     if (lease.guarantorInfo.stateOfFormation) {
//       result.push({
//         key: "Guarantor info state of formation",
//         value: lease.guarantorInfo.stateOfFormation
//       });
//     }
//     if (lease.guarantorInfo.type) {
//       result.push({
//         key: "Guarantor info type",
//         value: lease.guarantorInfo.type
//       });
//     }
//     if (lease.guarantorInfo.guarantors?.length > 0) {
//       lease.guarantorInfo.guarantors.forEach((record, index) => {
//         if (lease.guarantorInfo.guarantors[index]?.name) {
//           result.push({
//             key: `Guarantor #${index + 1} - name`,
//             value: lease.guarantorInfo.guarantors[index]?.name
//           });
//         }
//         if (lease.guarantorInfo.guarantors[index]?.address) {
//           result.push({
//             key: `Guarantor #${index + 1} - address`,
//             value: lease.guarantorInfo.guarantors[index]?.address
//           });
//         }
//         if (lease.guarantorInfo.guarantors[index]?.city) {
//           result.push({
//             key: `Guarantor #${index + 1} - city`,
//             value: lease.guarantorInfo.guarantors[index]?.city
//           });
//         }
//         if (lease.guarantorInfo.guarantors[index]?.zip) {
//           result.push({
//             key: `Guarantor #${index + 1} - zip`,
//             value: lease.guarantorInfo.guarantors[index]?.zip
//           });
//         }
//         if (lease.guarantorInfo.guarantors[index]?.state) {
//           result.push({
//             key: `Guarantor #${index + 1} - state`,
//             value: lease.guarantorInfo.guarantors[index]?.state
//           });
//         }
//         if (lease.guarantorInfo.guarantors[index]?.phone) {
//           result.push({
//             key: `Guarantor #${index + 1} - phone`,
//             value: lease.guarantorInfo.guarantors[index]?.phone
//           });
//         }
//         if (lease.guarantorInfo.guarantors[index]?.email) {
//           result.push({
//             key: `Guarantor #${index + 1} - email`,
//             value: lease.guarantorInfo.guarantors[index]?.email
//           });
//         }
//       });
//     }
//     if (lease.guarantorInfo.individuals?.length > 0) {
//       lease.guarantorInfo.individuals.forEach((record, index) => {
//         if (lease.guarantorInfo.individuals[index]?.name) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - name`,
//             value: lease.guarantorInfo.individuals[index]?.name
//           });
//         }
//         if (lease.guarantorInfo.individuals[index]?.address) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - address`,
//             value: lease.guarantorInfo.individuals[index]?.address
//           });
//         }
//         if (lease.guarantorInfo.individuals[index]?.city) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - city`,
//             value: lease.guarantorInfo.individuals[index]?.city
//           });
//         }
//         if (lease.guarantorInfo.individuals[index]?.zip) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - zip`,
//             value: lease.guarantorInfo.individuals[index]?.zip
//           });
//         }
//         if (lease.guarantorInfo.individuals[index]?.state) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - state`,
//             value: lease.guarantorInfo.individuals[index]?.state
//           });
//         }
//         if (lease.guarantorInfo.individuals[index]?.phone) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - phone`,
//             value: lease.guarantorInfo.individuals[index]?.phone
//           });
//         }
//         if (lease.guarantorInfo.individuals[index]?.email) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - email`,
//             value: lease.guarantorInfo.individuals[index]?.email
//           });
//         }
//         if (lease.guarantorInfo.individuals[index]?.SSN) {
//           result.push({
//             key: `Individual guarantor #${index + 1} - SSN`,
//             value: lease.guarantorInfo.individuals[index]?.SSN
//           });
//         }
//       });
//     }
//     if (lease.guarantorInfo.marriedCouples?.length > 0) {
//       lease.guarantorInfo.marriedCouples.forEach((record, index) => {
//         if (lease.guarantorInfo.marriedCouples[index]?.name1) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - name1`,
//             value: lease.guarantorInfo.marriedCouples[index]?.name1
//           });
//         }
//         if (lease.guarantorInfo.marriedCouples[index]?.name2) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - name2`,
//             value: lease.guarantorInfo.marriedCouples[index]?.name2
//           });
//         }
//         if (lease.guarantorInfo.marriedCouples[index]?.address1) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - address1`,
//             value: lease.guarantorInfo.marriedCouples[index]?.address1
//           });
//         }
//         if (lease.guarantorInfo.marriedCouples[index]?.address2) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - address2`,
//             value: lease.guarantorInfo.marriedCouples[index]?.address2
//           });
//         }
//         if (lease.guarantorInfo.marriedCouples[index]?.city1) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - city1`,
//             value: lease.guarantorInfo.marriedCouples[index]?.city1
//           });
//         }
//         if (lease.guarantorInfo.marriedCouples[index]?.city2) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - city2`,
//             value: lease.guarantorInfo.marriedCouples[index]?.city2
//           });
//         }
//         if (lease.guarantorInfo.marriedCouples[index]?.zip1) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - zip1`,
//             value: lease.guarantorInfo.marriedCouples[index]?.zip1
//           });
//         }
//         if (lease.guarantorInfo.marriedCouples[index]?.zip2) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - zip2`,
//             value: lease.guarantorInfo.marriedCouples[index]?.zip2
//           });
//         }
//         if (lease.guarantorInfo.marriedCouples[index]?.state1) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - state1`,
//             value: lease.guarantorInfo.marriedCouples[index]?.state1
//           });
//         }
//         if (lease.guarantorInfo.marriedCouples[index]?.state2) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - state2`,
//             value: lease.guarantorInfo.marriedCouples[index]?.state2
//           });
//         }
//         if (lease.guarantorInfo.marriedCouples[index]?.phone1) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - phone1`,
//             value: lease.guarantorInfo.marriedCouples[index]?.phone1
//           });
//         }
//         if (lease.guarantorInfo.marriedCouples[index]?.phone2) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - phone2`,
//             value: lease.guarantorInfo.marriedCouples[index]?.phone2
//           });
//         }
//         if (lease.guarantorInfo.marriedCouples[index]?.email1) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - email1`,
//             value: lease.guarantorInfo.marriedCouples[index]?.email1
//           });
//         }
//         if (lease.guarantorInfo.marriedCouples[index]?.email2) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - email2`,
//             value: lease.guarantorInfo.marriedCouples[index]?.email2
//           });
//         }
//         if (lease.guarantorInfo.marriedCouples[index]?.SSN1) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - ssn1`,
//             value: lease.guarantorInfo.marriedCouples[index]?.SSN1
//           });
//         }
//         if (lease.guarantorInfo.marriedCouples[index]?.SSN2) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - ssn2`,
//             value: lease.guarantorInfo.marriedCouples[index]?.SSN2
//           });
//         }
//         if (lease.guarantorInfo.marriedCouples[index]?.hasSameAddress) {
//           result.push({
//             key: `Married couple guarantors #${index + 1} - has same address`,
//             value: lease.guarantorInfo.marriedCouples[index]?.hasSameAddress
//           });
//         }
//       });
//     }
//     if (lease.guarantorInfo.entities?.length > 0) {
//       lease.guarantorInfo.entities.forEach((record, index) => {
//         if (lease.guarantorInfo.entities[index]?.name) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - name`,
//             value: lease.guarantorInfo.entities[index]?.name
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.type) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - type`,
//             value: lease.guarantorInfo.entities[index]?.type
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.entityType) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - entity type`,
//             value: lease.guarantorInfo.entities[index]?.entityType
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.entityTypeOther) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - entity type other`,
//             value: lease.guarantorInfo.entities[index]?.entityTypeOther
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.stateOfFormation) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - state of formation`,
//             value: lease.guarantorInfo.entities[index]?.stateOfFormation
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.address) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - address`,
//             value: lease.guarantorInfo.entities[index]?.address
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.city) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - city`,
//             value: lease.guarantorInfo.entities[index]?.city
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.zip) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - zip`,
//             value: lease.guarantorInfo.entities[index]?.zip
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.state) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - state`,
//             value: lease.guarantorInfo.entities[index]?.state
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.phone) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - phone`,
//             value: lease.guarantorInfo.entities[index]?.phone
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.email) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - email`,
//             value: lease.guarantorInfo.entities[index]?.email
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.taxId) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - tax`,
//             value: lease.guarantorInfo.entities[index]?.taxId
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.attention) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - attention`,
//             value: lease.guarantorInfo.entities[index]?.attention
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.agentName) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - agent name`,
//             value: lease.guarantorInfo.entities[index]?.agentName
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.agentAddress) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - agent address`,
//             value: lease.guarantorInfo.entities[index]?.agentAddress
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.agentCity) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - agent city`,
//             value: lease.guarantorInfo.entities[index]?.agentCity
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.agentState) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - agent state`,
//             value: lease.guarantorInfo.entities[index]?.agentState
//           });
//         }
//         if (lease.guarantorInfo.entities[index]?.agentZip) {
//           result.push({
//             key: `Entity guarantor #${index + 1} - agent zip`,
//             value: lease.guarantorInfo.entities[index]?.agentZip
//           });
//         }
//       });
//     }
//   }
//   if (lease.guarantyCapBurnOffReductionPeriods?.length > 0) {
//     lease.guarantyCapBurnOffReductionPeriods.forEach((record, index) => {
//       if (lease.guarantyCapBurnOffReductionPeriods[index]?.year) {
//         result.push({
//           key: `Guaranty cap burn off reduction periods #${index + 1} - year`,
//           value: lease.guarantyCapBurnOffReductionPeriods[index].year
//         });
//       }
//       if (lease.guarantyCapBurnOffReductionPeriods[index]?.reducedAmount) {
//         result.push({
//           key: `Guaranty cap burn off reduction periods #${index +
//             1} - reduced amount`,
//           value: lease.guarantyCapBurnOffReductionPeriods[index].reducedAmount
//         });
//       }
//       if (lease.guarantyCapBurnOffReductionPeriods[index]?.numberOfMonths) {
//         result.push({
//           key: `Guaranty cap burn off reduction periods #${index +
//             1} - number of months`,
//           value: lease.guarantyCapBurnOffReductionPeriods[index].numberOfMonths
//         });
//       }
//     });
//   }
//   if (lease.hardEstimatedDeliveryDate) {
//     result.push({
//       key: "Hard estimated delivery date",
//       value: lease.hardEstimatedDeliveryDate
//     });
//   }
//   if (lease.hardTerminationDate) {
//     result.push({
//       key: "Hard termination date",
//       value: lease.hardTerminationDate
//     });
//   }
//   if (lease.hasAdditionalIndividualTenants) {
//     result.push({
//       key: "Has additional individual tenants",
//       value: lease.hasAdditionalIndividualTenants
//     });
//   }
//   if (lease.hasAdditionalIndividualAssignees) {
//     result.push({
//       key: "Has additional individual assignees",
//       value: lease.hasAdditionalIndividualAssignees
//     });
//   }
//   if (lease.hasAdvanceRent) {
//     result.push({
//       key: "Has advance rent",
//       value: lease.hasAdvanceRent
//     });
//   }
//   if (lease.hasAlternativeCommencement) {
//     result.push({
//       key: "Has alternative commencement",
//       value: lease.hasAlternativeCommencement
//     });
//   }
//   if (lease.hasApplyIncreaseToRenewals) {
//     result.push({
//       key: "Has apply increase to renewals",
//       value: lease.hasApplyIncreaseToRenewals
//     });
//   }
//   if (lease.hasAreaRemeasurement) {
//     result.push({
//       key: "Has area remeasurement",
//       value: lease.hasAreaRemeasurement
//     });
//   }
//   if (lease.hasAuditRight) {
//     result.push({
//       key: "Has audit right",
//       value: lease.hasAuditRight
//     });
//   }
//   if (lease.hasBurnOff) {
//     result.push({
//       key: "Has burn off",
//       value: lease.hasBurnOff
//     });
//   }
//   if (lease.hasCapOnCam) {
//     result.push({
//       key: "Has cap on cam",
//       value: lease.hasCapOnCam
//     });
//   }
//   if (lease.hasCleaningSpecs) {
//     result.push({
//       key: "Has cleaning specs",
//       value: lease.hasCleaningSpecs
//     });
//   }
//   if (lease.hasCommonAreaWork) {
//     result.push({
//       key: "Has common area work",
//       value: lease.hasCommonAreaWork
//     });
//   }
//   if (lease.hasConfidentiality) {
//     result.push({
//       key: "Has confidentiality",
//       value: lease.hasConfidentiality
//     });
//   }
//   if (lease.hasCopyNoticeAddress) {
//     result.push({
//       key: "Has copy notice address",
//       value: lease.hasCopyNoticeAddress
//     });
//   }
//   if (lease.hasCpiRent) {
//     result.push({
//       key: "Has cpi rent",
//       value: lease.hasCpiRent
//     });
//   }
//   if (lease.hasDamageDeposit) {
//     result.push({
//       key: "Has damage deposit",
//       value: lease.hasDamageDeposit
//     });
//   }
//   if (lease.hasCleaningFee) {
//     result.push({
//       key: "Has cleaning fee",
//       value: lease.hasCleaningFee
//     });
//   }
//   if (lease.hasEarlyAccess) {
//     result.push({
//       key: "Has early access",
//       value: lease.hasEarlyAccess
//     });
//   }
//   if (lease.hasEntireFloor) {
//     result.push({
//       key: "Has entire floor",
//       value: lease.hasEntireFloor
//     });
//   }
//   if (lease.hasExclusiveParking) {
//     result.push({
//       key: "Has exclusive parking",
//       value: lease.hasExclusiveParking
//     });
//   }
//   if (lease.hasExclusiveUse) {
//     result.push({
//       key: "Has exclusive use",
//       value: lease.hasExclusiveUse
//     });
//   }
//   if (lease.hasExclusiveUseExhibit) {
//     result.push({
//       key: "Has exclusive use exhibit",
//       value: lease.hasExclusiveUseExhibit
//     });
//   }
//   if (lease.hasExerciseRenewalOption) {
//     result.push({
//       key: "Has exercise renewal option",
//       value: lease.hasExerciseRenewalOption
//     });
//   }
//   if (lease.hasExistingGuarantors) {
//     result.push({
//       key: "Has existing guarantors",
//       value: lease.hasExistingGuarantors
//     });
//   }
//   if (lease.hasExistingOptionRemoved) {
//     result.push({
//       key: "Has existing option removed",
//       value: lease.hasExistingOptionRemoved
//     });
//   }
//   if (lease.hasExpansionOption) {
//     result.push({
//       key: "Has expansion option",
//       value: lease.hasExpansionOption
//     });
//   }
//   if (lease.hasFitTestAllowance) {
//     result.push({
//       key: "Has fit test allowance",
//       value: lease.hasFitTestAllowance
//     });
//   }
//   if (lease.hasFixturingPeriod) {
//     result.push({
//       key: "Has fixturing period",
//       value: lease.hasFixturingPeriod
//     });
//   }
//   if (lease.hasFreeRent) {
//     result.push({
//       key: "Has free rent",
//       value: lease.hasFreeRent
//     });
//   }
//   if (lease.hasFreeRentAddedToTerm) {
//     result.push({
//       key: "Has free rent added to term",
//       value: lease.hasFreeRentAddedToTerm
//     });
//   }
//   if (lease.hasFutureSNDA) {
//     result.push({
//       key: "Has future SNDA",
//       value: lease.hasFutureSNDA
//     });
//   }
//   if (lease.hasGoDark) {
//     result.push({
//       key: "Has go dark",
//       value: lease.hasGoDark
//     });
//   }
//   if (lease.hasGrandOpening) {
//     result.push({
//       key: "Has grand opening",
//       value: lease.hasGrandOpening
//     });
//   }
//   if (lease.hasGuarantyCapBurndown) {
//     result.push({
//       key: "Has guaranty cap burndown",
//       value: lease.hasGuarantyCapBurndown
//     });
//   }
//   if (lease.hasHvacCap) {
//     result.push({
//       key: "Has HVAC cap",
//       value: lease.hasHvacCap
//     });
//   }
//   if (lease.hasHvacLandlordReplacement) {
//     result.push({
//       key: "Has HVAC landlord replacement",
//       value: lease.hasHvacLandlordReplacement
//     });
//   }
//   if (lease.hasHvacLandlordWarranty) {
//     result.push({
//       key: "Has HVAC landlord warranty",
//       value: lease.hasHvacLandlordWarranty
//     });
//   }
//   if (lease.hasHvacSpecs) {
//     result.push({
//       key: "Has HVAC specs",
//       value: lease.hasHvacSpecs
//     });
//   }
//   if (lease.hasHvacTenantCap) {
//     result.push({
//       key: "Has HVAC tenant cap",
//       value: lease.hasHvacTenantCap
//     });
//   }
//   if (lease.hasHvacTenantMaintain) {
//     result.push({
//       key: "Has HVAC tenant maintain",
//       value: lease.hasHvacTenantMaintain
//     });
//   }
//   if (lease.hasInitialDeliveryWork) {
//     result.push({
//       key: "Has initial delivery work",
//       value: lease.hasInitialDeliveryWork
//     });
//   }
//   if (lease.hasKickout) {
//     result.push({
//       key: "Has kickout",
//       value: lease.hasKickout
//     });
//   }
//   if (lease.hasKickoutTerminationFee) {
//     result.push({
//       key: "Has kickout termination fee",
//       value: lease.hasKickoutTerminationFee
//     });
//   }
//   if (lease.hasLLRecaptureRight) {
//     result.push({
//       key: "Has landlord recapture right",
//       value: lease.hasLLRecaptureRight
//     });
//   }
//   if (lease.hasLandlordBroker) {
//     result.push({
//       key: "Has landlord broker",
//       value: lease.hasLandlordBroker
//     });
//   }
//   if (lease.hasLandlordConstructionCap) {
//     result.push({
//       key: "Has landlord construction cap",
//       value: lease.hasLandlordConstructionCap
//     });
//   }
//   if (lease.hasLandlordCoordinationFee) {
//     result.push({
//       key: "Has landlord coordination fee",
//       value: lease.hasLandlordCoordinationFee
//     });
//   }
//   if (lease.hasLandlordDefault) {
//     result.push({
//       key: "Has landlord default",
//       value: lease.hasLandlordDefault
//     });
//   }
//   if (lease.hasLandlordDemoTermination) {
//     result.push({
//       key: "Has landlord demo termination",
//       value: lease.hasLandlordDemoTermination
//     });
//   }
//   if (lease.hasLandlordDoesTurnkey) {
//     result.push({
//       key: "Has landlord does turnkey",
//       value: lease.hasLandlordDoesTurnkey
//     });
//   }
//   if (lease.hasLandlordIndemnity) {
//     result.push({
//       key: "Has landlord indemnity",
//       value: lease.hasLandlordIndemnity
//     });
//   }
//   if (lease.hasLandlordInsuranceRequirement) {
//     result.push({
//       key: "Has landlord insurance requirement",
//       value: lease.hasLandlordInsuranceRequirement
//     });
//   }
//   if (lease.hasLandlordLienSubordination) {
//     result.push({
//       key: "Has landlord lien subordination",
//       value: lease.hasLandlordLienSubordination
//     });
//   }
//   if (lease.hasLandlordOfacRep) {
//     result.push({
//       key: "Has landlord OFAC rep",
//       value: lease.hasLandlordOfacRep
//     });
//   }
//   if (lease.hasLandlordPctRentTerminationRight) {
//     result.push({
//       key: "Has landlord pct rent termination right",
//       value: lease.hasLandlordPctRentTerminationRight
//     });
//   }
//   if (lease.hasLandlordPermittedUseRep) {
//     result.push({
//       key: "Has landlord permitted use rep",
//       value: lease.hasLandlordPermittedUseRep
//     });
//   }
//   if (lease.hasLandlordWarranty) {
//     result.push({
//       key: "Has landlord warranty",
//       value: lease.hasLandlordWarranty
//     });
//   }
//   if (lease.hasLayoutPlan) {
//     result.push({
//       key: "Has layout plan",
//       value: lease.hasLayoutPlan
//     });
//   }
//   if (lease.hasLeaseAssignment) {
//     result.push({
//       key: "Has lease assignment",
//       value: lease.hasLeaseAssignment
//     });
//   }
//   if (lease.hasLenderConsentRequired) {
//     result.push({
//       key: "Has lender consent required",
//       value: lease.hasLenderConsentRequired
//     });
//   }
//   if (lease.hasLetterOfCredit) {
//     result.push({
//       key: "Has letter of credit",
//       value: lease.hasLetterOfCredit
//     });
//   }
//   if (lease.hasLetterOfCreditDeliveredAfterLease) {
//     result.push({
//       key: "Has letter of credit delivered after lease",
//       value: lease.hasLetterOfCreditDeliveredAfterLease
//     });
//   }
//   if (lease.hasLimitedGrossSalesReporting) {
//     result.push({
//       key: "Has limited gross sales reporting",
//       value: lease.hasLimitedGrossSalesReporting
//     });
//   }
//   if (lease.hasLimitedRelocation) {
//     result.push({
//       key: "Has limited relocation",
//       value: lease.hasLimitedRelocation
//     });
//   }
//   if (lease.hasMarketingCap) {
//     result.push({
//       key: "Has marketing cap",
//       value: lease.hasMarketingCap
//     });
//   }
//   if (lease.hasMitigationOfDamages) {
//     result.push({
//       key: "Has mitigation of damages",
//       value: lease.hasMitigationOfDamages
//     });
//   }
//   if (lease.hasMonetaryCapOnGuaranty) {
//     result.push({
//       key: "Has monetary cap on guaranty",
//       value: lease.hasMonetaryCapOnGuaranty
//     });
//   }
//   if (lease.hasMonetaryDefaultNotice) {
//     result.push({
//       key: "Has monetary default notice",
//       value: lease.hasMonetaryDefaultNotice
//     });
//   }
//   if (lease.hasMonthToMonth) {
//     result.push({
//       key: "Has month to month",
//       value: lease.hasMonthToMonth
//     });
//   }
//   if (lease.hasMutualIndemnity) {
//     result.push({
//       key: "Has mutual indemnity",
//       value: lease.hasMutualIndemnity
//     });
//   }
//   if (lease.hasOngoingCoTenancy) {
//     result.push({
//       key: "Has ongoing co tenancy",
//       value: lease.hasOngoingCoTenancy
//     });
//   }
//   if (lease.hasOpeningCoTenancy) {
//     result.push({
//       key: "Has opening co tenancy",
//       value: lease.hasOpeningCoTenancy
//     });
//   }
//   if (lease.hasOpeningCovenant) {
//     result.push({
//       key: "Has opening covenant",
//       value: lease.hasOpeningCovenant
//     });
//   }
//   if (lease.hasOutdoorSeating) {
//     result.push({
//       key: "Has outdoor seating",
//       value: lease.hasOutdoorSeating
//     });
//   }
//   if (lease.hasParking) {
//     result.push({
//       key: "Has parking",
//       value: lease.hasParking
//     });
//   }
//   if (lease.hasPatriotActUpdate) {
//     result.push({
//       key: "Has patriot act update",
//       value: lease.hasPatriotActUpdate
//     });
//   }
//   if (lease.hasPercentageRent) {
//     result.push({
//       key: "Has percentage rent",
//       value: lease.hasPercentageRent
//     });
//   }
//   if (lease.hasPercentageRentUnnaturalEscalation) {
//     result.push({
//       key: "Has percentage rent unnatural escalation",
//       value: lease.hasPercentageRentUnnaturalEscalation
//     });
//   }
//   if (lease.hasPermitContingency) {
//     result.push({
//       key: "Has permit contingency",
//       value: lease.hasPermitContingency
//     });
//   }
//   if (lease.hasLiquorLicenseContingency) {
//     result.push({
//       key: "Has liquor license contingency",
//       value: lease.hasLiquorLicenseContingency
//     });
//   }
//   if (lease.hasPermittedTransfer) {
//     result.push({
//       key: "Has permitted transfer",
//       value: lease.hasPermittedTransfer
//     });
//   }
//   if (lease.hasPlansDeemedApproved) {
//     result.push({
//       key: "Has plans deemed approved",
//       value: lease.hasPlansDeemedApproved
//     });
//   }
//   if (lease.hasPostAddressSameAsPre) {
//     result.push({
//       key: "Has post address same as pre",
//       value: lease.hasPostAddressSameAsPre
//     });
//   }
//   if (lease.hasPreConstruction) {
//     result.push({
//       key: "Has pre construction",
//       value: lease.hasPreConstruction
//     });
//   }
//   if (lease.hasPremisesAreaSizeStipulated) {
//     result.push({
//       key: "Has premises area size stipulated",
//       value: lease.hasPremisesAreaSizeStipulated
//     });
//   }
//   if (lease.hasPremisesExpansion) {
//     result.push({
//       key: "Has premises expansion",
//       value: lease.hasPremisesExpansion
//     });
//   }
//   if (lease.hasPriorAmendment) {
//     result.push({
//       key: "Has prior amendment",
//       value: lease.hasPriorAmendment
//     });
//   }
//   if (lease.hasPriorAssignments) {
//     result.push({
//       key: "Has prior assignments",
//       value: lease.hasPriorAssignments
//     });
//   }
//   if (lease.hasPromotionFund) {
//     result.push({
//       key: "Has promotion fund",
//       value: lease.hasPromotionFund
//     });
//   }
//   if (lease.hasPylonSign) {
//     result.push({
//       key: "Has pylon sign",
//       value: lease.hasPylonSign
//     });
//   }
//   if (lease.hasPylonSignExhibit) {
//     result.push({
//       key: "Has pylon sign exhibit",
//       value: lease.hasPylonSignExhibit
//     });
//   }
//   if (lease.hasRadiusRestriction) {
//     result.push({
//       key: "Has radius restriction",
//       value: lease.hasRadiusRestriction
//     });
//   }
//   if (lease.hasReasonableFutureSNDAEfforts) {
//     result.push({
//       key: "Has reasonable future SNDA efforts",
//       value: lease.hasReasonableFutureSNDAEfforts
//     });
//   }
//   if (lease.hasRecaptureWithRescission) {
//     result.push({
//       key: "Has recapture with rescission",
//       value: lease.hasRecaptureWithRescission
//     });
//   }
//   if (lease.hasReduction) {
//     result.push({
//       key: "Has reduction",
//       value: lease.hasReduction
//     });
//   }
//   if (lease.hasReleaseGuarantors) {
//     result.push({
//       key: "Has release guarantors",
//       value: lease.hasReleaseGuarantors
//     });
//   }
//   if (lease.hasReleaseOfTenant) {
//     result.push({
//       key: "Has release of tenant",
//       value: lease.hasReleaseOfTenant
//     });
//   }
//   if (lease.hasRelocation) {
//     result.push({
//       key: "Has relocation",
//       value: lease.hasRelocation
//     });
//   }
//   if (lease.hasRenewalOption) {
//     result.push({
//       key: "Has renewal option",
//       value: lease.hasRenewalOption
//     });
//   }
//   if (lease.hasRenewalRentArbitration) {
//     result.push({
//       key: "Has renewal rent arbitration",
//       value: lease.hasRenewalRentArbitration
//     });
//   }
//   if (lease.hasRentAbatement) {
//     result.push({
//       key: "Has rent abatement",
//       value: lease.hasRentAbatement
//     });
//   }
//   if (lease.hasRentAbatementTenantPerformingWork) {
//     result.push({
//       key: "Has rent abatement tenant performing work",
//       value: lease.hasRentAbatementTenantPerformingWork
//     });
//   }
//   if (lease.hasReplacementSignageCriteria) {
//     result.push({
//       key: "Has replacement signage criteria",
//       value: lease.hasReplacementSignageCriteria
//     });
//   }
//   if (lease.hasReplacementSitePlan) {
//     result.push({
//       key: "Has replacement site plan",
//       value: lease.hasReplacementSitePlan
//     });
//   }
//   if (lease.hasReservedParking) {
//     result.push({
//       key: "Has reserved parking",
//       value: lease.hasReservedParking
//     });
//   }
//   if (lease.hasResetBaseYearForRenewal) {
//     result.push({
//       key: "Has reset base year for renewal",
//       value: lease.hasResetBaseYearForRenewal
//     });
//   }
//   if (lease.hasResetBreakpoint) {
//     result.push({
//       key: "Has reset breakpoint",
//       value: lease.hasResetBreakpoint
//     });
//   }
//   if (lease.hasRightOfFirstOffer) {
//     result.push({
//       key: "Has right of first offer",
//       value: lease.hasRightOfFirstOffer
//     });
//   }
//   if (lease.hasRollingGuaranty) {
//     result.push({
//       key: "Has rolling guaranty",
//       value: lease.hasRollingGuaranty
//     });
//   }
//   if (lease.hasRooftopEquipment) {
//     result.push({
//       key: "Has rooftop equipment",
//       value: lease.hasRooftopEquipment
//     });
//   }
//   if (lease.hasSNDA) {
//     result.push({
//       key: "Has snda",
//       value: lease.hasSNDA
//     });
//   }
//   if (lease.hasSNDAForm) {
//     result.push({
//       key: "Has sndaform",
//       value: lease.hasSNDAForm
//     });
//   }
//   if (lease.hasSameBillingAddress) {
//     result.push({
//       key: "Has same billing address",
//       value: lease.hasSameBillingAddress
//     });
//   }
//   if (lease.hasSatellite) {
//     result.push({
//       key: "Has satellite",
//       value: lease.hasSatellite
//     });
//   }
//   if (lease.hasScaledHoldover) {
//     result.push({
//       key: "Has scaled holdover",
//       value: lease.hasScaledHoldover
//     });
//   }
//   if (lease.hasHoldoverFeeWaived) {
//     result.push({
//       key: "Has holdover fee waived",
//       value: lease.hasHoldoverFeeWaived
//     });
//   }
//   if (lease.hasSecureAreas) {
//     result.push({
//       key: "Has secure areas",
//       value: lease.hasSecureAreas
//     });
//   }
//   if (lease.hasSecurityDeposit) {
//     result.push({
//       key: "Has security deposit",
//       value: lease.hasSecurityDeposit
//     });
//   }
//   if (lease.hasSecurityDepositBurndown) {
//     result.push({
//       key: "Has security deposit burndown",
//       value: lease.hasSecurityDepositBurndown
//     });
//   }
//   if (lease.hasSecuritySystem) {
//     result.push({
//       key: "Has security system",
//       value: lease.hasSecuritySystem
//     });
//   }
//   if (lease.hasSignageDeposit) {
//     result.push({
//       key: "Has signage deposit",
//       value: lease.hasSignageDeposit
//     });
//   }
//   if (lease.hasSignageRendering) {
//     result.push({
//       key: "Has signage rendering",
//       value: lease.hasSignageRendering
//     });
//   }
//   if (lease.hasSimultaneousDelivery) {
//     result.push({
//       key: "Has simultaneous delivery",
//       value: lease.hasSimultaneousDelivery
//     });
//   }
//   if (lease.hasSpecialUseProvisions) {
//     result.push({
//       key: "Has special use provisions",
//       value: lease.hasSpecialUseProvisions
//     });
//   }
//   if (lease.hasSpecificExpirationDate) {
//     result.push({
//       key: "Has specific expiration date",
//       value: lease.hasSpecificExpirationDate
//     });
//   }
//   if (lease.hasSpecificTenant) {
//     result.push({
//       key: "Has specific tenant",
//       value: lease.hasSpecificTenant
//     });
//   }
//   if (lease.hasSpecifiedExpiration) {
//     result.push({
//       key: "Has specified expiration",
//       value: lease.hasSpecifiedExpiration
//     });
//   }
//   if (lease.hasSubordinateLandlordLien) {
//     result.push({
//       key: "Has subordinate landlord lien",
//       value: lease.hasSubordinateLandlordLien
//     });
//   }
//   if (lease.hasTemporaryPremises) {
//     result.push({
//       key: "Has temporary premises",
//       value: lease.hasTemporaryPremises
//     });
//   }
//   if (lease.hasTenantBuysFurniture) {
//     result.push({
//       key: "Has tenant buys furniture",
//       value: lease.hasTenantBuysFurniture
//     });
//   }
//   if (lease.hasTenantCasualtyTermination) {
//     result.push({
//       key: "Has tenant casualty termination",
//       value: lease.hasTenantCasualtyTermination
//     });
//   }
//   if (lease.hasTenantIncentiveAllowance) {
//     result.push({
//       key: "Has tenant incentive allowance",
//       value: lease.hasTenantIncentiveAllowance
//     });
//   }
//   if (lease.hasTenantPermitContingency) {
//     result.push({
//       key: "Has tenant permit contingency",
//       value: lease.hasTenantPermitContingency
//     });
//   }
//   if (lease.hasTenantRemeasurementChallenge) {
//     result.push({
//       key: "Has tenant remeasurement challenge",
//       value: lease.hasTenantRemeasurementChallenge
//     });
//   }
//   if (lease.hasTenantTerminationOption) {
//     result.push({
//       key: "Has tenant termination option",
//       value: lease.hasTenantTerminationOption
//     });
//   }
//   if (lease.hasThirdPartyContingency) {
//     result.push({
//       key: "Has third party contingency",
//       value: lease.hasThirdPartyContingency
//     });
//   }
//   if (lease.hasTiAllowance) {
//     result.push({
//       key: "Has ti allowance",
//       value: lease.hasTiAllowance
//     });
//   }
//   if (lease.hasUnreservedParking) {
//     result.push({
//       key: "Has unreserved parking",
//       value: lease.hasUnreservedParking
//     });
//   }
//   if (lease.hasUpdateLandlordAddress) {
//     result.push({
//       key: "Has update landlord's address",
//       value: lease.hasUpdateLandlordAddress
//     });
//   }
//   if (lease.hasUploadTenantImprovementPlans) {
//     result.push({
//       key: "Has upload tenant improvement plans",
//       value: lease.hasUploadTenantImprovementPlans
//     });
//   }
//   if (lease.hasUtilityInterruption) {
//     result.push({
//       key: "Has utility interruption",
//       value: lease.hasUtilityInterruption
//     });
//   }
//   if (lease.hasCrossDefault) {
//     result.push({
//       key: "Has cross default",
//       value: lease.hasCrossDefault
//     });
//   }
//   if (lease.hasWaiverJuryTrial) {
//     result.push({
//       key: "Has waiver jury trial",
//       value: lease.hasWaiverJuryTrial
//     });
//   }
//   if (lease.hasGroundLease) {
//     result.push({
//       key: "Has ground lease",
//       value: lease.hasGroundLease
//     });
//   }
//   if (lease.holdSpace?.optionDate) {
//     result.push({
//       key: "Hold space option date",
//       value: lease.holdSpace.optionDate
//     });
//   }
//   if (lease.holdSpace?.areaSize) {
//     result.push({
//       key: "Hold space area size",
//       value: lease.holdSpace.areaSize
//     });
//   }
//   if (lease.holdSpace?.floor) {
//     result.push({
//       key: "Hold space floor",
//       value: lease.holdSpace.floor
//     });
//   }
//   if (lease.hvacCapAmount) {
//     result.push({
//       key: "HVAC cap amount",
//       value: lease.hvacCapAmount
//     });
//   }
//   if (lease.hvacLandlordWarrantyTerm) {
//     result.push({
//       key: "HVAC warranty term",
//       value: lease.hvacLandlordWarrantyTerm
//     });
//   }
//   if (lease.hvacTenantCapAmount) {
//     result.push({
//       key: "Hvac tenant cap amount",
//       value: lease.hvacTenantCapAmount
//     });
//   }
//   if (lease.insuranceReimbursement) {
//     result.push({
//       key: "Insurance reimbursement",
//       value: lease.insuranceReimbursement
//     });
//   }
//   if (lease.kickoutEarlyTerminationDays) {
//     result.push({
//       key: "Kickout early termination days",
//       value: lease.kickoutEarlyTerminationDays
//     });
//   }
//   if (lease.kickoutMinimumSales) {
//     result.push({
//       key: "Kickout minimum sales",
//       value: lease.kickoutMinimumSales
//     });
//   }
//   if (lease.kickoutTerminationFee) {
//     result.push({
//       key: "Kickout termination fee",
//       value: lease.kickoutTerminationFee
//     });
//   }
//   if (lease.kickoutTestingPeriodYears) {
//     result.push({
//       key: "Kickout testing period years",
//       value: lease.kickoutTestingPeriodYears
//     });
//   }
//   if (lease.landlordArchitect) {
//     result.push({
//       key: "Landlord construction cap",
//       value: lease.landlordArchitect
//     });
//   }
//   if (lease.landlordBroker) {
//     result.push({
//       key: "Landlord broker",
//       value: lease.landlordBroker
//     });
//   }
//   if (lease.landlordConstructionCap) {
//     result.push({
//       key: "Landlord construction cap",
//       value: lease.landlordConstructionCap
//     });
//   }
//   if (lease.landlordDoesConstruction) {
//     result.push({
//       key: "Has landlord does construction",
//       value: lease.landlordDoesConstruction
//     });
//   }
//   if (lease.landlordPctRentTerminationRightPercentage) {
//     result.push({
//       key: "Landlord pct rent termination right percentage",
//       value: lease.landlordPctRentTerminationRightPercentage
//     });
//   }
//   if (lease.landlordPctRentTerminationRightTestingPeriod) {
//     result.push({
//       key: "Landlord pct rent termination right testing period",
//       value: lease.landlordPctRentTerminationRightTestingPeriod
//     });
//   }
//   if (lease.landlordPermittedUseRep) {
//     result.push({
//       key: "Landlord permitted use rep",
//       value: lease.landlordPermittedUseRep
//     });
//   }
//   if (lease.landlordRepresentative) {
//     result.push({
//       key: "landlordRepresentative",
//       value: lease.landlordRepresentative
//     });
//   }
//   if (lease.landlordWarranty?.hvac) {
//     result.push({
//       key: "Has landlord warranty hvac",
//       value: lease.landlordWarranty.hvac
//     });
//   }
//   if (lease.landlordWarranty?.electrical) {
//     result.push({
//       key: "Has landlord warranty electrical",
//       value: lease.landlordWarranty.electrical
//     });
//   }
//   if (lease.landlordWarranty?.plumbing) {
//     result.push({
//       key: "Has landlord warranty plumbing",
//       value: lease.landlordWarranty.plumbing
//     });
//   }
//   if (lease.landlordWarranty?.roof) {
//     result.push({
//       key: "Has landlord warranty roof",
//       value: lease.landlordWarranty.roof
//     });
//   }
//   if (lease.landlordWarranty?.commonAreas) {
//     result.push({
//       key: "Has landlord warranty common areas",
//       value: lease.landlordWarranty.commonAreas
//     });
//   }
//   if (lease.landlordWarranty?.landlordContractor) {
//     result.push({
//       key: "Has landlord warranty landlord contractor",
//       value: lease.landlordWarranty.landlordContractor
//     });
//   }
//   if (lease.landlordWarranty?.buildingSystems) {
//     result.push({
//       key: "Has landlord warranty building systems",
//       value: lease.landlordWarranty.buildingSystems
//     });
//   }
//   if (lease.leaseMonths) {
//     result.push({
//       key: "Lease months",
//       value: lease.leaseMonths
//     });
//   }
//   if (lease.leaseYears) {
//     result.push({
//       key: "Lease years",
//       value: lease.leaseYears
//     });
//   }
//   if (lease.lenderName) {
//     result.push({
//       key: "Lender name",
//       value: lease.lenderName
//     });
//   }
//   if (lease.letterOfCredit) {
//     result.push({
//       key: "Letter of credit",
//       value: lease.letterOfCredit
//     });
//   }
//   if (lease.letterOfCreditFinancialStandard) {
//     result.push({
//       key: "Letter of credit financial standard",
//       value: lease.letterOfCreditFinancialStandard
//     });
//   }
//   if (lease.marketingChargeEscalator) {
//     result.push({
//       key: "Marketing charge escalator",
//       value: lease.marketingChargeEscalator
//     });
//   }
//   if (lease.marketingChargeInitial) {
//     result.push({
//       key: "Marketing charge initial",
//       value: lease.marketingChargeInitial
//     });
//   }
//   if (lease.minimumSales?.length > 0) {
//     lease.minimumSales.forEach((record, index) => {
//       if (lease.minimumSales[index]?.date) {
//         result.push({
//           key: `Minimum sale #${index + 1} - value`,
//           value: lease.minimumSales[index].value
//         });
//       }
//     });
//   }
//   if (lease.monetaryCapMonths) {
//     result.push({
//       key: "Monetary cap months",
//       value: lease.monetaryCapMonths
//     });
//   }
//   if (lease.monetaryCapOnGuarantyAmount) {
//     result.push({
//       key: "Monetary cap on guaranty amount",
//       value: lease.monetaryCapOnGuarantyAmount
//     });
//   }
//   if (lease.monetaryCapOnGuarantyPeriod) {
//     result.push({
//       key: "Monetary cap on guaranty period",
//       value: lease.monetaryCapOnGuarantyPeriod
//     });
//   }
//   if (lease.monetaryCapType) {
//     result.push({
//       key: "Monetary cap type",
//       value: lease.monetaryCapType
//     });
//   }
//   if (lease.monthToMonthEnd) {
//     result.push({
//       key: "Month to month end",
//       value: lease.monthToMonthEnd
//     });
//   }
//   if (lease.monthToMonthStart) {
//     result.push({
//       key: "Month to month start",
//       value: lease.monthToMonthStart
//     });
//   }
//   if (lease.naturalExpirationDate) {
//     result.push({
//       key: "Natural expiration date",
//       value: lease.naturalExpirationDate
//     });
//   }
//   if (lease.noReleasePeriod) {
//     result.push({
//       key: "No release period",
//       value: lease.noReleasePeriod
//     });
//   }
//   if (lease.officeMaintenanceBaseYear) {
//     result.push({
//       key: "Office maintenance base year",
//       value: lease.officeMaintenanceBaseYear
//     });
//   }
//   if (lease.openingCoTenancy?.hasAnchor) {
//     result.push({
//       key: "Opening co tenancy has anchor",
//       value: lease.openingCoTenancy.hasAnchor
//     });
//   }
//   if (lease.openingCoTenancy?.anchorTenant) {
//     result.push({
//       key: "Opening co tenancy anchor tenant",
//       value: lease.openingCoTenancy.anchorTenant
//     });
//   }
//   if (lease.openingCoTenancy?.hasNamedTenants) {
//     result.push({
//       key: "Opening co tenancy has named tenants",
//       value: lease.openingCoTenancy.hasNamedTenants
//     });
//   }
//   if (lease.openingCoTenancy?.minNamedTenants) {
//     result.push({
//       key: "Opening co tenancy min named tenants",
//       value: lease.openingCoTenancy.minNamedTenants
//     });
//   }
//   if (lease.openingCoTenancy?.namedTenants) {
//     result.push({
//       key: "Opening co tenancy named tenants",
//       value: lease.openingCoTenancy.namedTenants
//     });
//   }
//   if (lease.openingCoTenancy?.hasPercentGLA) {
//     result.push({
//       key: "Opening co tenancy has percent gla",
//       value: lease.openingCoTenancy.hasPercentGLA
//     });
//   }
//   if (lease.openingCoTenancy?.minPercentAreaOpen) {
//     result.push({
//       key: "Opening co tenancy min percent area open",
//       value: lease.openingCoTenancy.minPercentAreaOpen
//     });
//   }
//   if (lease.openingCoTenancy?.dropDeadDateTenantHasOpened) {
//     result.push({
//       key: "Opening co tenancy drop dead date tenant has opened",
//       value: lease.openingCoTenancy.dropDeadDateTenantHasOpened
//     });
//   }
//   if (lease.openingCoTenancy?.dropDeadDateTenantHasNotOpened) {
//     result.push({
//       key: "Opening co tenancy drop dead date tenant has not opened",
//       value: lease.openingCoTenancy.dropDeadDateTenantHasNotOpened
//     });
//   }
//   if (lease.openingCoTenancy?.alternativeRent) {
//     result.push({
//       key: "Opening co tenancy alternative rent",
//       value: lease.openingCoTenancy.alternativeRent
//     });
//   }
//   if (lease.openingCoTenancy?.grossSalesPercent) {
//     result.push({
//       key: "Opening co tenancy gross sales percent",
//       value: lease.openingCoTenancy.grossSalesPercent
//     });
//   }
//   if (lease.openingCoTenancy?.baseRentPercent) {
//     result.push({
//       key: "Opening co tenancy base rent percent",
//       value: lease.openingCoTenancy.baseRentPercent
//     });
//   }
//   if (lease.openingCoTenancy?.namedTenantsReqReopeningPercent) {
//     result.push({
//       key: "Opening co tenancy named tenants req reopening percent",
//       value: lease.openingCoTenancy.namedTenantsReqReopeningPercent
//     });
//   }
//   if (lease.ongoingCoTenancy?.hasAnchor) {
//     result.push({
//       key: "Ongoing co tenancy has anchor",
//       value: lease.ongoingCoTenancy.hasAnchor
//     });
//   }
//   if (lease.ongoingCoTenancy?.anchorTenant) {
//     result.push({
//       key: "Ongoing co tenancy anchor tenant",
//       value: lease.ongoingCoTenancy.anchorTenant
//     });
//   }
//   if (lease.ongoingCoTenancy?.anchorTenantClosurePeriod) {
//     result.push({
//       key: "Ongoing co tenancy anchor tenant closure period",
//       value: lease.ongoingCoTenancy.anchorTenantClosurePeriod
//     });
//   }
//   if (lease.ongoingCoTenancy?.anchorTenantCurePeriod) {
//     result.push({
//       key: "Ongoing co tenancy anchor tenant cure period",
//       value: lease.ongoingCoTenancy.anchorTenantCurePeriod
//     });
//   }
//   if (lease.ongoingCoTenancy?.anchorTenantReqReopeningPercent) {
//     result.push({
//       key: "Ongoing co tenancy anchor tenant req reopening percent",
//       value: lease.ongoingCoTenancy.anchorTenantReqReopeningPercent
//     });
//   }
//   if (lease.ongoingCoTenancy?.hasNamedTenants) {
//     result.push({
//       key: "Ongoing co tenancy has named tenants",
//       value: lease.ongoingCoTenancy.hasNamedTenants
//     });
//   }
//   if (lease.ongoingCoTenancy?.minNamedTenants) {
//     result.push({
//       key: "Ongoing co tenancy min named tenants",
//       value: lease.ongoingCoTenancy.minNamedTenants
//     });
//   }
//   if (lease.ongoingCoTenancy?.namedTenants) {
//     result.push({
//       key: "Ongoing co tenancy named tenants",
//       value: lease.ongoingCoTenancy.namedTenants
//     });
//   }
//   if (lease.ongoingCoTenancy?.namedTenantsClosurePeriod) {
//     result.push({
//       key: "Ongoing co tenancy named tenants closure period",
//       value: lease.ongoingCoTenancy.namedTenantsClosurePeriod
//     });
//   }
//   if (lease.ongoingCoTenancy?.namedTenantsCurePeriod) {
//     result.push({
//       key: "Ongoing co tenancy named tenants cure period",
//       value: lease.ongoingCoTenancy.namedTenantsCurePeriod
//     });
//   }
//   if (lease.ongoingCoTenancy?.namedTenantsReqReopeningPercent) {
//     result.push({
//       key: "Ongoing co tenancy named tenants req reopening percent",
//       value: lease.ongoingCoTenancy.namedTenantsReqReopeningPercent
//     });
//   }
//   if (lease.ongoingCoTenancy?.hasPercentGLA) {
//     result.push({
//       key: "Ongoing co tenancy has percent gla",
//       value: lease.ongoingCoTenancy.hasPercentGLA
//     });
//   }
//   if (lease.ongoingCoTenancy?.dropDeadDate) {
//     result.push({
//       key: "Ongoing co tenancy drop dead date",
//       value: lease.ongoingCoTenancy.dropDeadDate
//     });
//   }
//   if (lease.ongoingCoTenancy?.minPercentAreaOpen) {
//     result.push({
//       key: "Ongoing co tenancy min percent area open",
//       value: lease.ongoingCoTenancy.minPercentAreaOpen
//     });
//   }
//   if (lease.ongoingCoTenancy?.alternativeRent) {
//     result.push({
//       key: "Ongoing co tenancy alternative rent",
//       value: lease.ongoingCoTenancy.alternativeRent
//     });
//   }
//   if (lease.ongoingCoTenancy?.grossSalesPercent) {
//     result.push({
//       key: "Ongoing co tenancy gross sales percent",
//       value: lease.ongoingCoTenancy.grossSalesPercent
//     });
//   }
//   if (lease.ongoingCoTenancy?.baseRentPercent) {
//     result.push({
//       key: "Ongoing co tenancy base rent percent",
//       value: lease.ongoingCoTenancy.baseRentPercent
//     });
//   }
//   if (lease.ongoingCoTenancy?.minimumOperatingPeriod) {
//     result.push({
//       key: "Ongoing co tenancy minimum operating period",
//       value: lease.ongoingCoTenancy.minimumOperatingPeriod
//     });
//   }
//   if (lease.ongoingCoTenancy?.namedTenantsList?.length > 0) {
//     lease.ongoingCoTenancy.namedTenantsList.forEach((record, index) => {
//       if (lease.ongoingCoTenancy.namedTenantsList[index]?.name) {
//         result.push({
//           key: `Ongoing Co Tenancy named tenant #${index + 1} - name`,
//           value: lease.ongoingCoTenancy.namedTenantsList[index]?.name
//         });
//       }
//       if (lease.ongoingCoTenancy.namedTenantsList[index]?.reqMinArea) {
//         result.push({
//           key: `Ongoing Co Tenancy named tenant #${index + 1} - req min area`,
//           value: lease.ongoingCoTenancy.namedTenantsList[index]?.reqMinArea
//         });
//       }
//     });
//   }
//   if (lease.outdoorSeatingType) {
//     result.push({
//       key: "Outdoor seating type",
//       value: lease.outdoorSeatingType
//     });
//   }
//   if (lease.outdoorSeatingAreaSize) {
//     result.push({
//       key: "Outdoor seating area size",
//       value: lease.outdoorSeatingAreaSize
//     });
//   }
//   if (lease.parkingAmount) {
//     result.push({
//       key: "Parking amount",
//       value: lease.parkingAmount
//     });
//   }
//   if (lease.parkingAnnualEscalator) {
//     result.push({
//       key: "Parking annual escalator",
//       value: lease.parkingAnnualEscalator
//     });
//   }
//   if (lease.parkingCharge) {
//     result.push({
//       key: "Parking charge",
//       value: lease.parkingCharge
//     });
//   }
//   if (lease.parkingType) {
//     result.push({
//       key: "Parking type",
//       value: lease.parkingType
//     });
//   }
//   if (lease.pctRentBaseGrossSales) {
//     result.push({
//       key: "Pct rent base gross sales",
//       value: lease.pctRentBaseGrossSales
//     });
//   }
//   if (lease.pctRentHasNaturalBreakpoint) {
//     result.push({
//       key: "Has pct rent has natural breakpoint",
//       value: lease.pctRentHasNaturalBreakpoint
//     });
//   }
//   if (lease.percentageRent) {
//     result.push({
//       key: "Percentage Rent",
//       value: lease.percentageRent
//     });
//   }
//   if (lease.percentageRentUnnaturalEscalation) {
//     result.push({
//       key: "Percentage rent unnatural escalation",
//       value: lease.percentageRentUnnaturalEscalation
//     });
//   }
//   if (lease.permitChargesType) {
//     result.push({
//       key: "Permit charges type",
//       value: lease.permitChargesType
//     });
//   }
//   if (lease.permitContingencyType) {
//     result.push({
//       key: "Permit contingency type",
//       value: lease.permitContingencyType
//     });
//   }
//   if (lease.permittedUseDryCleaner) {
//     result.push({
//       key: "Has permitted use dry cleaner",
//       value: lease.permittedUseDryCleaner
//     });
//   }
//   if (lease.permittedUseFitness) {
//     result.push({
//       key: "Has permitted use fitness",
//       value: lease.permittedUseFitness
//     });
//   }
//   if (lease.permittedUseLiquorSales) {
//     result.push({
//       key: "Has permitted use liquor sales",
//       value: lease.permittedUseLiquorSales
//     });
//   }
//   if (lease.permittedUseMedical) {
//     result.push({
//       key: "Has permitted use medical",
//       value: lease.permittedUseMedical
//     });
//   }
//   if (lease.permittedUseNailSalon) {
//     result.push({
//       key: "Has permitted use nail salon",
//       value: lease.permittedUseNailSalon
//     });
//   }
//   if (lease.permittedUseNoise) {
//     result.push({
//       key: "Has permitted use noise",
//       value: lease.permittedUseNoise
//     });
//   }
//   if (lease.permittedUseOdor) {
//     result.push({
//       key: "Has permitted use odor",
//       value: lease.permittedUseOdor
//     });
//   }
//   if (lease.permittedUseRestaurant) {
//     result.push({
//       key: "Has permitted use restaurant",
//       value: lease.permittedUseRestaurant
//     });
//   }
//   if (lease.permittedUseWater) {
//     result.push({
//       key: "Has permitted use water",
//       value: lease.permittedUseWater
//     });
//   }
//   if (lease.permittedUses) {
//     result.push({
//       key: "Permitted uses",
//       value: lease.permittedUses
//     });
//   }
//   if (lease.planApprovalPeriod) {
//     result.push({
//       key: "Plan approval period",
//       value: lease.planApprovalPeriod
//     });
//   }
//   if (lease.premiseAddress) {
//     result.push({
//       key: "Premise address",
//       value: lease.premiseAddress
//     });
//   }
//   if (lease.premiseAddressCity) {
//     result.push({
//       key: "Premise address city",
//       value: lease.premiseAddressCity
//     });
//   }
//   if (lease.premiseAddressState) {
//     result.push({
//       key: "Premise address state",
//       value: lease.premiseAddressState
//     });
//   }
//   if (lease.premiseAddressZip) {
//     result.push({
//       key: "Premise address zip",
//       value: lease.premiseAddressZip
//     });
//   }
//   if (lease.premiseAreaSize) {
//     result.push({
//       key: "Premise area size",
//       value: lease.premiseAreaSize
//     });
//   }
//   if (lease.premiseStoreroomNumber) {
//     result.push({
//       key: "Premise storeroom number",
//       value: lease.premiseStoreroomNumber
//     });
//   }
//   if (lease.premiseSuites?.length > 0) {
//     lease.premiseSuites.forEach((record, index) => {
//       if (lease.premiseSuites[index]?.number) {
//         result.push({
//           key: `Premise suite #${index + 1} - number`,
//           value: lease.premiseSuites[index].number
//         });
//       }
//       if (lease.premiseSuites[index]?.floor) {
//         result.push({
//           key: `Premise suite #${index + 1} - floor`,
//           value: lease.premiseSuites[index].floor
//         });
//       }
//       if (lease.premiseSuites[index]?.areaSize) {
//         result.push({
//           key: `Premise suite #${index + 1} - area size`,
//           value: lease.premiseSuites[index].areaSize
//         });
//       }
//     });
//   }
//   if (lease.premisesFloorNumber) {
//     result.push({
//       key: "Premises floor number",
//       value: lease.premisesFloorNumber
//     });
//   }
//   if (lease.premisesModType) {
//     result.push({
//       key: "Premises mod type",
//       value: lease.premisesModType
//     });
//   }
//   if (lease.previousAmendment?.length > 0) {
//     lease.previousAmendment.forEach((record, index) => {
//       if (lease.previousAmendment[index]?.date) {
//         result.push({
//           key: `Previous amendment #${index + 1} - date`,
//           value: lease.previousAmendment[index].date
//         });
//       }
//       if (lease.previousAmendment[index]?.name) {
//         result.push({
//           key: `Previous amendment #${index + 1} - name`,
//           value: lease.previousAmendment[index].name
//         });
//       }
//     });
//   }
//   if (lease.priorAssignments?.length > 0) {
//     lease.priorAssignments.forEach((record, index) => {
//       if (lease.priorAssignments[index]?.date) {
//         result.push({
//           key: `Prior assignment #${index + 1} - date`,
//           value: lease.priorAssignments[index].date
//         });
//       }
//       if (lease.priorAssignments[index]?.name) {
//         result.push({
//           key: `Prior assignment #${index + 1} - name`,
//           value: lease.priorAssignments[index].name
//         });
//       }
//       if (lease.priorAssignments[index]?.assignor) {
//         result.push({
//           key: `Prior assignment #${index + 1} - assignor`,
//           value: lease.priorAssignments[index].assignor
//         });
//       }
//       if (lease.priorAssignments[index]?.tenantType) {
//         result.push({
//           key: `Prior assignment #${index + 1} - tenant type`,
//           value: lease.priorAssignments[index].tenantType
//         });
//       }
//       if (lease.priorAssignments[index]?.entityType) {
//         result.push({
//           key: `Prior assignment #${index + 1} - entity type`,
//           value: lease.priorAssignments[index].entityType
//         });
//       }
//       if (lease.priorAssignments[index]?.entityTypeOther) {
//         result.push({
//           key: `Prior assignment #${index + 1} - entity type other`,
//           value: lease.priorAssignments[index].entityTypeOther
//         });
//       }
//       if (lease.priorAssignments[index]?.stateOfFormation) {
//         result.push({
//           key: `Prior assignment #${index + 1} - state of formation`,
//           value: lease.priorAssignments[index].stateOfFormation
//         });
//       }
//     });
//   }
//   if (lease.proporShareBreakdown?.taxes) {
//     result.push({
//       key: "Propor share breakdown taxes",
//       value: lease.proporShareBreakdown.taxes
//     });
//   }
//   if (lease.proporShareBreakdown?.office_maintenance) {
//     result.push({
//       key: "Propor share breakdown office maintenance",
//       value: lease.proporShareBreakdown.office_maintenance
//     });
//   }
//   if (lease.proporShareBreakdown?.cam) {
//     result.push({
//       key: "Propor share breakdown cam",
//       value: lease.proporShareBreakdown.cam
//     });
//   }
//   if (lease.proportionateShare) {
//     result.push({
//       key: "Proportionate share",
//       value: lease.proportionateShare
//     });
//   }
//   if (lease.radiusRestriction) {
//     result.push({
//       key: "Radius restriction",
//       value: lease.radiusRestriction
//     });
//   }
//   if (lease.realEstateReimbursement) {
//     result.push({
//       key: "Real estate reimbursement",
//       value: lease.realEstateReimbursement
//     });
//   }
//   if (lease.recapture?.surrenderAreaSize) {
//     result.push({
//       key: "Recapture surrender area size",
//       value: lease.recapture.surrenderAreaSize
//     });
//   }
//   if (lease.recapture?.remainingAreaSize) {
//     result.push({
//       key: "Recapture remaining area size",
//       value: lease.recapture.remainingAreaSize
//     });
//   }
//   if (lease.reductionAreaSize) {
//     result.push({
//       key: "Reduction area size",
//       value: lease.reductionAreaSize
//     });
//   }
//   if (lease.reductionPeriods?.length > 0) {
//     lease.reductionPeriods.forEach((record, index) => {
//       if (lease.reductionPeriods[index]?.date) {
//         result.push({
//           key: `Reduction period #${index + 1} - date`,
//           value: lease.reductionPeriods[index].date
//         });
//       }
//       if (lease.reductionPeriods[index]?.year) {
//         result.push({
//           key: `Reduction period #${index + 1} - year`,
//           value: lease.reductionPeriods[index].year
//         });
//       }
//       if (lease.reductionPeriods[index]?.amountReduced) {
//         result.push({
//           key: `Reduction period #${index + 1} - amount reduced`,
//           value: lease.reductionPeriods[index].amountReduced
//         });
//       }
//     });
//   }
//   if (lease.reductionRemainingAreaSize) {
//     result.push({
//       key: "Reduction remaining area size",
//       value: lease.reductionRemainingAreaSize
//     });
//   }
//   if (lease.reimbursementDate) {
//     result.push({
//       key: "Reimbursement date",
//       value: lease.reimbursementDate
//     });
//   }
//   if (lease.reimbursementDateType) {
//     result.push({
//       key: "Reimbursement date type",
//       value: lease.reimbursementDateType
//     });
//   }
//   if (lease.relocationPremisesAreaSize) {
//     result.push({
//       key: "Relocation premises area size",
//       value: lease.relocationPremisesAreaSize
//     });
//   }
//   if (lease.relocationPremisesFloor) {
//     result.push({
//       key: "Relocation premises floor",
//       value: lease.relocationPremisesFloor
//     });
//   }
//   if (lease.relocationPremisesSpaceNumber) {
//     result.push({
//       key: "Relocation premises space number",
//       value: lease.relocationPremisesSpaceNumber
//     });
//   }
//   if (lease.relocationRight) {
//     result.push({
//       key: "Has relocation right",
//       value: lease.relocationRight
//     });
//   }
//   if (lease.renewalCount) {
//     result.push({
//       key: "Renewal Count",
//       value: lease.renewalCount
//     });
//   }
//   if (lease.renewalInfo) {
//     lease.renewalInfo.forEach((renewalInfoRecord, renewalInfoIndex) => {
//       if (lease.renewalInfo[renewalInfoIndex].renewalBaseRent) {
//         result.push({
//           key: `Renewal #${renewalInfoIndex + 1} - renewal base rent`,
//           value: lease.renewalInfo[renewalInfoIndex].renewalBaseRent
//         });
//       }
//       if (lease.renewalInfo[renewalInfoIndex].renewalAnnualIncrease) {
//         result.push({
//           key: `Renewal #${renewalInfoIndex + 1} - renewal annual increase`,
//           value: lease.renewalInfo[renewalInfoIndex].renewalAnnualIncrease
//         });
//       }
//       if (lease.renewalInfo[renewalInfoIndex].renewalInputType) {
//         result.push({
//           key: `Renewal #${renewalInfoIndex + 1} - renewal input type`,
//           value: lease.renewalInfo[renewalInfoIndex].renewalInputType
//         });
//       }
//       if (lease.renewalInfo[renewalInfoIndex].renewalOptionRent) {
//         result.push({
//           key: `Renewal #${renewalInfoIndex + 1} - renewal option rent`,
//           value: lease.renewalInfo[renewalInfoIndex].renewalOptionRent
//         });
//       }
//       if (lease.renewalInfo[renewalInfoIndex].hasCpiRent) {
//         result.push({
//           key: `Renewal #${renewalInfoIndex + 1} - has cpi rent`,
//           value: lease.renewalInfo[renewalInfoIndex].hasCpiRent
//         });
//       }
//       if (lease.renewalInfo[renewalInfoIndex].cpiAnchorYears) {
//         result.push({
//           key: `Renewal #${renewalInfoIndex + 1} - cpi anchor years`,
//           value: lease.renewalInfo[renewalInfoIndex].cpiAnchorYears
//         });
//       }
//       if (lease.renewalInfo[renewalInfoIndex].renewalRentPeriods?.length > 0) {
//         lease.renewalInfo[renewalInfoIndex].renewalRentPeriods.forEach(
//           (renewalRentPeriodRecord, renewalRentPeriodIndex) => {
//             if (
//               lease.renewalInfo[renewalInfoIndex].renewalRentPeriods[
//                 renewalRentPeriodIndex
//               ].rentPerSqFoot
//             ) {
//               result.push({
//                 key: `Renewal #${renewalInfoIndex +
//                   1} - rent period #${renewalRentPeriodIndex +
//                   1} - rent per sq foot`,
//                 value:
//                   lease.renewalInfo[renewalInfoIndex].renewalRentPeriods[
//                     renewalRentPeriodIndex
//                   ].rentPerSqFoot
//               });
//             }
//             if (
//               lease.renewalInfo[renewalInfoIndex].renewalRentPeriods[
//                 renewalRentPeriodIndex
//               ].totalRent
//             ) {
//               result.push({
//                 key: `Renewal #${renewalInfoIndex +
//                   1} - rent period #${renewalRentPeriodIndex + 1} - total rent`,
//                 value:
//                   lease.renewalInfo[renewalInfoIndex].renewalRentPeriods[
//                     renewalRentPeriodIndex
//                   ].totalRent
//               });
//             }
//           }
//         );
//       }
//     });
//   }
//   if (lease.renewalOptionRent) {
//     result.push({
//       key: "Renewal option rent",
//       value: lease.renewalOptionRent
//     });
//   }
//   if (lease.renewalTerm) {
//     result.push({
//       key: "Renewal Term",
//       value: lease.renewalTerm
//     });
//   }
//   if (lease.renewalTermNotice?.statedPeriodLength) {
//     result.push({
//       key: "Renewal term notice stated period length",
//       value: lease.renewalTermNotice.statedPeriodLength
//     });
//   }
//   if (lease.renewalTermNotice?.rangePeriodEarliestLength) {
//     result.push({
//       key: "Renewal term notice range period earliest length",
//       value: lease.renewalTermNotice.rangePeriodEarliestLength
//     });
//   }
//   if (lease.renewalTermNotice?.rangePeriodLongestLength) {
//     result.push({
//       key: "Renewal term notice range period longest length",
//       value: lease.renewalTermNotice.rangePeriodLongestLength
//     });
//   }
//   if (lease.renewalTermNoticeType) {
//     result.push({
//       key: "Renewal term notice type",
//       value: lease.renewalTermNoticeType
//     });
//   }
//   if (lease.rentAbatementRequiredMinimum) {
//     result.push({
//       key: "Rent abatement required minimum",
//       value: lease.rentAbatementRequiredMinimum
//     });
//   }
//   if (lease.rentCalculationMethod) {
//     result.push({
//       key: "Rent calculation method",
//       value: lease.rentCalculationMethod
//     });
//   }
//   if (lease.rentCommenceAt) {
//     result.push({
//       key: "Rent commence at",
//       value: lease.rentCommenceAt
//     });
//   }
//   if (lease.rentInputType) {
//     result.push({
//       key: "Rent input type",
//       value: lease.rentInputType
//     });
//   }
//   if (lease.rentPeriods?.length > 0) {
//     lease.rentPeriods.forEach((record, index) => {
//       if (lease.rentPeriods[index]?.rentPerSqFoot) {
//         result.push({
//           key: `Rent period #${index + 1} - rent per sq foot`,
//           value: lease.rentPeriods[index].rentPerSqFoot
//         });
//       }
//       if (lease.rentPeriods[index]?.totalRent) {
//         result.push({
//           key: `Rent period #${index + 1} - total rent`,
//           value: lease.rentPeriods[index].totalRent
//         });
//       }
//     });
//   }
//   if (lease.requiredOpening?.days) {
//     result.push({
//       key: "Required opening days",
//       value: lease.requiredOpening.days
//     });
//   }
//   if (lease.requiredOpening?.from) {
//     result.push({
//       key: "Required opening from",
//       value: lease.requiredOpening.from
//     });
//   }
//   if (lease.reservedParkingPermits) {
//     result.push({
//       key: "Reserved parking permits",
//       value: lease.reservedParkingPermits
//     });
//   }
//   if (lease.rightOfFirstOffer?.spaces) {
//     result.push({
//       key: "Right of first offer spaces",
//       value: lease.rightOfFirstOffer.spaces
//     });
//   }
//   if (lease.rightOfFirstOffer?.hasCoTerminus) {
//     result.push({
//       key: "Right of first offer has co terminus",
//       value: lease.rightOfFirstOffer.hasCoTerminus
//     });
//   }
//   if (lease.rightOfFirstOffer?.remainingTermRequiredForCoTerminus) {
//     result.push({
//       key: "Right of first offer remaining term required for co terminus",
//       value: lease.rightOfFirstOffer.remainingTermRequiredForCoTerminus
//     });
//   }
//   if (lease.rightOfFirstOffer?.lateTermPeriod) {
//     result.push({
//       key: "Right of first offer late term period",
//       value: lease.rightOfFirstOffer.lateTermPeriod
//     });
//   }
//   if (lease.rightOfFirstOffer?.hasVacant) {
//     result.push({
//       key: "Right of first offer has vacant",
//       value: lease.rightOfFirstOffer.hasVacant
//     });
//   }
//   if (lease.rightOfFirstOffer?.areaSize) {
//     result.push({
//       key: "Right of first offer area size",
//       value: lease.rightOfFirstOffer.areaSize
//     });
//   }
//   if (lease.rightOfFirstOffer?.hasSuperiorRightsHolders) {
//     result.push({
//       key: "Right of first offer has superior rights holders",
//       value: lease.rightOfFirstOffer.hasSuperiorRightsHolders
//     });
//   }
//   if (lease.rofoPremises?.hasArbitration) {
//     result.push({
//       key: "ROFO premises has arbitration",
//       value: lease.rofoPremises.hasArbitration
//     });
//   }
//   if (lease.rofoPremises?.arbitrationMarket) {
//     result.push({
//       key: "ROFO premises arbitration market",
//       value: lease.rofoPremises.arbitrationMarket
//     });
//   }
//   if (lease.rofoPremisesContiguousFloor) {
//     result.push({
//       key: "ROFO premises contiguous floor",
//       value: lease.rofoPremisesContiguousFloor
//     });
//   }
//   if (lease.rofoPremisesNoContiguousFloor) {
//     result.push({
//       key: "ROFO premises no contiguous floor",
//       value: lease.rofoPremisesNoContiguousFloor
//     });
//   }
//   if (lease.rofoPremisesType) {
//     result.push({
//       key: "ROFO premises type",
//       value: lease.rofoPremisesType
//     });
//   }
//   if (lease.rofoTermsType) {
//     result.push({
//       key: "ROFO terms type",
//       value: lease.rofoTermsType
//     });
//   }
//   if (lease.rollingGuarantyPeriod) {
//     result.push({
//       key: "Rolling guaranty period",
//       value: lease.rollingGuarantyPeriod
//     });
//   }
//   if (lease.rollingGuarantyMeasuringPeriod) {
//     result.push({
//       key: "Rolling guaranty measuring period",
//       value: lease.rollingGuarantyMeasuringPeriod
//     });
//   }
//   if (lease.rooftop?.hasHVAC) {
//     result.push({
//       key: "Rooftop has HVAC",
//       value: lease.rooftop.hasHVAC
//     });
//   }
//   if (lease.rooftop?.hasAntennae) {
//     result.push({
//       key: "Rooftop has antennae",
//       value: lease.rooftop.hasAntennae
//     });
//   }
//   if (lease.rooftop?.termsConditionsType) {
//     result.push({
//       key: "Rooftop terms conditions type",
//       value: lease.rooftop.termsConditionsType
//     });
//   }
//   if (lease.salesReportingFreq) {
//     result.push({
//       key: "Sales reporting freq",
//       value: lease.salesReportingFreq
//     });
//   }
//   if (lease.securityDepositCents) {
//     result.push({
//       key: "Security deposit cents",
//       value: lease.securityDepositCents
//     });
//   }
//   if (lease.securityDepositType) {
//     result.push({
//       key: "Security deposit type",
//       value: lease.securityDepositType
//     });
//   }
//   if (lease.signageDeposit) {
//     result.push({
//       key: "Signage Deposit",
//       value: lease.signageDeposit
//     });
//   }
//   if (lease.softEstimatedDeliveryDate) {
//     result.push({
//       key: "Soft Estimated Delivery Date",
//       value: lease.softEstimatedDeliveryDate
//     });
//   }
//   if (lease.softTerminationDate) {
//     result.push({
//       key: "Soft termination date",
//       value: lease.softTerminationDate
//     });
//   }
//   if (lease.specifiedExpirationMonthDay) {
//     result.push({
//       key: "Specified expiration month day",
//       value: lease.specifiedExpirationMonthDay
//     });
//   }
//   if (lease.taxBaseYear) {
//     result.push({
//       key: "Tax base year",
//       value: lease.taxBaseYear
//     });
//   }
//   if (lease.taxBaseYearType) {
//     result.push({
//       key: "Tax base year type",
//       value: lease.taxBaseYearType
//     });
//   }
//   if (lease.temporaryPremises?.type) {
//     result.push({
//       key: "Temporary premises type",
//       value: lease.temporaryPremises.type
//     });
//   }
//   if (lease.temporaryPremises?.suiteNumber) {
//     result.push({
//       key: "Temporary premises suite number",
//       value: lease.temporaryPremises?.suiteumber
//     });
//   }
//   if (lease.temporaryPremises?.baseRent) {
//     result.push({
//       key: "Temporary premises base rent",
//       value: lease.temporaryPremises?.baeRent
//     });
//   }
//   if (lease.temporaryPremises?.areaSize) {
//     result.push({
//       key: "Temporary premises area size",
//       value: lease.temporaryPremises?.araSize
//     });
//   }
//   if (lease.temporaryPremises?.spaceOccupied) {
//     result.push({
//       key: "Temporary premises space occupied",
//       value: lease.temporaryPremises?.spaceOcupied
//     });
//   }
//   if (lease.temporaryPremises?.hasBaseRent) {
//     result.push({
//       key: "Temporary premises has base rent",
//       value: lease.temporaryPremises?.hasBaeRent
//     });
//   }
//   if (lease.temporaryPremises?.hasAdditionalRent) {
//     result.push({
//       key: "Temporary premises has additional rent",
//       value: lease.temporaryPremises?.hasAdditionlRent
//     });
//   }
//   if (lease.temporaryPremises?.floorNumber) {
//     result.push({
//       key: "Temporary premises floor number",
//       value: lease.temporaryPremises?.floorumber
//     });
//   }
//   if (lease.temporaryPremises?.commencementDateType) {
//     result.push({
//       key: "Temporary premises commencement date type",
//       value: lease.temporaryPremises?.commencementDaeType
//     });
//   }
//   if (lease.temporaryPremises?.commencementDate) {
//     result.push({
//       key: "Temporary premises commencement date",
//       value: lease.temporaryPremises?.commencemetDate
//     });
//   }
//   if (lease.tenantArchitect) {
//     result.push({
//       key: "Tenant architect",
//       value: lease.tenantArchitect
//     });
//   }
//   if (lease.tenantDoesConstruction) {
//     result.push({
//       key: "Has tenant does construction",
//       value: lease.tenantDoesConstruction
//     });
//   }
//   if (lease.tenantImprovements?.type) {
//     result.push({
//       key: "Tenant improvements type",
//       value: lease.tenantImprovements.type
//     });
//   }
//   if (lease.tenantImprovements?.payment) {
//     result.push({
//       key: "Tenant improvements payment",
//       value: lease.tenantImprovements.payment
//     });
//   }
//   if (lease.tenantImprovements?.landlordCapAmount) {
//     result.push({
//       key: "Tenant improvements landlord cap amount",
//       value: lease.tenantImprovements.landlordCapAmount
//     });
//   }
//   if (lease.tenantImprovements?.landlordCapAmountUsedBy) {
//     result.push({
//       key: "Tenant improvements landlord cap amount used by",
//       value: lease.tenantImprovements.landlordCapAmountUsedBy
//     });
//   }
//   if (lease.tenantImprovements?.workType) {
//     result.push({
//       key: "Tenant improvements work type",
//       value: lease.tenantImprovements.workType
//     });
//   }
//   if (lease.tenantImprovements?.hasSubcontractBids) {
//     result.push({
//       key: "Tenant improvements has subcontract bids",
//       value: lease.tenantImprovements.hasSubcontractBids
//     });
//   }
//   if (lease.tenantIncentiveAllowanceAmount) {
//     result.push({
//       key: "Tenant incentive allowance amount",
//       value: lease.tenantIncentiveAllowanceAmount
//     });
//   }
//   if (lease.tenantInfo?.hasCopyTo) {
//     result.push({
//       key: "Tenant info has copy to",
//       value: lease.tenantInfo.hasCopyTo
//     });
//   }
//   if (lease.tenantInfo?.name) {
//     result.push({
//       key: "Tenant info name",
//       value: lease.tenantInfo.name
//     });
//   }
//   if (lease.tenantInfo?.type) {
//     result.push({
//       key: "Tenant info type",
//       value: lease.tenantInfo.type
//     });
//   }
//   if (lease.tenantInfo?.entityType) {
//     result.push({
//       key: "Tenant info entity type",
//       value: lease.tenantInfo.entityType
//     });
//   }
//   if (lease.tenantInfo?.entityTypeOther) {
//     result.push({
//       key: "Tenant info entity type other",
//       value: lease.tenantInfo.entityTypeOther
//     });
//   }
//   if (lease.tenantInfo?.address1) {
//     result.push({
//       key: "Tenant info address1",
//       value: lease.tenantInfo.address1
//     });
//   }
//   if (lease.tenantInfo?.address2) {
//     result.push({
//       key: "Tenant info address2",
//       value: lease.tenantInfo.address2
//     });
//   }
//   if (lease.tenantInfo?.addressCity) {
//     result.push({
//       key: "Tenant info address city",
//       value: lease.tenantInfo.addressCity
//     });
//   }
//   if (lease.tenantInfo?.addressState) {
//     result.push({
//       key: "Tenant info address state",
//       value: lease.tenantInfo.addressState
//     });
//   }
//   if (lease.tenantInfo?.addressZip) {
//     result.push({
//       key: "Tenant info address zip",
//       value: lease.tenantInfo.addressZip
//     });
//   }
//   if (lease.tenantInfo?.hasBroker) {
//     result.push({
//       key: "Tenant info has broker",
//       value: lease.tenantInfo.hasBroker
//     });
//   }
//   if (lease.tenantInfo?.broker) {
//     result.push({
//       key: "Tenant info broker",
//       value: lease.tenantInfo.broker
//     });
//   }
//   if (lease.tenantInfo?.tradeName) {
//     result.push({
//       key: "Tenant info trade name",
//       value: lease.tenantInfo.tradeName
//     });
//   }
//   if (lease.tenantInfo?.ssn) {
//     result.push({
//       key: "Tenant info ssn",
//       value: lease.tenantInfo.ssn
//     });
//   }
//   if (lease.tenantInfo?.email) {
//     result.push({
//       key: "Tenant info email",
//       value: lease.tenantInfo.email
//     });
//   }
//   if (lease.tenantInfo?.phoneNumber) {
//     result.push({
//       key: "Tenant info phone number",
//       value: lease.tenantInfo.phoneNumber
//     });
//   }
//   if (lease.tenantInfo?.taxId) {
//     result.push({
//       key: "Tenant info tax",
//       value: lease.tenantInfo.taxId
//     });
//   }
//   if (lease.tenantInfo?.stateOfFormation) {
//     result.push({
//       key: "Tenant info state of formation",
//       value: lease.tenantInfo.stateOfFormation
//     });
//   }
//   if (lease.tenantInfo?.attention) {
//     result.push({
//       key: "Tenant info attention",
//       value: lease.tenantInfo.attention
//     });
//   }
//   if (lease.tenantInfo?.billingAddress1) {
//     result.push({
//       key: "Tenant info billing address1",
//       value: lease.tenantInfo.billingAddress1
//     });
//   }
//   if (lease.tenantInfo?.billingAddressCity) {
//     result.push({
//       key: "Tenant info billing address city",
//       value: lease.tenantInfo.billingAddressCity
//     });
//   }
//   if (lease.tenantInfo?.billingAddressState) {
//     result.push({
//       key: "Tenant info billing address state",
//       value: lease.tenantInfo.billingAddressState
//     });
//   }
//   if (lease.tenantInfo?.billingAddressZip) {
//     result.push({
//       key: "Tenant info billing address zip",
//       value: lease.tenantInfo.billingAddressZip
//     });
//   }
//   if (lease.tenantInfo?.billingPhoneNumber) {
//     result.push({
//       key: "Tenant info billing phone number",
//       value: lease.tenantInfo.billingPhoneNumber
//     });
//   }
//   if (lease.tenantInfo?.billingEmail) {
//     result.push({
//       key: "Tenant info billing email",
//       value: lease.tenantInfo.billingEmail
//     });
//   }
//   if (lease.tenantInfo?.billingAttention) {
//     result.push({
//       key: "Tenant info billing attention",
//       value: lease.tenantInfo.billingAttention
//     });
//   }
//   if (lease.tenantInfo?.emergencyName) {
//     result.push({
//       key: "Tenant info emergency name",
//       value: lease.tenantInfo.emergencyName
//     });
//   }
//   if (lease.tenantInfo?.emergencyPhoneNumber) {
//     result.push({
//       key: "Tenant info emergency phone number",
//       value: lease.tenantInfo.emergencyPhoneNumber
//     });
//   }
//   if (lease.tenantInfo?.emergencyEmail) {
//     result.push({
//       key: "Tenant info emergency email",
//       value: lease.tenantInfo.emergencyEmail
//     });
//   }
//   if (lease.tenantInfo?.hasPublicCompany) {
//     result.push({
//       key: "Tenant info has public company",
//       value: lease.tenantInfo.hasPublicCompany
//     });
//   }
//   if (lease.tenantInfo?.preLeaseAddress) {
//     result.push({
//       key: "Tenant info pre lease address",
//       value: lease.tenantInfo.preLeaseAddress
//     });
//   }
//   if (lease.tenantInfo?.preLeaseCity) {
//     result.push({
//       key: "Tenant info pre lease city",
//       value: lease.tenantInfo.preLeaseCity
//     });
//   }
//   if (lease.tenantInfo?.preLeaseState) {
//     result.push({
//       key: "Tenant info pre lease state",
//       value: lease.tenantInfo.preLeaseState
//     });
//   }
//   if (lease.tenantInfo?.preLeaseZipCode) {
//     result.push({
//       key: "Tenant info pre lease zip code",
//       value: lease.tenantInfo.preLeaseZipCode
//     });
//   }
//   if (lease.tenantInfo?.preLeasePhoneNumber) {
//     result.push({
//       key: "Tenant info pre lease phone number",
//       value: lease.tenantInfo.preLeasePhoneNumber
//     });
//   }
//   if (lease.tenantInfo?.preLeaseEmail) {
//     result.push({
//       key: "Tenant info pre lease email",
//       value: lease.tenantInfo.preLeaseEmail
//     });
//   }
//   if (lease.tenantInfo?.preLeaseAttention) {
//     result.push({
//       key: "Tenant info pre lease attention",
//       value: lease.tenantInfo.preLeaseAttention
//     });
//   }
//   if (lease.tenantInfo?.postLeaseAddress) {
//     result.push({
//       key: "Tenant info post lease address",
//       value: lease.tenantInfo.postLeaseAddress
//     });
//   }
//   if (lease.tenantInfo?.postLeaseCity) {
//     result.push({
//       key: "Tenant info post lease city",
//       value: lease.tenantInfo.postLeaseCity
//     });
//   }
//   if (lease.tenantInfo?.postLeaseState) {
//     result.push({
//       key: "Tenant info post lease state",
//       value: lease.tenantInfo.postLeaseState
//     });
//   }
//   if (lease.tenantInfo?.postLeaseZipCode) {
//     result.push({
//       key: "Tenant info post lease zip code",
//       value: lease.tenantInfo.postLeaseZipCode
//     });
//   }
//   if (lease.tenantInfo?.postLeaseAttention) {
//     result.push({
//       key: "Tenant info post lease attention",
//       value: lease.tenantInfo.postLeaseAttention
//     });
//   }
//   if (lease.tenantInfo?.copyToName) {
//     result.push({
//       key: "Tenant info copy to name",
//       value: lease.tenantInfo.copyToName
//     });
//   }
//   if (lease.tenantInfo?.copyToAddress) {
//     result.push({
//       key: "Tenant info copy to address",
//       value: lease.tenantInfo.copyToAddress
//     });
//   }
//   if (lease.tenantInfo?.copyToAddressCity) {
//     result.push({
//       key: "Tenant info copy to address city",
//       value: lease.tenantInfo.copyToAddressCity
//     });
//   }
//   if (lease.tenantInfo?.copyToAddressState) {
//     result.push({
//       key: "Tenant info copy to address state",
//       value: lease.tenantInfo.copyToAddressState
//     });
//   }
//   if (lease.tenantInfo?.copyToAddressZip) {
//     result.push({
//       key: "Tenant info copy to address zip",
//       value: lease.tenantInfo.copyToAddressZip
//     });
//   }
//   if (lease.tenantInfo?.postLeasePhoneNumber) {
//     result.push({
//       key: "Tenant info post lease phone number",
//       value: lease.tenantInfo.postLeasePhoneNumber
//     });
//   }
//   if (lease.tenantInfo?.postLeaseEmail) {
//     result.push({
//       key: "Tenant info post lease email",
//       value: lease.tenantInfo.postLeaseEmail
//     });
//   }
//   if (lease.tenantInfo?.copyToAttention) {
//     result.push({
//       key: "Tenant info copy to attention",
//       value: lease.tenantInfo.copyToAttention
//     });
//   }
//   if (lease.tenantInfo?.copyToPhoneNumber) {
//     result.push({
//       key: "Tenant info copy to phone number",
//       value: lease.tenantInfo.copyToPhoneNumber
//     });
//   }
//   if (lease.tenantInfo?.copyToEmail) {
//     result.push({
//       key: "Tenant info copy to email",
//       value: lease.tenantInfo.copyToEmail
//     });
//   }
//   if (lease.tenantInfo?.brokerAddress) {
//     result.push({
//       key: "Tenant info broker address",
//       value: lease.tenantInfo.brokerAddress
//     });
//   }
//   if (lease.tenantInfo?.brokerCity) {
//     result.push({
//       key: "Tenant info broker city",
//       value: lease.tenantInfo.brokerCity
//     });
//   }
//   if (lease.tenantInfo?.brokerState) {
//     result.push({
//       key: "Tenant info broker state",
//       value: lease.tenantInfo.brokerState
//     });
//   }
//   if (lease.tenantInfo?.brokerZip) {
//     result.push({
//       key: "Tenant info broker zip",
//       value: lease.tenantInfo.brokerZip
//     });
//   }
//   if (lease.tenantInfo?.hasLandlordAgreesToPayTenantBroker) {
//     result.push({
//       key: "Tenant info has landlord agrees to pay tenant broker",
//       value: lease.tenantInfo.hasLandlordAgreesToPayTenantBroker
//     });
//   }
//   if (lease.tenantInfo?.individuals?.length > 0) {
//     lease.tenantInfo.individuals.forEach((record, index) => {
//       if (lease.tenantInfo.individuals[index]?.name) {
//         result.push({
//           key: `Tenant info individual #${index + 1} - name`,
//           value: lease.tenantInfo.individuals[index].name
//         });
//       }
//       if (lease.tenantInfo.individuals[index]?.SSN) {
//         result.push({
//           key: `Tenant info individual #${index + 1} - SSN`,
//           value: lease.tenantInfo.individuals[index].SSN
//         });
//       }
//     });
//   }
//   if (lease.tenantInfo?.copy_to?.length > 0) {
//     lease.tenantInfo.copy_to.forEach((record, index) => {
//       if (lease.tenantInfo.copy_to[index]?.begin) {
//         result.push({
//           key: `Tenant info copy to #${index + 1} - name`,
//           value: lease.tenantInfo.copy_to[index].name
//         });
//       }
//       if (lease.tenantInfo.copy_to[index]?.begin) {
//         result.push({
//           key: `Tenant info copy to #${index + 1} - address`,
//           value: lease.tenantInfo.copy_to[index].address
//         });
//       }
//       if (lease.tenantInfo.copy_to[index]?.begin) {
//         result.push({
//           key: `Tenant info copy to #${index + 1} - address city`,
//           value: lease.tenantInfo.copy_to[index].addressCity
//         });
//       }
//       if (lease.tenantInfo.copy_to[index]?.begin) {
//         result.push({
//           key: `Tenant info copy to #${index + 1} - address state`,
//           value: lease.tenantInfo.copy_to[index].addressState
//         });
//       }
//       if (lease.tenantInfo.copy_to[index]?.begin) {
//         result.push({
//           key: `Tenant info copy to #${index + 1} - address zip`,
//           value: lease.tenantInfo.copy_to[index].addressZip
//         });
//       }
//       if (lease.tenantInfo.copy_to[index]?.begin) {
//         result.push({
//           key: `Tenant info copy to #${index + 1} - attention`,
//           value: lease.tenantInfo.copy_to[index].attention
//         });
//       }
//       if (lease.tenantInfo.copy_to[index]?.begin) {
//         result.push({
//           key: `Tenant info copy to #${index + 1} - phone number`,
//           value: lease.tenantInfo.copy_to[index].phoneNumber
//         });
//       }
//       if (lease.tenantInfo.copy_to[index]?.begin) {
//         result.push({
//           key: `Tenant info copy to #${index + 1} - email`,
//           value: lease.tenantInfo.copy_to[index].email
//         });
//       }
//     });
//   }
//   if (lease.tenantLiabilityInsuranceAmount) {
//     result.push({
//       key: "Tenant liability insurance amount",
//       value: lease.tenantLiabilityInsuranceAmount
//     });
//   }
//   if (lease.tenantPermitContingency?.applicationPeriod) {
//     result.push({
//       key: "Tenant permit contingency application period",
//       value: lease.tenantPermitContingency.applicationPeriod
//     });
//   }
//   if (lease.tenantPermitContingency?.applicationDeadline) {
//     result.push({
//       key: "Tenant permit contingency application deadline",
//       value: lease.tenantPermitContingency.applicationDeadline
//     });
//   }
//   if (lease.tenantPermitContingency?.hasExtension) {
//     result.push({
//       key: "Tenant permit contingency has extension",
//       value: lease.tenantPermitContingency.hasExtension
//     });
//   }
//   if (lease.tenantPlanSubmissionDate) {
//     result.push({
//       key: "Tenant plan submission date",
//       value: lease.tenantPlanSubmissionDate
//     });
//   }
//   if (lease.tenantRep?.name) {
//     result.push({
//       key: "Tenant rep name",
//       value: lease.tenantRep.name
//     });
//   }
//   if (lease.tenantRep?.address) {
//     result.push({
//       key: "Tenant rep address",
//       value: lease.tenantRep.address
//     });
//   }
//   if (lease.tenantRep?.city) {
//     result.push({
//       key: "Tenant rep city",
//       value: lease.tenantRep.city
//     });
//   }
//   if (lease.tenantRep?.state) {
//     result.push({
//       key: "Tenant rep state",
//       value: lease.tenantRep.state
//     });
//   }
//   if (lease.tenantRep?.zipCode) {
//     result.push({
//       key: "Tenant rep zip code",
//       value: lease.tenantRep.zipCode
//     });
//   }
//   if (lease.tenantRep?.phoneNumber) {
//     result.push({
//       key: "Tenant rep phone number",
//       value: lease.tenantRep.phoneNumber
//     });
//   }
//   if (lease.tenantRepresentative) {
//     result.push({
//       key: "Tenant representative",
//       value: lease.tenantRepresentative
//     });
//   }
//   if (lease.tenantTerminationOption?.hasCapOnFee) {
//     result.push({
//       key: "Tenant termination option has cap on fee",
//       value: lease.tenantTerminationOption.hasCapOnFee
//     });
//   }
//   if (lease.tenantTerminationOption?.capOnFee) {
//     result.push({
//       key: "Tenant termination option cap on fee",
//       value: lease.tenantTerminationOption.capOnFee
//     });
//   }
//   if (lease.tenantTerminationOption?.hasTerminationAmount) {
//     result.push({
//       key: "Tenant termination option has termination amount",
//       value: lease.tenantTerminationOption.hasTerminationAmount
//     });
//   }
//   if (lease.tenantTerminationOption?.terminationAmount) {
//     result.push({
//       key: "Tenant termination option termination amount",
//       value: lease.tenantTerminationOption.terminationAmount
//     });
//   }
//   if (lease.tenantTerminationOptionFee) {
//     result.push({
//       key: "Tenant termination option fee",
//       value: lease.tenantTerminationOptionFee
//     });
//   }
//   if (lease.termCommencementDate) {
//     result.push({
//       key: "Term commencement date",
//       value: lease.termCommencementDate
//     });
//   }
//   if (lease.termNumber) {
//     result.push({
//       key: "Term number",
//       value: lease.termNumber
//     });
//   }
//   if (lease.tiAllowance?.method) {
//     result.push({
//       key: "Ti allowance method",
//       value: lease.tiAllowance.method
//     });
//   }
//   if (lease.tiAllowance?.amount) {
//     result.push({
//       key: "Ti allowance amount",
//       value: lease.tiAllowance.amount
//     });
//   }
//   if (lease.tiAllowance?.totalAmount) {
//     result.push({
//       key: "Ti allowance total amount",
//       value: lease.tiAllowance.totalAmount
//     });
//   }
//   if (lease.tiAllowance?.drawType) {
//     result.push({
//       key: "Ti allowance draw type",
//       value: lease.tiAllowance.drawType
//     });
//   }
//   if (lease.tiAllowance?.grossAmount) {
//     result.push({
//       key: "Ti allowance gross amount",
//       value: lease.tiAllowance.grossAmount
//     });
//   }
//   if (lease.tiAllowance?.hasRelocationPortion) {
//     result.push({
//       key: "Ti allowance has relocation portion",
//       value: lease.tiAllowance.hasRelocationPortion
//     });
//   }
//   if (lease.tiAllowance?.hasRentPortion) {
//     result.push({
//       key: "Ti allowance has rent portion",
//       value: lease.tiAllowance.hasRentPortion
//     });
//   }
//   if (lease.tiAllowance?.relocationPortion) {
//     result.push({
//       key: "Ti allowance relocation portion",
//       value: lease.tiAllowance.relocationPortion
//     });
//   }
//   if (lease.tiAllowance?.rentPortion) {
//     result.push({
//       key: "Ti allowance rent portion",
//       value: lease.tiAllowance.rentPortion
//     });
//   }
//   if (lease.tiAllowance?.amountUsedBy) {
//     result.push({
//       key: "Ti allowance amount used by",
//       value: lease.tiAllowance.amountUsedBy
//     });
//   }
//   if (lease.tiAllowance?.hasDropDeadDate) {
//     result.push({
//       key: "Ti allowance has drop dead date",
//       value: lease.tiAllowance.hasDropDeadDate
//     });
//   }
//   if (lease.tiAllowance?.dropDeadDate) {
//     result.push({
//       key: "Ti allowance drop dead date",
//       value: lease.tiAllowance.dropDeadDate
//     });
//   }
//   if (lease.tiAllowance?.hasEquipmentPortion) {
//     result.push({
//       key: "Ti allowance has equipment portion",
//       value: lease.tiAllowance.hasEquipmentPortion
//     });
//   }
//   if (lease.tiAllowance?.equipmentPortion) {
//     result.push({
//       key: "Ti allowance equipment portion",
//       value: lease.tiAllowance.equipmentPortion
//     });
//   }
//   if (lease.tiAllowance?.dropDeadDateSoft) {
//     result.push({
//       key: "Ti allowance drop dead date soft",
//       value: lease.tiAllowance.dropDeadDateSoft
//     });
//   }
//   if (lease.tiAllowance?.dropDeadDateType) {
//     result.push({
//       key: "Ti allowance drop dead date type",
//       value: lease.tiAllowance.dropDeadDateType
//     });
//   }
//   if (lease.tiAllowance?.hasTenantManagesConstruction) {
//     result.push({
//       key: "Ti allowance has tenant manages construction",
//       value: lease.tiAllowance.hasTenantManagesConstruction
//     });
//   }
//   if (lease.tiAllowance?.hasWaiverOfBonds) {
//     result.push({
//       key: "Ti allowance has waiver of bonds",
//       value: lease.tiAllowance.hasWaiverOfBonds
//     });
//   }
//   if (lease.tiAllowance?.hasSupplementalImprovementAllowance) {
//     result.push({
//       key: "Ti allowance has supplemental improvement allowance",
//       value: lease.tiAllowance.hasSupplementalImprovementAllowance
//     });
//   }
//   if (lease.tiAllowance?.supplementalImprovementAllowance) {
//     result.push({
//       key: "Ti allowance supplemental improvement allowance",
//       value: lease.tiAllowance.supplementalImprovementAllowance
//     });
//   }
//   if (lease.tiAllowance?.tiTotalAmount) {
//     result.push({
//       key: "Ti allowance ti total amount",
//       value: lease.tiAllowance.tiTotalAmount
//     });
//   }
//   if (lease.tiAllowance?.method) {
//     result.push({
//       key: "TI allowance method",
//       value: lease.tiAllowance.method
//     });
//   }
//   if (lease.tiAllowance?.amount) {
//     result.push({
//       key: "TI allowance amount",
//       value: lease.tiAllowance.amount
//     });
//   }
//   if (lease.tiAllowance?.totalAmount) {
//     result.push({
//       key: "TI allowance total amount",
//       value: lease.tiAllowance.totalAmount
//     });
//   }
//   if (lease.tiAllowance?.drawType) {
//     result.push({
//       key: "TI allowance draw type",
//       value: lease.tiAllowance.drawType
//     });
//   }
//   if (lease.tiAllowance?.grossAmount) {
//     result.push({
//       key: "TI allowance gross amount",
//       value: lease.tiAllowance.grossAmount
//     });
//   }
//   if (lease.tiAllowance?.hasRelocationPortion) {
//     result.push({
//       key: "TI allowance has relocation portion",
//       value: lease.tiAllowance.hasRelocationPortion
//     });
//   }
//   if (lease.tiAllowance?.hasRentPortion) {
//     result.push({
//       key: "TI allowance has rent portion",
//       value: lease.tiAllowance.hasRentPortion
//     });
//   }
//   if (lease.tiAllowance?.relocationPortion) {
//     result.push({
//       key: "TI allowance relocation portion",
//       value: lease.tiAllowance.relocationPortion
//     });
//   }
//   if (lease.tiAllowance?.rentPortion) {
//     result.push({
//       key: "TI allowance rent portion",
//       value: lease.tiAllowance.rentPortion
//     });
//   }
//   if (lease.tiAllowance?.amountUsedBy) {
//     result.push({
//       key: "TI allowance amount used by",
//       value: lease.tiAllowance.amountUsedBy
//     });
//   }
//   if (lease.tiAllowance?.hasDropDeadDate) {
//     result.push({
//       key: "TI allowance has drop dead date",
//       value: lease.tiAllowance.hasDropDeadDate
//     });
//   }
//   if (lease.tiAllowance?.dropDeadDate) {
//     result.push({
//       key: "TI allowance drop dead date",
//       value: lease.tiAllowance.dropDeadDate
//     });
//   }
//   if (lease.tiAllowance?.hasEquipmentPortion) {
//     result.push({
//       key: "TI allowance has equipment portion",
//       value: lease.tiAllowance.hasEquipmentPortion
//     });
//   }
//   if (lease.tiAllowance?.equipmentPortion) {
//     result.push({
//       key: "TI allowance equipment portion",
//       value: lease.tiAllowance.equipmentPortion
//     });
//   }
//   if (lease.tiAllowance?.dropDeadDateSoft) {
//     result.push({
//       key: "TI allowance drop dead date soft",
//       value: lease.tiAllowance.dropDeadDateSoft
//     });
//   }
//   if (lease.tiAllowance?.dropDeadDateType) {
//     result.push({
//       key: "TI allowance drop dead date type",
//       value: lease.tiAllowance.dropDeadDateType
//     });
//   }
//   if (lease.tiAllowance?.hasTenantManagesConstruction) {
//     result.push({
//       key: "TI allowance has tenant manages construction",
//       value: lease.tiAllowance.hasTenantManagesConstruction
//     });
//   }
//   if (lease.tiAllowance?.hasWaiverOfBonds) {
//     result.push({
//       key: "TI allowance has waiver of bonds",
//       value: lease.tiAllowance.hasWaiverOfBonds
//     });
//   }
//   if (lease.tiAllowance?.hasSupplementalImprovementAllowance) {
//     result.push({
//       key: "TI allowance has supplemental improvement allowance",
//       value: lease.tiAllowance.hasSupplementalImprovementAllowance
//     });
//   }
//   if (lease.tiAllowance?.supplementalImprovementAllowance) {
//     result.push({
//       key: "TI allowance supplemental improvement allowance",
//       value: lease.tiAllowance.supplementalImprovementAllowance
//     });
//   }
//   if (lease.tiAllowance?.tiTotalAmount) {
//     result.push({
//       key: "TI allowance ti total amount",
//       value: lease.tiAllowance.tiTotalAmount
//     });
//   }
//   if (lease.umbrellaLimit) {
//     result.push({
//       key: "Umbrella limit",
//       value: lease.umbrellaLimit
//     });
//   }
//   if (lease.unreservedParkingPermits) {
//     result.push({
//       key: "Unreserved parking permits",
//       value: lease.unreservedParkingPermits
//     });
//   }
//   if (lease.utilitiesCharge) {
//     result.push({
//       key: "Utilities charge",
//       value: lease.utilitiesCharge
//     });
//   }
//   if (lease.utilitiesFlatCharge) {
//     result.push({
//       key: "Utilities flat charge",
//       value: lease.utilitiesFlatCharge
//     });
//   }
//   if (lease.whenPlansCompleted) {
//     result.push({
//       key: "When plans completed",
//       value: lease.whenPlansCompleted
//     });
//   }
//   if (lease.whoCompletesPlans) {
//     result.push({
//       key: "Who completes plans",
//       value: lease.whoCompletesPlans
//     });
//   }
//   if (lease.accelerationFeeAmount) {
//     result.push({
//       key: "Acceleration fee amount",
//       value: lease.accelerationFeeAmount
//     });
//   }
//   if (lease.allowanceScheduleType) {
//     result.push({
//       key: "Allowance schedule type",
//       value: lease.allowanceScheduleType
//     });
//   }
//   if (lease.assignmentFee) {
//     result.push({
//       key: "Assignment fee",
//       value: lease.assignmentFee
//     });
//   }
//   if (lease.assignmentFeeCap) {
//     result.push({
//       key: "Assignment fee cap",
//       value: lease.assignmentFeeCap
//     });
//   }
//   if (lease.assignmentInfo?.hasConfidentialityRestrictionsForNotice) {
//     result.push({
//       key: "Assignment info has confidentiality restrictions for notice",
//       value: lease.assignmentInfo.hasConfidentialityRestrictionsForNotice
//     });
//   }
//   if (lease.assignmentInfo?.hasDeductExpenseFromTransferPremium) {
//     result.push({
//       key: "Assignment info has deduct expense from transfer premium",
//       value: lease.assignmentInfo.hasDeductExpenseFromTransferPremium
//     });
//   }
//   if (lease.buildingSecurityReimbursement) {
//     result.push({
//       key: "Building security reimbursement",
//       value: lease.buildingSecurityReimbursement
//     });
//   }
//   if (lease.camCap?.firstYearAmount) {
//     result.push({
//       key: "Cam cap first year amount",
//       value: lease.camCap.firstYearAmount
//     });
//   }
//   if (lease.camCap?.annualIncrease) {
//     result.push({
//       key: "Cam cap annual increase",
//       value: lease.camCap.annualIncrease
//     });
//   }
//   if (lease.camCap?.capOnCamType) {
//     result.push({
//       key: "Cam cap cap on cam type",
//       value: lease.camCap.capOnCamType
//     });
//   }
//   if (lease.capExCompromise?.conservationProgram) {
//     result.push({
//       key: "Cap ex compromise conservation program",
//       value: lease.capExCompromise.conservationProgram
//     });
//   }
//   if (lease.capExCompromise?.laws) {
//     result.push({
//       key: "Cap ex compromise laws",
//       value: lease.capExCompromise.laws
//     });
//   }
//   if (lease.capExCompromise?.amortization) {
//     result.push({
//       key: "Cap ex compromise amortization",
//       value: lease.capExCompromise.amortization
//     });
//   }
//   if (lease.capOnTenantImprovements) {
//     result.push({
//       key: "Cap on tenant improvements",
//       value: lease.capOnTenantImprovements
//     });
//   }
//   if (lease.commencementDateType) {
//     result.push({
//       key: "Commencement date type",
//       value: lease.commencementDateType
//     });
//   }
//   if (lease.consentContingencies?.name) {
//     result.push({
//       key: "Consent contingencies name",
//       value: lease.consentContingencies.name
//     });
//   }
//   if (lease.consentContingencies?.nickname) {
//     result.push({
//       key: "Consent contingencies nickname",
//       value: lease.consentContingencies.nickname
//     });
//   }
//   if (lease.contingencyConsentToPermittedUse?.type) {
//     result.push({
//       key: "contingency consent to permitted use type) ",
//       value: lease.contingencyConsentToPermittedUse.type
//     });
//   }
//   if (lease.contingencyConsentToPermittedUse?.period) {
//     result.push({
//       key: "contingency consent to permitted use period",
//       value: lease.contingencyConsentToPermittedUse.period
//     });
//   }
//   if (lease.contractionOption?.effectiveDate) {
//     result.push({
//       key: "Contraction option effective date",
//       value: lease.contractionOption.effectiveDate
//     });
//   }
//   if (lease.contractionOption?.noticeDate) {
//     result.push({
//       key: "Contraction option notice date",
//       value: lease.contractionOption.noticeDate
//     });
//   }
//   if (lease.contractionOption?.maximumSf) {
//     result.push({
//       key: "Contraction option maximum sf",
//       value: lease.contractionOption.maximumSf
//     });
//   }
//   if (lease.contractionOption?.fee) {
//     result.push({
//       key: "Contraction option fee",
//       value: lease.contractionOption.fee
//     });
//   }
//   if (lease.contractionOption?.hasContractionFee) {
//     result.push({
//       key: "Contraction option has contraction fee",
//       value: lease.contractionOption.hasContractionFee
//     });
//   }
//   if (lease.demolitionAllowance?.amount) {
//     result.push({
//       key: "Demolition allowance amount",
//       value: lease.demolitionAllowance.amount
//     });
//   }
//   if (lease.designationOfImprovementsAsBuildingStandard) {
//     result.push({
//       key: "Designation of improvements as building standard",
//       value: lease.designationOfImprovementsAsBuildingStandard
//     });
//   }
//   if (lease.electricalLoad) {
//     result.push({
//       key: "Electrical load",
//       value: lease.electricalLoad
//     });
//   }
//   if (lease.expansion?.commencementDateType) {
//     result.push({
//       key: "Expansion commencement date type",
//       value: lease.expansion.commencementDateType
//     });
//   }
//   if (lease.expansion?.hardCommencementDate) {
//     result.push({
//       key: "Expansion hard commencement date",
//       value: lease.expansion.hardCommencementDate
//     });
//   }
//   if (lease.expansion?.softCommencementDate) {
//     result.push({
//       key: "Expansion soft commencement date",
//       value: lease.expansion.softCommencementDate
//     });
//   }
//   if (lease.expansion?.extensionTermType) {
//     result.push({
//       key: "Expansion extension term type",
//       value: lease.expansion.extensionTermType
//     });
//   }
//   if (lease.expansion?.termYears) {
//     result.push({
//       key: "Expansion term years",
//       value: lease.expansion.termYears
//     });
//   }
//   if (lease.expansion?.termMonths) {
//     result.push({
//       key: "Expansion term months",
//       value: lease.expansion.termMonths
//     });
//   }
//   if (lease.expansion?.expirationDate) {
//     result.push({
//       key: "Expansion expiration date",
//       value: lease.expansion.expirationDate
//     });
//   }
//   if (lease.expansion?.hasFloatingCommencementDate) {
//     result.push({
//       key: "Expansion has floating commencement date",
//       value: lease.expansion.hasFloatingCommencementDate
//     });
//   }
//   if (lease.expansion?.rentBumps?.length > 0) {
//     lease.expansion.rentBumps.forEach((record, index) => {
//       if (lease.expansion.rentBumps[index]?.begin) {
//         result.push({
//           key: `Expansion rent bump #${index + 1} begin`,
//           value: lease.expansion.rentBumps[index].begin
//         });
//       }
//       if (lease.expansion.rentBumps[index]?.duration) {
//         result.push({
//           key: `Expansion rent bump #${index + 1} duration`,
//           value: lease.expansion.rentBumps[index].duration
//         });
//       }
//       if (lease.expansion.rentBumps[index]?.rentPerSqFoot) {
//         result.push({
//           key: `Expansion rent bump #${index + 1} rent per sq foot`,
//           value: lease.expansion.rentBumps[index].rentPerSqFoot
//         });
//       }
//     });
//   }
//   if (lease.expansionAgreementLeaseYear) {
//     result.push({
//       key: "Expansion agreement lease year",
//       value: lease.expansionAgreementLeaseYear
//     });
//   }
//   if (lease.expansionAgreementPeriod) {
//     result.push({
//       key: "Expansion agreement period",
//       value: lease.expansionAgreementPeriod
//     });
//   }
//   if (lease.expansionNotice?.rangePeriodEarliestLength) {
//     result.push({
//       key: "Expansion notice range period earliest length",
//       value: lease.expansionNotice.rangePeriodEarliestLength
//     });
//   }
//   if (lease.expansionNotice?.rangePeriodLongestLength) {
//     result.push({
//       key: "Expansion notice range period longest length",
//       value: lease.expansionNotice.rangePeriodLongestLength
//     });
//   }
//   if (lease.expansionNotice?.leaseYear) {
//     result.push({
//       key: "Expansion notice lease year",
//       value: lease.expansionNotice.leaseYear
//     });
//   }
//   if (lease.expansionNoticeWindowStartDate) {
//     result.push({
//       key: "Expansion notice window start date",
//       value: lease.expansionNoticeWindowStartDate
//     });
//   }
//   if (lease.expansionRentNoticeLeaseYear) {
//     result.push({
//       key: "Expansion rent notice lease year",
//       value: lease.expansionRentNoticeLeaseYear
//     });
//   }
//   if (lease.expansionRentNoticePeriod) {
//     result.push({
//       key: "Expansion rent notice period",
//       value: lease.expansionRentNoticePeriod
//     });
//   }
//   if (lease.extendingTermBumpsKeyedOf) {
//     result.push({
//       key: "Extending term bumps keyed of",
//       value: lease.extendingTermBumpsKeyedOf
//     });
//   }
//   if (lease.fixedCommencementDateType) {
//     result.push({
//       key: "Fixed commencement date type",
//       value: lease.fixedCommencementDateType
//     });
//   }
//   if (lease.fixturing?.hasLinkedToPermits) {
//     result.push({
//       key: "Fixturing has linked to permits",
//       value: lease.fixturing.hasLinkedToPermits
//     });
//   }
//   if (lease.fixturing?.hasBackendRentCommencementDate) {
//     result.push({
//       key: "Fixturing has backend rent commencement date",
//       value: lease.fixturing.hasBackendRentCommencementDate
//     });
//   }
//   if (lease.fixturing?.backendRentCommencementDateDaysAfterDelivery) {
//     result.push({
//       key: "Fixturing backend rent commencement date days after delivery",
//       value: lease.fixturing.backendRentCommencementDateDaysAfterDelivery
//     });
//   }
//   if (lease.fixturing?.backendRentCommencementDateHardDate) {
//     result.push({
//       key: "Fixturing backend rent commencement date hard date",
//       value: lease.fixturing.backendRentCommencementDateHardDate
//     });
//   }
//   if (lease.freeRentAbatedBaseRent) {
//     result.push({
//       key: "Free rent abated base rent",
//       value: lease.freeRentAbatedBaseRent
//     });
//   }
//   if (lease.freeRentBeginInMonth) {
//     result.push({
//       key: "Free rent begin in month",
//       value: lease.freeRentBeginInMonth
//     });
//   }
//   if (lease.generatorCharge) {
//     result.push({
//       key: "Generator charge",
//       value: lease.generatorCharge
//     });
//   }
//   if (lease.generatorChargeEscalation) {
//     result.push({
//       key: "Generator charge escalation",
//       value: lease.generatorChargeEscalation
//     });
//   }
//   if (lease.goDarkGrossSalesFloorAmount) {
//     result.push({
//       key: "Go dark gross sales floor amount",
//       value: lease.goDarkGrossSalesFloorAmount
//     });
//   }
//   if (lease.grossSalesReporting?.hasMonthlyReporting) {
//     result.push({
//       key: "Gross sales reporting has monthly reporting",
//       value: lease.grossSalesReporting.hasMonthlyReporting
//     });
//   }
//   if (lease.grossSalesReporting?.hasQuarterlyReporting) {
//     result.push({
//       key: "Gross sales reporting has quarterly reporting",
//       value: lease.grossSalesReporting.hasQuarterlyReporting
//     });
//   }
//   if (lease.grossSalesReporting?.hasAnnualReporting) {
//     result.push({
//       key: "Gross sales reporting has annual reporting",
//       value: lease.grossSalesReporting.hasAnnualReporting
//     });
//   }
//   if (lease.guarantyCapBurndownType) {
//     result.push({
//       key: "Guaranty cap burndown type",
//       value: lease.guarantyCapBurndownType
//     });
//   }
//   if (lease.hardOptionStartDate) {
//     result.push({
//       key: "Hard option start date",
//       value: lease.hardOptionStartDate
//     });
//   }
//   if (lease.hasAccelerationFee) {
//     result.push({
//       key: "Has acceleration fee",
//       value: lease.hasAccelerationFee
//     });
//   }
//   if (lease.hasAdaCompliance) {
//     result.push({
//       key: "Has ada compliance",
//       value: lease.hasAdaCompliance
//     });
//   }
//   if (lease.hasAddInsuranceToBaseYear) {
//     result.push({
//       key: "Has add insurance to base year",
//       value: lease.hasAddInsuranceToBaseYear
//     });
//   }
//   if (lease.hasAdditionalOptionsPursuantToLease) {
//     result.push({
//       key: "Has additional options pursuant to lease",
//       value: lease.hasAdditionalOptionsPursuantToLease
//     });
//   }
//   if (lease.hasAffiliates) {
//     result.push({
//       key: "Has affiliates",
//       value: lease.hasAffiliates
//     });
//   }
//   if (lease.hasApprovedSpacePlanComplete) {
//     result.push({
//       key: "Has approved space plan complete",
//       value: lease.hasApprovedSpacePlanComplete
//     });
//   }
//   if (lease.hasApprovedUsers) {
//     result.push({
//       key: "Has approved users",
//       value: lease.hasApprovedUsers
//     });
//   }
//   if (lease.hasAssignmentFee) {
//     result.push({
//       key: "Has assignment fee",
//       value: lease.hasAssignmentFee
//     });
//   }
//   if (lease.hasAssignmentFeeCap) {
//     result.push({
//       key: "Has assignment fee cap",
//       value: lease.hasAssignmentFeeCap
//     });
//   }
//   if (lease.hasAssignmentToAffiliate) {
//     result.push({
//       key: "Has assignment to affiliate",
//       value: lease.hasAssignmentToAffiliate
//     });
//   }
//   if (lease.hasAugmentedHazSub) {
//     result.push({
//       key: "Has augmented haz sub",
//       value: lease.hasAugmentedHazSub
//     });
//   }
//   if (lease.hasBalconyRights) {
//     result.push({
//       key: "Has balcony rights",
//       value: lease.hasBalconyRights
//     });
//   }
//   if (lease.hasBaseYearReviewRights) {
//     result.push({
//       key: "Has base year review rights",
//       value: lease.hasBaseYearReviewRights
//     });
//   }
//   if (lease.hasBlendAndExtend) {
//     result.push({
//       key: "Has blend and extend",
//       value: lease.hasBlendAndExtend
//     });
//   }
//   if (lease.hasCapExCompromise) {
//     result.push({
//       key: "Has cap ex compromise",
//       value: lease.hasCapExCompromise
//     });
//   }
//   if (lease.hasChangeBaseYear) {
//     result.push({
//       key: "Has change base year",
//       value: lease.hasChangeBaseYear
//     });
//   }
//   if (lease.hasChangeModifiedGrossTerms) {
//     result.push({
//       key: "Has change modified gross terms",
//       value: lease.hasChangeModifiedGrossTerms
//     });
//   }
//   if (lease.hasChangesAdditionalRentType) {
//     result.push({
//       key: "Has changes additional rent type",
//       value: lease.hasChangesAdditionalRentType
//     });
//   }
//   if (lease.hasCompensationForLossOfGoodWill) {
//     result.push({
//       key: "Has compensation for loss of good will",
//       value: lease.hasCompensationForLossOfGoodWill
//     });
//   }
//   if (lease.hasCondenserWater) {
//     result.push({
//       key: "Has condenser water",
//       value: lease.hasCondenserWater
//     });
//   }
//   if (lease.hasContingencyConsentToPermittedUse) {
//     result.push({
//       key: "Has contingency consent to permitted use",
//       value: lease.hasContingencyConsentToPermittedUse
//     });
//   }
//   if (lease.hasContractionOption) {
//     result.push({
//       key: "Has contraction option",
//       value: lease.hasContractionOption
//     });
//   }
//   if (lease.hasConvertUnreservedToReservedParking) {
//     result.push({
//       key: "Has convert unreserved to reserved parking",
//       value: lease.hasConvertUnreservedToReservedParking
//     });
//   }
//   if (lease.hasCustomAdvancedEdit) {
//     result.push({
//       key: "Has custom advanced edit",
//       value: lease.hasCustomAdvancedEdit
//     });
//   }
//   if (lease.hasCustomExhibit) {
//     result.push({
//       key: "Has custom exhibit",
//       value: lease.hasCustomExhibit
//     });
//   }
//   if (lease.hasDeliveryDeadline) {
//     result.push({
//       key: "Has delivery deadline",
//       value: lease.hasDeliveryDeadline
//     });
//   }
//   if (lease.hasDemolitionAllowance) {
//     result.push({
//       key: "Has demolition allowance",
//       value: lease.hasDemolitionAllowance
//     });
//   }
//   if (lease.hasEstimatedCommencementDate) {
//     result.push({
//       key: "Has estimated commencement date",
//       value: lease.hasEstimatedCommencementDate
//     });
//   }
//   if (lease.hasExtensionOfTerm) {
//     result.push({
//       key: "Has extension of term",
//       value: lease.hasExtensionOfTerm
//     });
//   }
//   if (lease.hasExteriorSignage) {
//     result.push({
//       key: "Has exterior signage",
//       value: lease.hasExteriorSignage
//     });
//   }
//   if (lease.hasFullTimeAccess) {
//     result.push({
//       key: "Has full time access",
//       value: lease.hasFullTimeAccess
//     });
//   }
//   if (lease.hasGeneratorCharge) {
//     result.push({
//       key: "Has generator charge",
//       value: lease.hasGeneratorCharge
//     });
//   }
//   if (lease.hasGeneratorRights) {
//     result.push({
//       key: "Has generator rights",
//       value: lease.hasGeneratorRights
//     });
//   }
//   if (lease.hasGracePeriodCompromise) {
//     result.push({
//       key: "Has grace period compromise",
//       value: lease.hasGracePeriodCompromise
//     });
//   }
//   if (lease.hasCenterImprovements) {
//     result.push({
//       key: "Has center improvements",
//       value: lease.hasCenterImprovements
//     });
//   }
//   if (lease.hasGreenLease) {
//     result.push({
//       key: "Has green lease",
//       value: lease.hasGreenLease
//     });
//   }
//   if (lease.hasGrossSalesReporting) {
//     result.push({
//       key: "Has gross sales reporting",
//       value: lease.hasGrossSalesReporting
//     });
//   }
//   if (lease.hasGrossUp) {
//     result.push({
//       key: "Has gross up",
//       value: lease.hasGrossUp
//     });
//   }
//   if (lease.hasImprovementsOnPlanDeemedBuildingStandard) {
//     result.push({
//       key: "Has improvements on plan deemed building standard",
//       value: lease.hasImprovementsOnPlanDeemedBuildingStandard
//     });
//   }
//   if (lease.hasInterimLetterOfCredit) {
//     result.push({
//       key: "Has interim letter of credit",
//       value: lease.hasInterimLetterOfCredit
//     });
//   }
//   if (lease.hasLandlordAreaRemeasurements) {
//     result.push({
//       key: "Has landlord area remeasurements",
//       value: lease.hasLandlordAreaRemeasurements
//     });
//   }
//   if (lease.hasLandlordAttorney) {
//     result.push({
//       key: "Has landlord attorney",
//       value: lease.hasLandlordAttorney
//     });
//   }
//   if (lease.hasLandlordConstructsDemisingWalls) {
//     result.push({
//       key: "Has landlord constructs demising walls",
//       value: lease.hasLandlordConstructsDemisingWalls
//     });
//   }
//   if (lease.hasLandlordDoesAcmWork) {
//     result.push({
//       key: "Has landlord does acm work",
//       value: lease.hasLandlordDoesAcmWork
//     });
//   }
//   if (lease.hasLandlordDoesConcurrentWork) {
//     result.push({
//       key: "Has landlord does concurrent work",
//       value: lease.hasLandlordDoesConcurrentWork
//     });
//   }
//   if (lease.hasLandlordDoesWorkAfterOccupancy) {
//     result.push({
//       key: "Has landlord does work after occupancy",
//       value: lease.hasLandlordDoesWorkAfterOccupancy
//     });
//   }
//   if (lease.hasLandlordExculpationCarveout) {
//     result.push({
//       key: "Has landlord exculpation carveout",
//       value: lease.hasLandlordExculpationCarveout
//     });
//   }
//   if (lease.hasLandlordHazMatRep) {
//     result.push({
//       key: "Has landlord haz mat rep",
//       value: lease.hasLandlordHazMatRep
//     });
//   }
//   if (lease.hasLandlordLateDeliveryPenalty) {
//     result.push({
//       key: "Has landlord late delivery penalty",
//       value: lease.hasLandlordLateDeliveryPenalty
//     });
//   }
//   if (lease.hasLandlordLienSubordinationExhibit) {
//     result.push({
//       key: "Has landlord lien subordination exhibit",
//       value: lease.hasLandlordLienSubordinationExhibit
//     });
//   }
//   if (lease.hasLandlordMaintenanceObligations) {
//     result.push({
//       key: "Has landlord maintenance obligations",
//       value: lease.hasLandlordMaintenanceObligations
//     });
//   }
//   if (lease.hasLandlordPaysForAudit) {
//     result.push({
//       key: "Has landlord pays for audit",
//       value: lease.hasLandlordPaysForAudit
//     });
//   }
//   if (lease.hasLandlordRelocationRight) {
//     result.push({
//       key: "Has landlord relocation right",
//       value: lease.hasLandlordRelocationRight
//     });
//   }
//   if (lease.hasLandlordTerminationRight) {
//     result.push({
//       key: "Has landlord termination right",
//       value: lease.hasLandlordTerminationRight
//     });
//   }
//   if (lease.hasLandlordToUseDeductibleToRestore) {
//     result.push({
//       key: "Has landlord to use deductible to restore",
//       value: lease.hasLandlordToUseDeductibleToRestore
//     });
//   }
//   if (lease.hasLandlordWorkPunchList) {
//     result.push({
//       key: "Has landlord work punch list",
//       value: lease.hasLandlordWorkPunchList
//     });
//   }
//   if (lease.hasLimitOnThreeStrikesDefault) {
//     result.push({
//       key: "Has limit on three strikes default",
//       value: lease.hasLimitOnThreeStrikesDefault
//     });
//   }
//   if (lease.hasLimitTenantFinancials) {
//     result.push({
//       key: "Has limit tenant financials",
//       value: lease.hasLimitTenantFinancials
//     });
//   }
//   if (lease.hasLimitationOnHoldoverDamages) {
//     result.push({
//       key: "Has limitation on holdover damages",
//       value: lease.hasLimitationOnHoldoverDamages
//     });
//   }
//   if (lease.hasLimitationOnRightToAlterParking) {
//     result.push({
//       key: "Has limitation on right to alter parking",
//       value: lease.hasLimitationOnRightToAlterParking
//     });
//   }
//   if (lease.hasMakeTenantRepsMutual) {
//     result.push({
//       key: "Has make tenant reps mutual",
//       value: lease.hasMakeTenantRepsMutual
//     });
//   }
//   if (lease.hasMonumentSign) {
//     result.push({
//       key: "Has monument sign",
//       value: lease.hasMonumentSign
//     });
//   }
//   if (lease.hasNoticePriorToEntry) {
//     result.push({
//       key: "Has notice prior to entry",
//       value: lease.hasNoticePriorToEntry
//     });
//   }
//   if (lease.hasOpExExclusions) {
//     result.push({
//       key: "Has op ex exclusions",
//       value: lease.hasOpExExclusions
//     });
//   }
//   if (lease.hasOutparcel) {
//     result.push({
//       key: "Has outparcel",
//       value: lease.hasOutparcel
//     });
//   }
//   if (lease.hasPatioPlan) {
//     result.push({
//       key: "Has patio plan",
//       value: lease.hasPatioPlan
//     });
//   }
//   if (lease.hasPatioTenant) {
//     result.push({
//       key: "Has patio tenant",
//       value: lease.hasPatioTenant
//     });
//   }
//   if (lease.hasPaymentSystem) {
//     result.push({
//       key: "Has payment system",
//       value: lease.hasPaymentSystem
//     });
//   }
//   if (lease.hasPermittedClosures) {
//     result.push({
//       key: "Has permitted closures",
//       value: lease.hasPermittedClosures
//     });
//   }
//   if (lease.hasPermittedFranchisee) {
//     result.push({
//       key: "Has permitted franchisee",
//       value: lease.hasPermittedFranchisee
//     });
//   }
//   if (lease.hasPermittedUseHazardousMaterials) {
//     result.push({
//       key: "Has permitted use hazardous materials",
//       value: lease.hasPermittedUseHazardousMaterials
//     });
//   }
//   if (lease.hasPermittedUseVendingMachinesForCustomer) {
//     result.push({
//       key: "Has permitted use vending machines for customer",
//       value: lease.hasPermittedUseVendingMachinesForCustomer
//     });
//   }
//   if (lease.hasPermittedUseVendingMachinesForNonCustomer) {
//     result.push({
//       key: "Has permitted use vending machines for non customer",
//       value: lease.hasPermittedUseVendingMachinesForNonCustomer
//     });
//   }
//   if (lease.hasPublicSecuritiesCarveout) {
//     result.push({
//       key: "Has public securities carveout",
//       value: lease.hasPublicSecuritiesCarveout
//     });
//   }
//   if (lease.hasReasonableAlterations) {
//     result.push({
//       key: "Has reasonable alterations",
//       value: lease.hasReasonableAlterations
//     });
//   }
//   if (lease.hasReductionFee) {
//     result.push({
//       key: "Has reduction fee",
//       value: lease.hasReductionFee
//     });
//   }
//   if (lease.hasRefusalSpacePlan) {
//     result.push({
//       key: "Has refusal space plan",
//       value: lease.hasRefusalSpacePlan
//     });
//   }
//   if (lease.hasRelocationOnWeekendsOnly) {
//     result.push({
//       key: "Has relocation on weekends only",
//       value: lease.hasRelocationOnWeekendsOnly
//     });
//   }
//   if (lease.hasRelocationProhibitedInInitialTerm) {
//     result.push({
//       key: "Has relocation prohibited in initial term",
//       value: lease.hasRelocationProhibitedInInitialTerm
//     });
//   }
//   if (lease.hasRemainingPortionOfPremisesUnusable) {
//     result.push({
//       key: "Has remaining portion of premises unusable",
//       value: lease.hasRemainingPortionOfPremisesUnusable
//     });
//   }
//   if (lease.hasRenewalRentCurrentRentFloor) {
//     result.push({
//       key: "Has renewal rent current rent floor",
//       value: lease.hasRenewalRentCurrentRentFloor
//     });
//   }
//   if (lease.hasRentAbatementForClosure) {
//     result.push({
//       key: "Has rent abatement for closure",
//       value: lease.hasRentAbatementForClosure
//     });
//   }
//   if (lease.hasRentDeferral) {
//     result.push({
//       key: "Has rent deferral",
//       value: lease.hasRentDeferral
//     });
//   }
//   if (lease.hasRentReduction) {
//     result.push({
//       key: "Has rent reduction",
//       value: lease.hasRentReduction
//     });
//   }
//   if (lease.hasRequiresDemisingWork) {
//     result.push({
//       key: "Has requires demising work",
//       value: lease.hasRequiresDemisingWork
//     });
//   }
//   if (lease.hasRestroomAllowance) {
//     result.push({
//       key: "Has restroom allowance",
//       value: lease.hasRestroomAllowance
//     });
//   }
//   if (lease.hasRightOfFirstRefusal) {
//     result.push({
//       key: "Has right of first refusal",
//       value: lease.hasRightOfFirstRefusal
//     });
//   }
//   if (lease.hasRmbsNotToExceed100Percent) {
//     result.push({
//       key: "Has RBMS not to exceed 100%",
//       value: lease.hasRmbsNotToExceed100Percent
//     });
//   }
//   if (lease.hasRofoContiguousSpaceLimitation) {
//     result.push({
//       key: "Has ROFO contiguous space limitation",
//       value: lease.hasRofoContiguousSpaceLimitation
//     });
//   }
//   if (lease.hasRofoOfferingSpacePlan) {
//     result.push({
//       key: "Has ROFO offering space plan",
//       value: lease.hasRofoOfferingSpacePlan
//     });
//   }
//   if (lease.hasRooftopRights) {
//     result.push({
//       key: "Has rooftop rights",
//       value: lease.hasRooftopRights
//     });
//   }
//   if (lease.hasSpacePlanningAllowance) {
//     result.push({
//       key: "Has space planning allowance",
//       value: lease.hasSpacePlanningAllowance
//     });
//   }
//   if (lease.hasSubleaseToOccupantPermitted) {
//     result.push({
//       key: "Has sublease to occupant permitted",
//       value: lease.hasSubleaseToOccupantPermitted
//     });
//   }
//   if (lease.hasSustainabilityPractices) {
//     result.push({
//       key: "Has sustainability practices",
//       value: lease.hasSustainabilityPractices
//     });
//   }
//   if (lease.hasTenancyAtSufferanceChange) {
//     result.push({
//       key: "Has tenancy at sufferance change",
//       value: lease.hasTenancyAtSufferanceChange
//     });
//   }
//   if (lease.hasTenantAccessToRisers) {
//     result.push({
//       key: "Has tenant access to risers",
//       value: lease.hasTenantAccessToRisers
//     });
//   }
//   if (lease.hasTenantAttorney) {
//     result.push({
//       key: "Has tenant attorney",
//       value: lease.hasTenantAttorney
//     });
//   }
//   if (lease.hasTenantBlanketInsurance) {
//     result.push({
//       key: "Has tenant blanket insurance",
//       value: lease.hasTenantBlanketInsurance
//     });
//   }
//   if (lease.hasTenantCAMContribution) {
//     result.push({
//       key: "Has tenant CAM contribution",
//       value: lease.hasTenantCAMContribution
//     });
//   }
//   if (lease.hasTenantCompetitors) {
//     result.push({
//       key: "Has tenant competitors",
//       value: lease.hasTenantCompetitors
//     });
//   }
//   if (lease.hasTenantCosmeticImprovements) {
//     result.push({
//       key: "Has tenant cosmetic improvements",
//       value: lease.hasTenantCosmeticImprovements
//     });
//   }
//   if (lease.hasTenantDeliveriesInFrontOfBuilding) {
//     result.push({
//       key: "Has tenant deliveries in front of building",
//       value: lease.hasTenantDeliveriesInFrontOfBuilding
//     });
//   }
//   if (lease.hasTenantDeliveriesInRearOfBuilding) {
//     result.push({
//       key: "Has tenant deliveries in rear of building",
//       value: lease.hasTenantDeliveriesInRearOfBuilding
//     });
//   }
//   if (lease.hasTenantMaintainsHVAC) {
//     result.push({
//       key: "Has tenant maintains HVAC",
//       value: lease.hasTenantMaintainsHVAC
//     });
//   }
//   if (lease.hasTenantNoticeAddress) {
//     result.push({
//       key: "Has tenant notice address",
//       value: lease.hasTenantNoticeAddress
//     });
//   }
//   if (lease.hasTenantOfacRep) {
//     result.push({
//       key: "Has tenant OFAC rep",
//       value: lease.hasTenantOfacRep
//     });
//   }
//   if (lease.hasTenantRemeasurement) {
//     result.push({
//       key: "Has tenant remeasurement",
//       value: lease.hasTenantRemeasurement
//     });
//   }
//   if (lease.hasTenantRep) {
//     result.push({
//       key: "Has tenant rep",
//       value: lease.hasTenantRep
//     });
//   }
//   if (lease.hasTenantRescissionRight) {
//     result.push({
//       key: "Has tenant rescission right",
//       value: lease.hasTenantRescissionRight
//     });
//   }
//   if (lease.hasTenantRightToReviewBids) {
//     result.push({
//       key: "Has tenant right to review bids",
//       value: lease.hasTenantRightToReviewBids
//     });
//   }
//   if (lease.hasTenantSelfInsurance) {
//     result.push({
//       key: "Has tenant self insurance",
//       value: lease.hasTenantSelfInsurance
//     });
//   }
//   if (lease.hasThirdPartyFranchisee) {
//     result.push({
//       key: "Has third party franchisee",
//       value: lease.hasThirdPartyFranchisee
//     });
//   }
//   if (lease.hasWindowSignage) {
//     result.push({
//       key: "Has window signage",
//       value: lease.hasWindowSignage
//     });
//   }
//   if (lease.insurance?.landlordTerminationForCasualty) {
//     result.push({
//       key: "Insurance landlord termination for casualty",
//       value: lease.insurance.landlordTerminationForCasualty
//     });
//   }
//   if (lease.insurance?.landlordTerminationForCondemnation) {
//     result.push({
//       key: "Insurance landlord termination for condemnation",
//       value: lease.insurance.landlordTerminationForCondemnation
//     });
//   }
//   if (lease.landlordArchitect) {
//     result.push({
//       key: "Landlord architect",
//       value: lease.landlordArchitect
//     });
//   }
//   if (lease.landlordAreaRemeasurements?.hasPremisesAreaRemeasurements) {
//     result.push({
//       key: "Landlord area remeasurements has premises area remeasurements",
//       value: lease.landlordAreaRemeasurements.hasPremisesAreaRemeasurements
//     });
//   }
//   if (lease.landlordAreaRemeasurements?.formerPremisesArea) {
//     result.push({
//       key: "Landlord area remeasurements former premises area",
//       value: lease.landlordAreaRemeasurements.formerPremisesArea
//     });
//   }
//   if (lease.landlordAreaRemeasurements?.hasBuildingAreaRemeasurements) {
//     result.push({
//       key: "Landlord area remeasurements has building area remeasurements",
//       value: lease.landlordAreaRemeasurements.hasBuildingAreaRemeasurements
//     });
//   }
//   if (lease.landlordAreaRemeasurements?.formerBuildingArea) {
//     result.push({
//       key: "Landlord area remeasurements former building area",
//       value: lease.landlordAreaRemeasurements.formerBuildingArea
//     });
//   }
//   if (lease.landlordAttorney?.name) {
//     result.push({
//       key: "Landlord attorney name",
//       value: lease.landlordAttorney.name
//     });
//   }
//   if (lease.landlordAttorney?.telephone) {
//     result.push({
//       key: "Landlord attorney telephone",
//       value: lease.landlordAttorney.telephone
//     });
//   }
//   if (lease.landlordAttorney?.email) {
//     result.push({
//       key: "Landlord attorney email",
//       value: lease.landlordAttorney.email
//     });
//   }
//   if (lease.landlordBrokerAddress) {
//     result.push({
//       key: "Landlord broker address",
//       value: lease.landlordBrokerAddress
//     });
//   }
//   if (lease.landlordBrokerCity) {
//     result.push({
//       key: "Landlord broker city",
//       value: lease.landlordBrokerCity
//     });
//   }
//   if (lease.landlordBrokerState) {
//     result.push({
//       key: "Landlord broker state",
//       value: lease.landlordBrokerState
//     });
//   }
//   if (lease.landlordBrokerZip) {
//     result.push({
//       key: "Landlord broker zip",
//       value: lease.landlordBrokerZip
//     });
//   }
//   if (lease.landlordComplianceWithLaws) {
//     result.push({
//       key: "Landlord compliance with laws",
//       value: lease.landlordComplianceWithLaws
//     });
//   }
//   if (lease.landlordConstructionCapPerSqFt) {
//     result.push({
//       key: "Landlord construction cap per sq ft",
//       value: lease.landlordConstructionCapPerSqFt
//     });
//   }
//   if (lease.landlordCoordinationFeePercentageAmount) {
//     result.push({
//       key: "Landlord coordination fee percentage amount",
//       value: lease.landlordCoordinationFeePercentageAmount
//     });
//   }
//   if (lease.landlordDelayAbatementTrigger) {
//     result.push({
//       key: "Landlord delay abatement trigger",
//       value: lease.landlordDelayAbatementTrigger
//     });
//   }
//   if (lease.landlordDelayAmount) {
//     result.push({
//       key: "Landlord delay amount",
//       value: lease.landlordDelayAmount
//     });
//   }
//   if (lease.landlordDelayOutsideDate) {
//     result.push({
//       key: "Landlord delay outside date",
//       value: lease.landlordDelayOutsideDate
//     });
//   }
//   if (lease.landlordDelayPeriodAfterCompletionDate) {
//     result.push({
//       key: "Landlord delay period after completion date",
//       value: lease.landlordDelayPeriodAfterCompletionDate
//     });
//   }
//   if (lease.landlordLateDeliveryPenalty?.outsideDate) {
//     result.push({
//       key: "Landlord late delivery penalty outside date",
//       value: lease.landlordLateDeliveryPenalty.outsideDate
//     });
//   }
//   if (lease.landlordLateDeliveryPenalty?.deliveryDate) {
//     result.push({
//       key: "Landlord late delivery penalty delivery date",
//       value: lease.landlordLateDeliveryPenalty.deliveryDate
//     });
//   }
//   if (lease.landlordLateDeliveryPenalty?.estimatedDeliveryDate) {
//     result.push({
//       key: "Landlord late delivery penalty estimated delivery date",
//       value: lease.landlordLateDeliveryPenalty.estimatedDeliveryDate
//     });
//   }
//   if (lease.landlordLateDeliveryPenalty?.hasTenantTerminationRight) {
//     result.push({
//       key: "Landlord late delivery penalty has tenant termination right",
//       value: lease.landlordLateDeliveryPenalty.hasTenantTerminationRight
//     });
//   }
//   if (lease.landlordLateDeliveryPenalty?.hasAbatement) {
//     result.push({
//       key: "Landlord late delivery penalty has abatement",
//       value: lease.landlordLateDeliveryPenalty.hasAbatement
//     });
//   }
//   if (lease.landlordLateDeliveryPenalty?.abatementPeriodAfterCompletion) {
//     result.push({
//       key: "Landlord late delivery penalty abatement period after completion",
//       value: lease.landlordLateDeliveryPenalty.abatementPeriodAfterCompletion
//     });
//   }
//   if (
//     lease.landlordLateDeliveryPenalty
//       ?.tenantTerminationRightPeriodAfterCompletion
//   ) {
//     result.push({
//       key:
//         "Landlord late delivery penalty tenant termination right period after completion",
//       value:
//         lease.landlordLateDeliveryPenalty
//           .tenantTerminationRightPeriodAfterCompletion
//     });
//   }
//   if (lease.landlordRelocationRightType) {
//     result.push({
//       key: "Landlord relocation right type",
//       value: lease.landlordRelocationRightType
//     });
//   }
//   if (lease.landlordWorkDeliveryCondition) {
//     result.push({
//       key: "Landlord work delivery condition",
//       value: lease.landlordWorkDeliveryCondition
//     });
//   }
//   if (lease.lenderInfo?.name) {
//     result.push({
//       key: "Lender info name",
//       value: lease.lenderInfo.name
//     });
//   }
//   if (lease.lenderInfo?.address) {
//     result.push({
//       key: "Lender info address",
//       value: lease.lenderInfo.address
//     });
//   }
//   if (lease.lenderInfo?.city) {
//     result.push({
//       key: "Lender info city",
//       value: lease.lenderInfo.city
//     });
//   }
//   if (lease.lenderInfo?.state) {
//     result.push({
//       key: "Lender info state",
//       value: lease.lenderInfo.state
//     });
//   }
//   if (lease.lenderInfo?.zip) {
//     result.push({
//       key: "Lender info zip",
//       value: lease.lenderInfo.zip
//     });
//   }
//   if (lease.minimumIncreaseCPI) {
//     result.push({
//       key: "Minimum increase cpi",
//       value: lease.minimumIncreaseCPI
//     });
//   }
//   if (lease.monthlyBaseAmountForExpenses) {
//     result.push({
//       key: "Monthly base amount for expenses",
//       value: lease.monthlyBaseAmountForExpenses
//     });
//   }
//   if (lease.monthlyBaseAmountForInsurance) {
//     result.push({
//       key: "Monthly base amount for insurance",
//       value: lease.monthlyBaseAmountForInsurance
//     });
//   }
//   if (lease.monthlyBaseAmountForTaxes) {
//     result.push({
//       key: "Monthly base amount for taxes",
//       value: lease.monthlyBaseAmountForTaxes
//     });
//   }
//   if (lease.opExExclusions?.hasManagementFeeExclusion) {
//     result.push({
//       key: "Op ex exclusions has management fee exclusion",
//       value: lease.opExExclusions.hasManagementFeeExclusion
//     });
//   }
//   if (lease.opExExclusions?.hasTaxExpenseExclusion) {
//     result.push({
//       key: "Op ex exclusions has tax expense exclusion",
//       value: lease.opExExclusions.hasTaxExpenseExclusion
//     });
//   }
//   if (lease.opExExclusions?.hasHighInsuranceDeductibleExclusion) {
//     result.push({
//       key: "Op ex exclusions has high insurance deductible exclusion",
//       value: lease.opExExclusions.hasHighInsuranceDeductibleExclusion
//     });
//   }
//   if (lease.opExExclusions?.hasEquipmentExcludedAsCapEx) {
//     result.push({
//       key: "Op ex exclusions has equipment excluded as cap ex",
//       value: lease.opExExclusions.hasEquipmentExcludedAsCapEx
//     });
//   }
//   if (lease.opExExclusions?.hasCapExForNewLawsOnly) {
//     result.push({
//       key: "Op ex exclusions has cap ex for new laws only",
//       value: lease.opExExclusions.hasCapExForNewLawsOnly
//     });
//   }
//   if (lease.opExExclusions?.hasTenantReceivesBenefitOfGreenBuildingTaxCredit) {
//     result.push({
//       key:
//         "Op ex exclusions has tenant receives benefit of green building tax credit",
//       value:
//         lease.opExExclusions.hasTenantReceivesBenefitOfGreenBuildingTaxCredit
//     });
//   }
//   if (lease.originalLeaseName) {
//     result.push({
//       key: "Original lease name",
//       value: lease.originalLeaseName
//     });
//   }
//   if (lease.permittedUseFoodCourt) {
//     result.push({
//       key: "Has permitted use food court",
//       value: lease.permittedUseFoodCourt
//     });
//   }
//   if (lease.permittedUseParkingLotBin) {
//     result.push({
//       key: "Has permitted use parking lot bin",
//       value: lease.permittedUseParkingLotBin
//     });
//   }
//   if (lease.permittedUsePetStore) {
//     result.push({
//       key: "Has permitted use pet store",
//       value: lease.permittedUsePetStore
//     });
//   }
//   if (lease.promotionFundCharge) {
//     result.push({
//       key: "Promotion fund charge",
//       value: lease.promotionFundCharge
//     });
//   }
//   if (lease.reduction?.hardCommencementDate) {
//     result.push({
//       key: "Reduction hard commencement date",
//       value: lease.reduction.hardCommencementDate
//     });
//   }
//   if (lease.reduction?.extensionTermType) {
//     result.push({
//       key: "Reduction extension term type",
//       value: lease.reduction.extensionTermType
//     });
//   }
//   if (lease.reduction?.termMonths) {
//     result.push({
//       key: "Reduction term months",
//       value: lease.reduction.termMonths
//     });
//   }
//   if (lease.reduction?.expirationDate) {
//     result.push({
//       key: "Reduction expiration date",
//       value: lease.reduction.expirationDate
//     });
//   }
//   if (lease.reduction?.rentBumps?.length > 0) {
//     lease.reduction.rentBumps.forEach((record, index) => {
//       if (lease.reduction.rentBumps[index]?.begin) {
//         result.push({
//           key: `Reduction rent bump #${index + 1} begin`,
//           value: lease.reduction.rentBumps[index].begin
//         });
//       }
//       if (lease.reduction.rentBumps[index]?.duration) {
//         result.push({
//           key: `Reduction rent bump #${index + 1} duration`,
//           value: lease.reduction.rentBumps[index].duration
//         });
//       }
//       if (lease.reduction.rentBumps[index]?.rentPerSqFoot) {
//         result.push({
//           key: `Reduction rent bump #${index + 1} rent per sq foot`,
//           value: lease.reduction.rentBumps[index].rentPerSqFoot
//         });
//       }
//     });
//   }
//   if (lease.reductionFeeAmount) {
//     result.push({
//       key: "Reduction fee amount",
//       value: lease.reductionFeeAmount
//     });
//   }
//   if (lease.reductionPremisesFloor) {
//     result.push({
//       key: "Reduction premises floor",
//       value: lease.reductionPremisesFloor
//     });
//   }
//   if (lease.reductionPremisesSpaceNumber) {
//     result.push({
//       key: "Reduction premises space number",
//       value: lease.reductionPremisesSpaceNumber
//     });
//   }
//   if (lease.relocation?.commencementDateType) {
//     result.push({
//       key: "Relocation commencement date type",
//       value: lease.relocation.commencementDateType
//     });
//   }
//   if (lease.relocation?.hardCommencementDate) {
//     result.push({
//       key: "Relocation hard commencement date",
//       value: lease.relocation.hardCommencementDate
//     });
//   }
//   if (lease.relocation?.softCommencementDate) {
//     result.push({
//       key: "Relocation soft commencement date",
//       value: lease.relocation.softCommencementDate
//     });
//   }
//   if (lease.relocation?.extensionTermType) {
//     result.push({
//       key: "Relocation extension term type",
//       value: lease.relocation.extensionTermType
//     });
//   }
//   if (lease.relocation?.termYears) {
//     result.push({
//       key: "Relocation term years",
//       value: lease.relocation.termYears
//     });
//   }
//   if (lease.relocation?.termMonths) {
//     result.push({
//       key: "Relocation term months",
//       value: lease.relocation.termMonths
//     });
//   }
//   if (lease.relocation?.expirationDate) {
//     result.push({
//       key: "Relocation expiration date",
//       value: lease.relocation.expirationDate
//     });
//   }
//   if (lease.relocation?.hasFloatingCommencementDate) {
//     result.push({
//       key: "Relocation has floating commencement date",
//       value: lease.relocation.hasFloatingCommencementDate
//     });
//   }
//   if (lease.relocation?.rentBumps?.length > 0) {
//     lease.relocation.rentBumps.forEach((record, index) => {
//       if (lease.relocation.rentBumps[index]?.begin) {
//         result.push({
//           key: `Relocation rent bump #${index + 1} begin`,
//           value: lease.relocation.rentBumps[index].begin
//         });
//       }
//       if (lease.relocation.rentBumps[index]?.duration) {
//         result.push({
//           key: `Relocation rent bump #${index + 1} duration`,
//           value: lease.relocation.rentBumps[index].duration
//         });
//       }
//       if (lease.relocation.rentBumps[index]?.rentPerSqFoot) {
//         result.push({
//           key: `Relocation rent bump #${index + 1} rent per sq foot`,
//           value: lease.relocation.rentBumps[index].rentPerSqFoot
//         });
//       }
//     });
//   }
//   if (lease.renewalOptionRentPercentage) {
//     result.push({
//       key: "Renewal option rent percentage",
//       value: lease.renewalOptionRentPercentage
//     });
//   }
//   if (lease.renewalRentBumps?.length > 0) {
//     lease.renewalRentBumps.forEach((renewalRentBump, renewalRentBumpIndex) => {
//       if (lease.renewalRentBumps[renewalRentBumpIndex].periods) {
//         lease.renewalRentBumps[renewalRentBumpIndex].periods.forEach(
//           (renewalRentBumpPeriod, renewalRentBumpPeriodIndex) => {
//             if (
//               lease.renewalRentBumps[renewalRentBumpIndex].periods[
//                 renewalRentBumpPeriodIndex
//               ]?.begin
//             ) {
//               result.push({
//                 key: `Renewal #${renewalRentBumpIndex} rent bump #${renewalRentBumpIndex +
//                   1} begin`,
//                 value:
//                   lease.renewalRentBumps[renewalRentBumpIndex].periods[
//                     renewalRentBumpPeriodIndex
//                   ].begin
//               });
//             }
//             if (
//               lease.renewalRentBumps[renewalRentBumpIndex].periods[
//                 renewalRentBumpPeriodIndex
//               ]?.duration
//             ) {
//               result.push({
//                 key: `Renewal #${renewalRentBumpIndex} rent bump #${renewalRentBumpIndex +
//                   1} duration`,
//                 value:
//                   lease.renewalRentBumps[renewalRentBumpIndex].periods[
//                     renewalRentBumpPeriodIndex
//                   ].duration
//               });
//             }
//             if (
//               lease.renewalRentBumps[renewalRentBumpIndex].periods[
//                 renewalRentBumpPeriodIndex
//               ]?.rentPerSqFoot
//             ) {
//               result.push({
//                 key: `Renewal #${renewalRentBumpIndex} rent bump rent per sq #${renewalRentBumpIndex +
//                   1} foot`,
//                 value:
//                   lease.renewalRentBumps[renewalRentBumpIndex].periods[
//                     renewalRentBumpPeriodIndex
//                   ].rentPerSqFoot
//               });
//             }
//           }
//         );
//       }
//     });
//   }
//   if (lease.rentAbatementForClosure) {
//     result.push({
//       key: "Rent abatement for closure",
//       value: lease.rentAbatementForClosure
//     });
//   }
//   if (lease.rentBumps?.length > 0) {
//     lease.rentBumps.forEach((record, index) => {
//       if (lease.rentBumps[index]?.begin) {
//         result.push({
//           key: `Rent bump #${index + 1} begin`,
//           value: lease.rentBumps[index].begin
//         });
//       }
//       if (lease.rentBumps[index]?.duration) {
//         result.push({
//           key: `Rent bump #${index + 1} duration`,
//           value: lease.rentBumps[index].duration
//         });
//       }
//       if (lease.rentBumps[index]?.rentPerSqFoot) {
//         result.push({
//           key: `Rent bump #${index + 1} rent per sq foot`,
//           value: lease.rentBumps[index].rentPerSqFoot
//         });
//       }
//     });
//   }
//   if (lease.rentDeferral?.deferralStartDate) {
//     result.push({
//       key: "Rent deferral deferral start date",
//       value: lease.rentDeferral.deferralStartDate
//     });
//   }
//   if (lease.rentDeferral?.deferralEndDate) {
//     result.push({
//       key: "Rent deferral deferral end date",
//       value: lease.rentDeferral.deferralEndDate
//     });
//   }
//   if (lease.rentDeferral?.rentReducedBy) {
//     result.push({
//       key: "Rent deferral rent reduced by",
//       value: lease.rentDeferral.rentReducedBy
//     });
//   }
//   if (lease.rentDeferral?.deferredRentDollarAmount) {
//     result.push({
//       key: "Rent deferral deferred rent dollar amount",
//       value: lease.rentDeferral.deferredRentDollarAmount
//     });
//   }
//   if (lease.rentDeferral?.rentDeferralAppliesTo) {
//     result.push({
//       key: "Rent deferral rent deferral applies to",
//       value: lease.rentDeferral.rentDeferralAppliesTo
//     });
//   }
//   if (lease.rentDeferral?.rentReductionType) {
//     result.push({
//       key: "Rent deferral rent reduction type",
//       value: lease.rentDeferral.rentReductionType
//     });
//   }
//   if (lease.rentDeferral?.reducedRentDollarAmount) {
//     result.push({
//       key: "Rent deferral reduced rent dollar amount",
//       value: lease.rentDeferral.reducedRentDollarAmount
//     });
//   }
//   if (lease.rentDeferral?.reducedRentPercentOfSalesAmount) {
//     result.push({
//       key: "Rent deferral reduced rent percent of sales amount",
//       value: lease.rentDeferral.reducedRentPercentOfSalesAmount
//     });
//   }
//   if (lease.rentDeferral?.repaymentStartDate) {
//     result.push({
//       key: "Rent deferral repayment start date",
//       value: lease.rentDeferral.repaymentStartDate
//     });
//   }
//   if (lease.rentDeferral?.repaymentPeriods) {
//     result.push({
//       key: "Rent deferral repayment periods",
//       value: lease.rentDeferral.repaymentPeriods
//     });
//   }
//   if (lease.rentReduction?.startDate) {
//     result.push({
//       key: "Rent reduction start date",
//       value: lease.rentReduction.startDate
//     });
//   }
//   if (lease.rentReduction?.endDate) {
//     result.push({
//       key: "Rent reduction end date",
//       value: lease.rentReduction.endDate
//     });
//   }
//   if (lease.rentReduction?.rentReducedBy) {
//     result.push({
//       key: "Rent reduction rent reduced by",
//       value: lease.rentReduction.rentReducedBy
//     });
//   }
//   if (lease.rentReduction?.reducedRentDollarAmount) {
//     result.push({
//       key: "Rent reduction reduced rent dollar amount",
//       value: lease.rentReduction.reducedRentDollarAmount
//     });
//   }
//   if (lease.rentReduction?.rentReductionAppliesTo) {
//     result.push({
//       key: "Rent reduction rent reduction applies to",
//       value: lease.rentReduction.rentReductionAppliesTo
//     });
//   }
//   if (lease.rentReduction?.rentReductionType) {
//     result.push({
//       key: "Rent reduction rent reduction type",
//       value: lease.rentReduction.rentReductionType
//     });
//   }
//   if (lease.rentReduction?.reducedRentPercentOfSalesAmount) {
//     result.push({
//       key: "Rent reduction reduced rent percent of sales amount",
//       value: lease.rentReduction.reducedRentPercentOfSalesAmount
//     });
//   }
//   if (lease.rentTableType) {
//     result.push({
//       key: "Rent table type",
//       value: lease.rentTableType
//     });
//   }
//   if (lease.reservedParkingPermitsCharge) {
//     result.push({
//       key: "Reserved parking permits charge",
//       value: lease.reservedParkingPermitsCharge
//     });
//   }
//   if (lease.restroomAllowance?.amount) {
//     result.push({
//       key: "Restroom allowance amount",
//       value: lease.restroomAllowance.amount
//     });
//   }
//   if (lease.rightOfFirstRefusal?.area) {
//     result.push({
//       key: "Right of first refusal area",
//       value: lease.rightOfFirstRefusal.area
//     });
//   }
//   if (lease.rightOfFirstRefusal?.suite) {
//     result.push({
//       key: "Right of first refusal suite",
//       value: lease.rightOfFirstRefusal.suite
//     });
//   }
//   if (lease.rightOfFirstRefusal?.floor) {
//     result.push({
//       key: "Right of first refusal floor",
//       value: lease.rightOfFirstRefusal.floor
//     });
//   }
//   if (lease.rightOfFirstRefusal?.terminationDate) {
//     result.push({
//       key: "Right of first refusal termination date",
//       value: lease.rightOfFirstRefusal.terminationDate
//     });
//   }
//   if (lease.rofoPremisesFloor) {
//     result.push({
//       key: "ROFO premises floor",
//       value: lease.rofoPremisesFloor
//     });
//   }
//   if (lease.rofoRightType) {
//     result.push({
//       key: "ROFO right type",
//       value: lease.rofoRightType
//     });
//   }
//   if (lease.scaledHoldover?.firstPeriodDays) {
//     result.push({
//       key: "Scaled holdover first period days",
//       value: lease.scaledHoldover.firstPeriodDays
//     });
//   }
//   if (lease.scaledHoldover?.firstPeriodPercentage) {
//     result.push({
//       key: "Scaled holdover first period percentage",
//       value: lease.scaledHoldover.firstPeriodPercentage
//     });
//   }
//   if (lease.scaledHoldover?.remainingPeriodPercentage) {
//     result.push({
//       key: "Scaled holdover remaining period percentage",
//       value: lease.scaledHoldover.remainingPeriodPercentage
//     });
//   }
//   if (lease.scaledHoldover?.cashBurndownPeriods?.length > 0) {
//     lease.scaledHoldover.cashBurndownPeriods.forEach((record, index) => {
//       if (lease.scaledHoldover.cashBurndownPeriods[index]?.year) {
//         result.push({
//           key: `Scaled holdover cash burndown period #${index + 1} - year`,
//           value: lease.scaledHoldover.cashBurndownPeriods[index].year
//         });
//       }
//       if (lease.scaledHoldover.cashBurndownPeriods[index]?.date) {
//         result.push({
//           key: `Scaled holdover cash burndown period #${index + 1} - date`,
//           value: lease.scaledHoldover.cashBurndownPeriods[index].date
//         });
//       }
//       if (lease.scaledHoldover.cashBurndownPeriods[index]?.amountReduced) {
//         result.push({
//           key: `Scaled holdover cash burndown period #${index +
//             1} - amount reduced`,
//           value: lease.scaledHoldover.cashBurndownPeriods[index].amountReduced
//         });
//       }
//     });
//   }
//   if (lease.scaledHoldover?.locBurndownPeriods?.length > 0) {
//     lease.scaledHoldover.locBurndownPeriods.forEach((record, index) => {
//       if (lease.scaledHoldover.locBurndownPeriods[index]?.year) {
//         result.push({
//           key: `Scaled holdover loc burndown period #${index + 1} - year`,
//           value: lease.scaledHoldover.locBurndownPeriods[index].year
//         });
//       }
//       if (lease.scaledHoldover.locBurndownPeriods[index]?.date) {
//         result.push({
//           key: `Scaled holdover loc burndown period #${index + 1} - date`,
//           value: lease.scaledHoldover.locBurndownPeriods[index].date
//         });
//       }
//       if (lease.scaledHoldover.locBurndownPeriods[index]?.amountReduced) {
//         result.push({
//           key: `Scaled holdover loc burndown period #${index +
//             1} - amount reduced`,
//           value: lease.scaledHoldover.locBurndownPeriods[index].amountReduced
//         });
//       }
//     });
//   }
//   if (lease.security?.type) {
//     result.push({
//       key: "Security type",
//       value: lease.security.type
//     });
//   }
//   if (lease.security?.cashAmount) {
//     result.push({
//       key: "Security cash amount",
//       value: lease.security.cashAmount
//     });
//   }
//   if (lease.security?.locAmount) {
//     result.push({
//       key: "Security loc amount",
//       value: lease.security.locAmount
//     });
//   }
//   if (lease.security?.hasCashBurndown) {
//     result.push({
//       key: "Security has cash burndown",
//       value: lease.security.hasCashBurndown
//     });
//   }
//   if (lease.security?.cashBurndownPeriodsNumber) {
//     result.push({
//       key: "Security cash burndown periods number",
//       value: lease.security.cashBurndownPeriodsNumber
//     });
//   }
//   if (lease.security?.hasLocBurndown) {
//     result.push({
//       key: "Security has loc burndown",
//       value: lease.security.hasLocBurndown
//     });
//   }
//   if (lease.security?.locBurndownPeriodsNumber) {
//     result.push({
//       key: "Security loc burndown periods number",
//       value: lease.security.locBurndownPeriodsNumber
//     });
//   }
//   if (lease.securityDepositUpdate?.existingAmount) {
//     result.push({
//       key: "Security deposit update existing amount",
//       value: lease.securityDepositUpdate.existingAmount
//     });
//   }
//   if (lease.securityDepositUpdate?.amendmentAmount) {
//     result.push({
//       key: "Security deposit update amendment amount",
//       value: lease.securityDepositUpdate.amendmentAmount
//     });
//   }
//   if (lease.securityDepositUpdate?.hasReplaceLCtoCash) {
//     result.push({
//       key: "Security deposit update has replace lcto cash",
//       value: lease.securityDepositUpdate.hasReplaceLCtoCash
//     });
//   }
//   if (lease.securityDepositUpdate?.lcToCashAmount) {
//     result.push({
//       key: "Security deposit update lc to cash amount",
//       value: lease.securityDepositUpdate.lcToCashAmount
//     });
//   }
//   if (lease.securityDepositUpdate?.hasRenewLC) {
//     result.push({
//       key: "Security deposit update has renew lc",
//       value: lease.securityDepositUpdate.hasRenewLC
//     });
//   }
//   if (lease.securityDepositUpdate?.renewLCAmount) {
//     result.push({
//       key: "Security deposit update renew lcamount",
//       value: lease.securityDepositUpdate.renewLCAmount
//     });
//   }
//   if (lease.snowIceRemovalReimbursement) {
//     result.push({
//       key: "Snow ice removal reimbursement",
//       value: lease.snowIceRemovalReimbursement
//     });
//   }
//   if (lease.spacePlanningAllowance?.amount) {
//     result.push({
//       key: "Space panning allowance amount",
//       value: lease.spacePlanningAllowance.amount
//     });
//   }
//   if (lease.sublet) {
//     result.push({
//       key: "Sublet",
//       value: lease.sublet
//     });
//   }
//   if (lease.tenantAttorney?.name) {
//     result.push({
//       key: "Tenant attorney name",
//       value: lease.tenantAttorney.name
//     });
//   }
//   if (lease.tenantAttorney?.telephone) {
//     result.push({
//       key: "Tenant attorney telephone",
//       value: lease.tenantAttorney.telephone
//     });
//   }
//   if (lease.tenantAttorney?.email) {
//     result.push({
//       key: "Tenant attorney email",
//       value: lease.tenantAttorney.email
//     });
//   }
//   if (lease.tenantCompetitors?.hasAffiliates) {
//     result.push({
//       key: "Tenant competitors has affiliates",
//       value: lease.tenantCompetitors.hasAffiliates
//     });
//   }
//   if (lease.tenantCompetitors?.requiredOccupancy) {
//     result.push({
//       key: "Tenant competitors required occupancy",
//       value: lease.tenantCompetitors.requiredOccupancy
//     });
//   }
//   if (lease.tenantCompetitors?.minAmount) {
//     result.push({
//       key: "Tenant competitors min amount",
//       value: lease.tenantCompetitors.minAmount
//     });
//   }
//   if (lease.tenantSelfInsuranceType) {
//     result.push({
//       key: "Tenant self insurance type",
//       value: lease.tenantSelfInsuranceType
//     });
//   }
//   if (lease.tenantTerminationOptionsSoftFeeInterestRate) {
//     result.push({
//       key: "Tenant termination options soft fee interest rate",
//       value: lease.tenantTerminationOptionsSoftFeeInterestRate
//     });
//   }
//   if (lease.tenantTerminationType) {
//     result.push({
//       key: "Tenant termination type",
//       value: lease.tenantTerminationType
//     });
//   }
//   if (lease.terminationDate) {
//     result.push({
//       key: "Termination Date",
//       value: lease.terminationDate
//     });
//   }
//   if (lease.terminationDateType) {
//     result.push({
//       key: "Termination date type",
//       value: lease.terminationDateType
//     });
//   }
//   if (lease.terminationFeeType) {
//     result.push({
//       key: "Termination fee type",
//       value: lease.terminationFeeType
//     });
//   }
//   if (lease.terminationNotice) {
//     result.push({
//       key: "Termination notice",
//       value: lease.terminationNotice
//     });
//   }
//   if (lease.uncontrollableExpenseEstimate) {
//     result.push({
//       key: "Uncontrollable expense estimate",
//       value: lease.uncontrollableExpenseEstimate
//     });
//   }
//   if (lease.unreservedParkingPermitsCharge) {
//     result.push({
//       key: "Unreserved parking permits charge",
//       value: lease.unreservedParkingPermitsCharge
//     });
//   }
//   if (lease.buildingInfo?.city) {
//     result.push({
//       key: "Building City",
//       value: lease.buildingInfo.city
//     });
//   }
//   if (lease.buildingInfo?.state) {
//     result.push({
//       key: "Building State",
//       value: lease.buildingInfo.state
//     });
//   }
//   if (lease.buildingInfo?.county) {
//     result.push({
//       key: "Building County",
//       value: lease.buildingInfo.county
//     });
//   }
//   if (lease.buildingInfo?.zipCode) {
//     result.push({
//       key: "Building Zip Code",
//       value: lease.buildingInfo.zipCode
//     });
//   }
//   if (lease.buildingInfo?.displayName) {
//     result.push({
//       key: "Building Name",
//       value: lease.buildingInfo.displayName
//     });
//   }
//   if (lease.buildingInfo?.landlordName) {
//     result.push({
//       key: "Building Landlord Name",
//       value: lease.buildingInfo.landlordName
//     });
//   }
//   if (lease.buildingInfo?.streetAddress) {
//     result.push({
//       key: "Building Street Address",
//       value: lease.buildingInfo.streetAddress
//     });
//   }
//   if (lease.buildingInfo?.landlordEntityType) {
//     result.push({
//       key: "Building Landlord Entity Type",
//       value: lease.buildingInfo.landlordEntityType
//     });
//   }
//   if (lease.buildingInfo?.landlordStateOfFormation) {
//     result.push({
//       key: "Building Landlord State Of Formation",
//       value: lease.buildingInfo.landlordStateOfFormation
//     });
//   }



//   if (lease.event?.name) {
//     result.push({
//       key: "Event name",
//       value: lease.event.name,
//     });
//   }
//   if (lease.event?.times?.length > 0) {
//     lease.event.times.forEach((record, index) => {
//       if (lease.event?.times[index]?.date) {
//         result.push({
//           key: `Event time #${index + 1} date`,
//           value: lease.event.times[index].date,
//         });
//       }
//       if (lease.event?.times[index]?.startTime?.hour) {
//         result.push({
//           key: `Event time #${index + 1} - start time - hour`,
//           value: lease.event.times[index].startTime.hour,
//         });
//       }
//       if (lease.event?.times[index]?.startTime?.minute) {
//         result.push({
//           key: `Event time #${index + 1} - start time - minute`,
//           value: lease.event.times[index].startTime.minute,
//         });
//       }
//       if (lease.event?.times[index]?.startTime?.meridian) {
//         result.push({
//           key: `Event time #${index + 1} - start time - meridian`,
//           value: lease.event.times[index].startTime.meridian,
//         });
//       }
//       if (lease.event?.times[index]?.endTime?.hour) {
//         result.push({
//           key: `Event time #${index + 1} - end time - hour`,
//           value: lease.event.times[index].endTime.hour,
//         });
//       }
//       if (lease.event?.times[index]?.endTime?.minute) {
//         result.push({
//           key: `Event time #${index + 1} - end time - minute`,
//           value: lease.event.times[index].endTime.minute,
//         });
//       }
//       if (lease.event?.times[index]?.endTime?.meridian) {
//         result.push({
//           key: `Event time #${index + 1} - end time - meridian`,
//           value: lease.event.times[index].endTime.meridian,
//         });
//       }
//     });
//   }
//   if (lease.event?.setupTime?.hour) {
//     result.push({
//       key: `Event setup time - hour`,
//       value: lease.event.setupTime.hour,
//     });
//   }
//   if (lease.event?.setupTime?.minute) {
//     result.push({
//       key: `Event setup time - minute`,
//       value: lease.event.setupTime.minute,
//     });
//   }
//   if (lease.event?.cleanupTime?.hour) {
//     result.push({
//       key: `Event cleanup time - hour`,
//       value: lease.event.cleanupTime.hour,
//     });
//   }
//   if (lease.event?.cleanupTime?.minute) {
//     result.push({
//       key: `Event cleanup time - minute`,
//       value: lease.event.cleanupTime.minute,
//     });
//   }
//   if (lease.event?.startLoadInTime?.hour) {
//     result.push({
//       key: `Event start load-in time - hour`,
//       value: lease.event.startLoadInTime.hour,
//     });
//   }
//   if (lease.event?.startLoadInTime?.minute) {
//     result.push({
//       key: `Event start load-in time - minute`,
//       value: lease.event.startLoadInTime.minute,
//     });
//   }
//   if (lease.event?.startLoadInTime?.meridian) {
//     result.push({
//       key: `Event start load-in time - meridian`,
//       value: lease.event.startLoadInTime.meridian,
//     });
//   }
//   if (lease.event?.endLoadInTime?.hour) {
//     result.push({
//       key: `Event end load-in time - hour`,
//       value: lease.event.endLoadInTime.hour,
//     });
//   }
//   if (lease.event?.endLoadInTime?.minute) {
//     result.push({
//       key: `Event end load-in time - minute`,
//       value: lease.event.endLoadInTime.minute,
//     });
//   }
//   if (lease.event?.endLoadInTime?.meridian) {
//     result.push({
//       key: `Event end load-in time - meridian`,
//       value: lease.event.endLoadInTime.meridian,
//     });
//   }
//   if (lease.event?.startLoadOutTime?.hour) {
//     result.push({
//       key: `Event start load-out time - hour`,
//       value: lease.event.startLoadOutTime.hour,
//     });
//   }
//   if (lease.event?.startLoadOutTime?.minute) {
//     result.push({
//       key: `Event start load-out time - minute`,
//       value: lease.event.startLoadOutTime.minute,
//     });
//   }
//   if (lease.event?.startLoadOutTime?.meridian) {
//     result.push({
//       key: `Event start load-out time - meridian`,
//       value: lease.event.startLoadOutTime.meridian,
//     });
//   }
//   if (lease.event?.endLoadOutTime?.hour) {
//     result.push({
//       key: `Event end load-out time - hour`,
//       value: lease.event.endLoadOutTime.hour,
//     });
//   }
//   if (lease.event?.endLoadOutTime?.minute) {
//     result.push({
//       key: `Event end load-out time - minute`,
//       value: lease.event.endLoadOutTime.minute,
//     });
//   }
//   if (lease.event?.endLoadOutTime?.meridian) {
//     result.push({
//       key: `Event end load-out time - meridian`,
//       value: lease.event.endLoadOutTime.meridian,
//     });
//   }
//   if (lease.event?.attendeesNumber) {
//     result.push({
//       key: "Event attendees number",
//       value: lease.event.attendeesNumber,
//     });
//   }
//   if (lease.event?.contact?.name) {
//     result.push({
//       key: "Event contact name",
//       value: lease.event.contact.name,
//     });
//   }
//   if (lease.event?.contact?.position) {
//     result.push({
//       key: "Event contact position",
//       value: lease.event.contact.position,
//     });
//   }
//   if (lease.event?.contact?.address) {
//     result.push({
//       key: "Event contact address",
//       value: lease.event.contact.address,
//     });
//   }
//   if (lease.event?.contact?.city) {
//     result.push({
//       key: "Event contact citty",
//       value: lease.event.contact.city,
//     });
//   }
//   if (lease.event?.contact?.state) {
//     result.push({
//       key: "Event contact state",
//       value: lease.event.contact.state,
//     });
//   }
//   if (lease.event?.contact?.zip) {
//     result.push({
//       key: "Event contact zip",
//       value: lease.event.contact.zip,
//     });
//   }
//   if (lease.event?.contact?.phone) {
//     result.push({
//       key: "Event contact phone",
//       value: lease.event.contact.phone,
//     });
//   }
//   if (lease.event?.contact?.email) {
//     result.push({
//       key: "Event contact email",
//       value: lease.event.contact.email,
//     });
//   }
//   if (lease.event?.description) {
//     result.push({
//       key: "Event description",
//       value: lease.event.description,
//     });
//   }
//   if (lease.event?.hasFee) {
//     result.push({
//       key: "Event has fee",
//       value: lease.event.hasFee,
//     });
//   }
//   if (lease.event?.feeType) {
//     result.push({
//       key: "Event fee type",
//       value: lease.event.feeType,
//     });
//   }
//   if (lease.event?.feePayer) {
//     result.push({
//       key: "Event fee payer",
//       value: lease.event.feePayer,
//     });
//   }
//   if (lease.event?.fee) {
//     result.push({
//       key: "Event fee",
//       value: lease.event.fee,
//     });
//   }
//   if (lease.event?.hasBaseFee) {
//     result.push({
//       key: "Event has base fee",
//       value: lease.event.hasFee,
//     });
//   }
//   if (lease.event?.baseFee) {
//     result.push({
//       key: "Event base fee",
//       value: lease.event.fee,
//     });
//   }
//   if (lease.event?.hasRecurring) {
//     result.push({
//       key: "Event has recurring event",
//       value: lease.event.hasRecurring,
//     });
//   }

//   result.forEach(record => {
//     if (record.value instanceof Date) {
//       record.value = window.datesHelper.formatDateAsMonthDayYear(record.value);
//     }
//   })

//   return result;
// }

function getDocumentTerms() {
  if (!lease) {
    return;
  }

  const root = document.querySelector(".document");
  const conditions = root.querySelectorAll("[lease-if]");
  const variables = root.querySelectorAll("lease-var");
  const building = window.lease.buildingInfo;

  const result = {};

  for (let i = 0; i < conditions.length; i++) {
    const condition = conditions[i];
    const parts = [
      condition.getAttribute("hl"),
      condition.getAttribute("hl2"),
      condition.getAttribute("h3"),
      condition.getAttribute("h4"),
      condition.getAttribute("h5"),
      condition.getAttribute("h6"),
      condition.getAttribute("hl7"),
      condition.getAttribute("hl8"),
      condition.getAttribute("hl9"),
      condition.getAttribute("hl10"),
      condition.getAttribute("hl11"),
      condition.getAttribute("hl12"),
      condition.getAttribute("hl13"),
      condition.getAttribute("hl14"),
      condition.getAttribute("hl15")
    ];

    for (let j = 0; j < parts.length; j++) {
      const part = parts[j];

      if (part) {
        const value = _.get(window.lease, part.replace("lease.", ""));
  
        if (value !== null && value !== undefined && !part.endsWith(".length")) {
          _.set(result, part.replace("lease.", ""), value);
          break;
        }
      }
    }
  }

  for (let i = 0; i < variables.length; i++) {
    const variable = variables[i];
    const parts = [
      variable.getAttribute("hl"),
      variable.getAttribute("hl2"),
      variable.getAttribute("h3"),
      variable.getAttribute("h4"),
      variable.getAttribute("h5"),
      variable.getAttribute("h6"),
      variable.getAttribute("hl7"),
      variable.getAttribute("hl8"),
      variable.getAttribute("hl9"),
      variable.getAttribute("hl10"),
      variable.getAttribute("hl11"),
      variable.getAttribute("hl12"),
      variable.getAttribute("hl13"),
      variable.getAttribute("hl14"),
      variable.getAttribute("hl15")
    ];

    for (let j = 0; j < parts.length; j++) {
      const part = parts[j];

      if (part) {
        const value = _.get(window.lease, part.replace("lease.", ""));
  
        if (value !== null && value !== undefined && !part.endsWith(".length")) {
          _.set(result, part.replace("lease.", ""), value);
          break;
        }
      }
    }
  }

  result.building = {};

  if (building.city) {
    result.building.city = building.city;
  }
  if (building.state) {
    result.building.state = building.state;
  }
  if (building.county) {
    result.building.county = building.county;
  }
  if (building.zipCode) {
    result.building.zipCode = building.zipCode;
  }
  if (building.displayName) {
    result.building.displayName = building.displayName;
  }
  if (building.landlordName) {
    result.building.landlordName = building.landlordName;
  }
  if (building.streetAddress) {
    result.building.streetAddress = building.streetAddress;
  }
  if (building.landlordEntityType) {
    result.building.landlordEntityType = building.landlordEntityType;
  }
  if (building.landlordStateOfFormation) {
    result.building.landlordStateOfFormation = building.landlordStateOfFormation;
  }

  return result;
}

function getDocumentText() {
  if (!lease) {
    return;
  }

  const root = document.querySelector(".document");

  if (root) {
    const text = root.innerText;
  
    return text
      .replace(/\u200B/g, "") // ZERO WIDTH SPACE
      .replace(/\u200D/g, "") // ZERO WIDTH JOINER
      .replace(/\ufeff/g, ""); // ZERO WIDTH NO-BREAK SPACE;
  }
}

function getContext() {
  const selection = rangy.getSelection();

  if (selection) {
    return selection.toString();
  }

  return null;
}

(function() {
  function AutopilotpManager() {
    let $scope = null;

    this.initialized = false;

    this.init = function(_scope) {
      $scope = _scope;

      if (this.initialized) {
        return;
      }

      try {
        if (!lease) {
          return;
        }

        if (window?.user?.company?.companyProfile?.hasCoPilot) {
          const autopilotFrame = document.getElementById("autopilot");
          const params = new URLSearchParams();
          const workspaceId = window.user.company.name;
          params.append("workspaceId", workspaceId);
          const userId = window.user.email;
          params.append("userId", userId);
          const reference = document.location.href;
          params.append("reference", reference);
          const name = document.querySelector(".document-info").innerText;
          params.append("name", name);

          autopilotFrame.setAttribute(
            "src",
            `${window._autopilot_base_url}/?${params.toString()}`
          );

          window.addEventListener(
            "message",
            event => {
              if (event.origin !== window._autopilot_base_url) {
                return;
              }

              switch (event.data.type) {
                case "INSERT": {
                  this.insert(event);
                  break;
                }
                case "COPY": {
                  this.copy(event);
                  break;
                }
              }
            },
            false
          );

          this.initialized = true;

          setTimeout(() => {
            this.updateDocumentContext();
          }, 3000);
        }
      } catch (e) {
        console.log(e);
        // Do nothing.
      }
    };

    this.updateDocumentContext = function() {
      try {
        const iframe = document.getElementById("autopilot");

        if (iframe) {
          const terms = getDocumentTerms();
          const doc = getDocumentText();
  
          const message = {
            type: "UPDATE_DOCUMENT_CONTEXT",
            payload: {
              terms,
              doc,
            }
          };
  
          iframe.contentWindow.postMessage(message, window._autopilot_base_url);
        }
      } catch(ex) {
        Raven.captureException(ex);
      }
    };

    this.updatePromptContext = function() {
      try {
        const iframe = document.getElementById("autopilot");

        if (iframe) {
          const context = getContext();
  
          const message = {
            type: "UPDATE_PROMPT_CONTEXT",
            payload: {
              context,
            }
          };
  
          iframe.contentWindow.postMessage(message, window._autopilot_base_url);
        }
      } catch(ex) {
        Raven.captureException(ex);
      }
    }

    this.insert = function(event) {
      const { text } = event.data.payload;

      if (text) {
        clipboardManager.insertText(text);
      }
    };

    this.copy = function(event) {
      const { text } = event.data.payload;

      if (text) {
        const ClipboardItemData = {
          "text/plain": new Blob([text], { type: "text/plain" })
        };
        const data = [new ClipboardItem(ClipboardItemData)];

        navigator.clipboard.write(data);
      }
    };

    this.getDocumentTerms = function() {
      return getDocumentTerms();
    }

    this.getDocumentText = function() {
      return getDocumentText();
    }

    this.getContext = function() {
      return getContext();
    }
  }

  window.autopilotManager = new AutopilotpManager();
})();
